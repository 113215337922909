"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  components: {},
  props: {},
  data() {
    return {
      showPop: false,
      messageCount: 0
    };
  },
  watch: {},
  filter: {},
  created() {
    this.init();
  },
  mounted() {},
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    renderCount(num) {
      return num > 99 ? '99+' : num;
    },
    async init() {
      const res = await this.$api.getMessagecneterCount();
      this.messageCount = res.num;
    },
    windowhref() {
      window.location.href = "https://newradacademy.wechat.bayer.cn/api/yingxiangxueyuan/usefun?fun=consent_html"; //"https://documents.truman.bayer.cn/cn_ph/hcp/zh/cn_consent_statement/index.html";
    }
  }
};