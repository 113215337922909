import "core-js/modules/es.json.stringify.js";
import Vue from 'vue';
import projectConfig from "@/config";
var state = {
  monitor: false,
  openid: '',
  wechatInfo: '',
  redirect: ''
};
var mutations = {
  DIRECTIVE_MONITOR: function DIRECTIVE_MONITOR(state) {
    state.monitor = true;
  },
  SET_WECHAT_INFO: function SET_WECHAT_INFO(state, info) {
    state.openid = info.openid;
    state.wechatInfo = info;
    localStorage.setItem('openid', info.openid || '');
    localStorage.setItem('wechatInfo', JSON.stringify(info) || '');
    Vue.$cookies.set('openid', info.openid || '', projectConfig.cookie.expires, projectConfig.deploy.path);
    Vue.$cookies.set('wechatInfo', JSON.stringify(info) || '', projectConfig.cookie.expires, projectConfig.deploy.path);
  },
  CLEAR_WECHAT_INFO: function CLEAR_WECHAT_INFO(state) {
    state.openid = '';
    state.wechatInfo = '';
    localStorage.removeItem('isRegister');
    localStorage.removeItem('openid');
    localStorage.removeItem('wechatInfo');
    Vue.$cookies.set('openid', '');
    Vue.$cookies.set('wechatInfo', '');
  },
  SET_REDIRECT: function SET_REDIRECT(state, url) {
    state.redirect = url;
    Vue.$cookies.set('redirect', url);
  },
  REMOVE_REDIRECT: function REMOVE_REDIRECT(state) {
    state.redirect = '';
    Vue.$cookies.set('redirect', '');
  }
};
var actions = {
  directiveMonitor: function directiveMonitor(_ref) {
    var commit = _ref.commit;
    commit('DIRECTIVE_MONITOR');
  },
  setWechatInfo: function setWechatInfo(_ref2, info) {
    var commit = _ref2.commit;
    commit('SET_WECHAT_INFO', info);
  },
  clearWechatInfo: function clearWechatInfo(_ref3) {
    var commit = _ref3.commit;
    commit('CLEAR_WECHAT_INFO');
  },
  setRedirect: function setRedirect(_ref4, url) {
    var commit = _ref4.commit;
    commit('SET_REDIRECT', url);
  },
  removeRedirect: function removeRedirect(_ref5) {
    var commit = _ref5.commit;
    commit('REMOVE_REDIRECT');
  }
};
export default {
  state: state,
  mutations: mutations,
  actions: actions
};