"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/popup/style");
var _popup = _interopRequireDefault(require("vant/es/popup"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    vanPopup: _popup.default
  },
  data() {
    return {
      showPowerBoxTip: false,
      showTipInfoWords: {
        title: "",
        words: ""
      },
      isSign: false,
      isLoadSign: false,
      checkAiRep: 0,
      wecomStatus: 0
    };
  },
  watch: {},
  filter: {},
  created() {
    this.checkSign();
    this.checkMemberAiRep();
    this.getWecomStatus();
  },
  mounted() {},
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    async checkMemberAiRep() {
      let res = await this.$api.memberAiRep();
      console.log(res, '==> res --- ');
      this.checkAiRep = res === null || res === void 0 ? void 0 : res.status;
    },
    async getWecomStatus() {
      let res = await this.$api.getWecomStatus();
      console.log(res, '==> res --- ');
      this.wecomStatus = res === null || res === void 0 ? void 0 : res.wecom_status;
    },
    handleLevel() {
      var _levelMap$this$userIn;
      const levelMap = {
        0: {
          title: "",
          score: 0
        },
        1: {
          title: "初入师门",
          score: 30
        },
        2: {
          title: "载酒问字",
          score: 100
        },
        3: {
          title: "负笈游学",
          score: 300
        },
        4: {
          title: "孜孜以求",
          score: 900
        },
        5: {
          title: "手不释卷",
          score: 3000
        },
        6: {
          title: "腹载五车",
          score: 8000
        },
        7: {
          title: "坐而论道",
          score: 24000
        },
        8: {
          title: "博古通今",
          score: 48000
        },
        9: {
          title: "智周万物",
          score: 102400
        }
      };
      const calcProgress = () => {
        var _levelMap;
        /* 下一阶段积分 */
        // const nextScore = levelMap[10]?.score
        const nextScore = (_levelMap = levelMap[this.userInfo.level + 1]) === null || _levelMap === void 0 ? void 0 : _levelMap.score;
        /* 当前积分 */
        const nowScore = this.userInfo.score;

        /* 如果没有拿到最顶级的积分 说明已经满级了 */
        if (!nextScore) {
          return {
            progress: 100,
            nextLevel: null
          };
        }
        return {
          progress: parseFloat(nowScore / nextScore).toFixed(2) * 100,
          nextLevel: this.userInfo.level + 1,
          nextLevelScore: levelMap[this.userInfo.level + 1].score
        };
      };
      let params = {
        title: (_levelMap$this$userIn = levelMap[this.userInfo.level]) === null || _levelMap$this$userIn === void 0 ? void 0 : _levelMap$this$userIn.title,
        /* calcProgress为计算的宽度 */
        ...calcProgress(),
        nextLevelScore: calcProgress().nextLevel ? levelMap[this.userInfo.level + 1].score : 0
      };
      return params;
    },
    async setSign() {
      let res = await this.$api.setLogin();
      if (res !== null && res !== void 0 && res.errcode) {
        // alert("签到失败：" + res.errmsg)
        return;
      }
      // this.$toatsView.show({ tipWords: "签到成功" })
      // this.showPowerBoxTip = true

      this.checkSign();
      this.$store.dispatch("getUserInfo");
    },
    async checkSign() {
      let res = await this.$api.loginChech();
      this.isLoadSign = true;
      if (res !== null && res !== void 0 && res.errcode) {
        // alert("查询签到失败：" + res.errmsg)
        return;
      }
      if (res.have) {
        this.isSign = true;
      }
      if (!res.have) {
        this.isSign = false;
      }
    },
    showTipInfo() {
      this.$refs["customPop-tipInfo"].open();
    },
    setShowTipInfoWords(title, words) {
      this.showTipInfoWords = {
        title,
        words
      };
    }
  }
});