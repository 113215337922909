import Vue from 'vue';
import aiLoading from './ai-loading-vue';
var AiLoading = Vue.extend(aiLoading);
var instance = new AiLoading().$mount();
aiLoading.install = {
  show: function show(option) {
    /* document.body.appendChild(instance.$el)
    Vue.nextTick(() => {
      if (option) {
        const { type, color, text, styles } = option
        if (text) instance.text = text
        if (type) instance.type = type
        if (color) instance.color = color
        if (styles) instance.styles = styles
      }
      instance.show = true
    }) */
  },
  hide: function hide() {
    // instance.show = false
    // document.body.removeChild(instance.$el)
  }
};
export default aiLoading;