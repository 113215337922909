var _objectSpread = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/objectSpread2.js").default;
var needSet = require('./env.config');

/** 项目配置部分 */
var config = {
  /** 基础信息 */
  version: needSet.version,
  title: "".concat(needSet.title),
  type: needSet.type,
  appid: needSet.appid,
  env: needSet.env,
  baseUrl: _objectSpread(_objectSpread({}, needSet.baseUrl), {}, {
    base: 'https://newradacademy.wechat.bayer.cn'
  }),
  /** 中台配置信息 */
  middlePlatform: {
    token: needSet.middlePlatform.token,
    baseUrl: 'https://newradacademy.wechat.bayer.cn' // 一般情况无需改动
  },
  wechatUrl: "/api/platform/jssdk",
  lookstar: needSet.baseUrl.base,
  cookie: {
    expires: '5d' // 5天
  },
  /** 监测 */
  monitor: {
    user_name: needSet.title,
    open_state: needSet.monitor
  },
  /** 微信 */
  wechat: {
    jsApiList: ['onMenuShareTimeline', 'updateTimelineShareData', 'onMenuShareAppMessage', 'updateAppMessageShareData', 'hideMenuItems', 'chooseImage']
  },
  /** 小程序 */
  miniProgram: {
    iv: '',
    key: ''
  },
  crypto: needSet.crypto,
  /** 部署 */
  proxy: needSet.proxy,
  debug: needSet.debug,
  deploy: needSet.deploy
};
module.exports = config;