export default {
  name: 'Loading',
  props: {
    show: Boolean,
    type: {
      type: String,
      default: 'spinner' // 'circular' // 'spinner'
    },
    color: {
      type: String,
      default: '#ec6602' // '#999'
    },
    text: {
      type: String,
      default: '加载中...'
    },
    styles: {
      type: String,
      default: 'rect' // rect // opacity
    }
  },
  data: function data() {
    return {};
  }
};