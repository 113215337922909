var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container-components"
  }, [!_vm.hideSeat ? _c("div", {
    staticClass: "tabbarBoxSeat mark",
    class: _vm.smallTabbarBoxSeat && "smallTabbarBoxSeat"
  }) : _vm._e(), _c("div", {
    staticClass: "tabbarBox flex align-center justify-around"
  }, [_c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "tabbarBoxItem flex align-center flex-direction",
    attrs: {
      "event-key": "tabbar\u9996\u9875"
    },
    on: {
      click: function click($event) {
        return _vm.switchTab(0);
      }
    }
  }, [_c("div", {
    staticClass: "tabbarBoxItemIcon-home"
  }, [_vm.tabIndex == 0 ? _c("img", {
    attrs: {
      src: require("img/tabbar/homeCurr.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("img/tabbar/home.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "tabbarBoxItemWords",
    class: _vm.tabIndex == 0 ? "tabbarBoxItemWordsCurr" : ""
  }, [_vm._v("首页")])]), _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "tabbarBoxItem flex align-center flex-direction",
    attrs: {
      "event-key": "tabbar\u4F1A\u8BAE"
    },
    on: {
      click: function click($event) {
        return _vm.switchTab(1);
      }
    }
  }, [_c("div", {
    staticClass: "tabbarBoxItemIcon-meeting"
  }, [_vm.tabIndex == 1 ? _c("img", {
    attrs: {
      src: require("img/tabbar/meetingCurr.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("img/tabbar/meeting.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "tabbarBoxItemWords",
    class: _vm.tabIndex == 1 ? "tabbarBoxItemWordsCurr" : ""
  }, [_vm._v("会议")])]), _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "tabbarBoxItem flex align-center flex-direction",
    attrs: {
      "event-key": "tabbar\u5B66\u4E60"
    },
    on: {
      click: function click($event) {
        return _vm.switchTab(2);
      }
    }
  }, [_c("div", {
    staticClass: "tabbarBoxItemIcon-course"
  }, [_vm.tabIndex == 2 && _vm.urlQuery.typeMap != "2" ? _c("img", {
    attrs: {
      src: require("img/tabbar/courseCurr.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("img/tabbar/course.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "tabbarBoxItemWords",
    class: _vm.tabIndex == 2 && _vm.urlQuery.typeMap != "2" ? "tabbarBoxItemWordsCurr" : ""
  }, [_vm._v("学习")])]), _c("div", {
    directives: [{
      name: "monitor",
      rawName: "v-monitor"
    }],
    staticClass: "tabbarBoxItem flex align-center flex-direction",
    attrs: {
      "event-key": "tabbar\u6211\u7684"
    },
    on: {
      click: function click($event) {
        return _vm.switchTab(3);
      }
    }
  }, [_c("div", {
    staticClass: "tabbarBoxItemIcon-mine"
  }, [_vm.tabIndex == 3 ? _c("img", {
    attrs: {
      src: require("img/tabbar/mineCurr.png"),
      alt: ""
    }
  }) : _c("img", {
    attrs: {
      src: require("img/tabbar/mine.png"),
      alt: ""
    }
  }), _vm.showClaim && _vm.show ? _c("div", {
    staticClass: "showClaim"
  }) : _vm._e()]), _c("div", {
    staticClass: "tabbarBoxItemWords",
    class: _vm.tabIndex == 3 ? "tabbarBoxItemWordsCurr" : ""
  }, [_vm._v("我的")])])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };