export default {
  components: {},
  name: "picture",
  props: {
    filterMap: {
      type: Object,
      default: function _default() {}
    },
    src: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {};
  },
  watch: {},
  filter: {},
  created: function created() {},
  mounted: function mounted() {},
  computed: {},
  methods: {}
};