import _typeof from "D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.object.keys.js";
import Vue from 'vue';
export default {
  monitor: function monitor(state) {
    return state.defaultData.monitor;
  },
  openid: function openid(state) {
    return state.defaultData.openid || localStorage.getItem('openid') || Vue.$cookies.get('openid');
  },
  wechatInfo: function wechatInfo(state) {
    if (state.defaultData.wechatInfo) return state.defaultData.wechatInfo;
    var wechatInfo = localStorage.getItem('wechatInfo') || Vue.$cookies.get('wechatInfo');
    if (_typeof(wechatInfo) === 'object') return wechatInfo;
    return JSON.parse(wechatInfo);
  },
  redirect: function redirect(state) {
    return state.defaultData.redirect || Vue.$cookies.get('redirect');
  }
};