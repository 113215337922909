"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm$showTipInfoWords;
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_c("div", {
    staticClass: "fixedBg"
  }), _c("div", {
    staticClass: "container"
  }, [_c("div", {
    staticClass: "containerBg"
  }, [_c("div", {
    staticClass: "activeBtn",
    on: {
      click: _vm.toggleActive
    }
  }, [_c("img", {
    attrs: {
      src: require("img/annual/home/activeBtn.png"),
      alt: "",
      srcset: ""
    }
  })]), _c("img", {
    staticClass: "gohome",
    attrs: {
      src: require("img/commonHeader/backIcon.png"),
      alt: ""
    },
    on: {
      click: _vm.switchTab
    }
  }), _c("img", {
    staticClass: "logoImg",
    attrs: {
      src: require("img/annual/home/logo.png"),
      alt: "",
      srcset: ""
    }
  }), _c("div", {
    staticClass: "topBg"
  }, [_c("div", {
    staticClass: "startApp btnAni",
    on: {
      click: _vm.goYearBackPage
    }
  })]), _c("div", {
    staticClass: "taskBg"
  }, [_c("div", {
    staticClass: "everyDayTask"
  }, [_c("div", {
    staticClass: "e-title"
  }, [_vm._v("学术能量限时加速ing，排行榜等你冲击")]), _c("div", {
    staticClass: "e-time"
  }, [_vm._v("加速时间：即日起至2025年3月31日")]), _c("img", {
    staticClass: "e-title-img",
    attrs: {
      src: require("img/annual/home/title1Icon.png"),
      alt: "",
      srcset: ""
    }
  }), _c("div", {
    staticClass: "e-task-list"
  }, [_vm._m(0), _vm.infoData ? _c("div", {
    staticClass: "e-taskRight btn_unfinished",
    class: {
      btn_complete: _vm.infoData.is_finished == 1
    },
    on: {
      click: function ($event) {
        return _vm.everyTaskHandele(1);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.infoData.is_finished ? "已完成" : "去完成") + " ")]) : _vm._e()]), _c("div", {
    staticClass: "e-task-list"
  }, [_vm._m(1), _c("div", {
    staticClass: "e-taskRight btn_unfinished",
    on: {
      click: function ($event) {
        return _vm.everyTaskHandele(2);
      }
    }
  }, [_vm._v("去完成")])]), _c("div", {
    staticClass: "e-task-list"
  }, [_vm._m(2), _vm.infoData ? _c("div", {
    staticClass: "e-taskRight btn_unfinished",
    class: {
      btn_complete: _vm.infoData && _vm.infoData.member && _vm.infoData.member.veeva == 1
    },
    on: {
      click: function ($event) {
        return _vm.everyTaskHandele(4);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.infoData && _vm.infoData.member && _vm.infoData.member.veeva == 1 ? "已完成" : "去完成") + " ")]) : _vm._e()]), _c("div", {
    staticClass: "e-task-list"
  }, [_vm._m(3), _vm.infoData ? _c("div", {
    staticClass: "e-taskRight btn_unfinished",
    class: {
      btn_complete: _vm.infoData && _vm.infoData.member && _vm.infoData.member.fusion == 1
    },
    on: {
      click: function ($event) {
        return _vm.everyTaskHandele(3);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.infoData && _vm.infoData.member && _vm.infoData.member.fusion == 1 ? "已完成" : "去完成") + " ")]) : _vm._e()]), _c("div", {
    staticClass: "e-task-list"
  }, [_vm._m(4), _vm.infoData && _vm.infoData.member && _vm.infoData.member.ai_rep ? _c("div", {
    staticClass: "e-taskRight btn_complete"
  }, [_vm._v(" " + _vm._s("已完成") + " ")]) : _c("div", {
    staticClass: "e-taskRight btn_unfinished",
    on: {
      click: _vm.veevaAuthHandle
    }
  }, [_vm._v(" " + _vm._s("去完成") + " ")])])]), _c("div", {
    staticClass: "line line1"
  }), _c("div", {
    staticClass: "overseasTask"
  }, [_c("img", {
    staticClass: "o-title-img",
    attrs: {
      src: require("img/annual/home/title2Icon.png"),
      alt: "",
      srcset: ""
    }
  }), _c("div", {
    staticClass: "o-title-desc"
  }, [_vm._v(" 四大精品大咖训练营全面开放，邀你即刻落座深入学习，完成学习任务的优等生将会获赠学习能量大礼包，助你解锁更全专业学习资讯，医海新征程扬帆远航。 ")]), _vm._l(_vm.overseasTaskData, function (item, index) {
    return _c("div", {
      key: item.id,
      staticClass: "o-task-list",
      on: {
        click: function ($event) {
          return _vm.toDetail(item.id, index);
        }
      }
    }, [_c("div", {
      staticClass: "o-taskLeft"
    }, [_c("div", {
      staticClass: "o-task-img"
    }, [_c("img", {
      attrs: {
        src: item.icon,
        alt: "",
        srcset: ""
      }
    })]), _c("div", {
      staticClass: "o-task-content"
    }, [_c("div", {
      staticClass: "o-content-title"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "o-content-desc"
    }, [_vm._v(_vm._s(item.desc))])])]), _c("div", {
      staticClass: "o-taskRight btn_complete btn_unfinished"
    }, [_vm._v("去学习")])]);
  })], 2), _c("div", {
    staticClass: "timeTips"
  }, [_vm._v("以上课程仅面向“认证学员”开放")]), _c("div", {
    staticClass: "line line2"
  }), _c("div", {
    staticClass: "storageTask"
  }, [_c("img", {
    staticClass: "s-title-img",
    attrs: {
      src: require("img/annual/home/title3Icon.png"),
      alt: "",
      srcset: ""
    }
  }), _c("div", {
    staticClass: "s-title-desc"
  }, [_vm._v("每日探索拜耳影像学苑，均可获得学术能量，让你积少成多，攀登学术高峰。")]), _c("div", {
    staticClass: "s-task-list-box"
  }, _vm._l(_vm.storageTaskData, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "s-task-list"
    }, [_c("div", {
      staticClass: "s-taskLeft"
    }, [_c("div", {
      staticClass: "s-task-img"
    }, [_c("img", {
      attrs: {
        src: item.icon,
        alt: "",
        srcset: ""
      }
    })]), _c("div", {
      staticClass: "s-task-content"
    }, [_c("div", {
      staticClass: "s-content-title"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "s-content-desc"
    }, [_vm._v(_vm._s(item.desc))])])])]);
  }), 0), _c("div", {
    staticClass: "s-taskRight btn_complete btn_unfinished",
    on: {
      click: _vm.goPersonalTask
    }
  }, [_vm._v("去提升")])]), _c("div", {
    staticClass: "zs1"
  })]), _c("div", {
    staticClass: "zs2"
  }), _vm._m(5), _c("div", {
    staticClass: "commentBox"
  }, [_vm._m(6), _c("div", {
    staticClass: "commentIcon1"
  }), _c("div", {
    staticClass: "commentIcon2"
  }), _c("div", {
    staticClass: "commentbottom"
  }), _c("div", {
    staticClass: "commentMyTitle"
  }), _c("comment", {
    ref: "mycomment",
    attrs: {
      renderModule: "activity"
    }
  })], 1)]), _c("div", {
    staticClass: "serialNumber"
  }, [_vm._v("PP-GAD-CN-1729-1，仅供与医疗卫生专业人士学术沟通使用。")]), _vm.activePageFlag ? _c("div", {
    staticClass: "activePop"
  }, [_c("div", {
    staticClass: "activeBox"
  }, [_vm._m(7), _c("div", {
    staticClass: "downIcon"
  }), _c("div", {
    staticClass: "closeBtn",
    on: {
      click: function ($event) {
        _vm.activePageFlag = false;
      }
    }
  })])]) : _vm._e(), _c("customPop", {
    ref: "customPop-tipInfo",
    attrs: {
      showType: "slot"
    }
  }, [_c("div", {
    staticClass: "tipInfo"
  }, [_c("div", {
    staticClass: "tipInfoTitle"
  }, [_vm._v(_vm._s((_vm$showTipInfoWords = _vm.showTipInfoWords) === null || _vm$showTipInfoWords === void 0 ? void 0 : _vm$showTipInfoWords.title))]), _c("div", {
    staticClass: "tipInfoWords",
    domProps: {
      innerHTML: _vm._s(_vm.$VIDEOPLAYSINLINE(_vm.showTipInfoWords.words))
    },
    on: {
      click: _vm.$imageEnlargement
    }
  }), _c("div", {
    staticClass: "tipInfoBtn flex align-center justify-center",
    on: {
      click: function ($event) {
        return _vm.$refs["customPop-tipInfo"].close();
      }
    }
  }, [_vm._v(" 我知道了 ")])])])], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "e-taskLeft"
  }, [_c("div", {
    staticClass: "e-task-img"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/img/annual/home/icon1.png"),
      alt: "",
      srcset: ""
    }
  })]), _c("div", {
    staticClass: "e-task-content"
  }, [_c("div", {
    staticClass: "e-content-title"
  }, [_vm._v("生成你的「年度学习报告」")]), _c("div", {
    staticClass: "e-content-desc"
  }, [_vm._v("将获赠100学术能量")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "e-taskLeft"
  }, [_c("div", {
    staticClass: "e-task-img"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/img/annual/home/icon2.png"),
      alt: "",
      srcset: ""
    }
  })]), _c("div", {
    staticClass: "e-task-content"
  }, [_c("div", {
    staticClass: "e-content-title"
  }, [_vm._v("完成「问卷调研」")]), _c("div", {
    staticClass: "e-content-desc"
  }, [_vm._v("将获赠100-600学术能量")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "e-taskLeft"
  }, [_c("div", {
    staticClass: "e-task-img"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/img/annual/home/icon4.png"),
      alt: "",
      srcset: ""
    }
  })]), _c("div", {
    staticClass: "e-task-content"
  }, [_c("div", {
    staticClass: "e-content-title"
  }, [_vm._v("活动期间内，完成医疗卫生专业人士相关信息认证")]), _c("div", {
    staticClass: "e-content-desc"
  }, [_vm._v("将获赠300学术能量")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "e-taskLeft"
  }, [_c("div", {
    staticClass: "e-task-img"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/img/annual/home/icon3.png"),
      alt: "",
      srcset: ""
    }
  })]), _c("div", {
    staticClass: "e-task-content"
  }, [_c("div", {
    staticClass: "e-content-title"
  }, [_vm._v("绑定拜耳会议平台")]), _c("div", {
    staticClass: "e-content-desc"
  }, [_vm._v("将获赠100学术能量")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "e-taskLeft"
  }, [_c("div", {
    staticClass: "e-task-img"
  }, [_c("img", {
    attrs: {
      src: require("@/assets/img/annual/home/icon5.png"),
      alt: "",
      srcset: ""
    }
  })]), _c("div", {
    staticClass: "e-task-content"
  }, [_c("div", {
    staticClass: "e-content-title"
  }, [_vm._v("加入影像医生学习社群，"), _c("br"), _vm._v("与同行互通有无")]), _c("div", {
    staticClass: "e-content-desc"
  }, [_vm._v("将获赠100学术能量")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "prizeBox"
  }, [_c("div", {
    staticClass: "p_title"
  }, [_vm._v("学海续航")]), _c("div", {
    staticClass: "prizeContent"
  }, [_c("div", {
    staticClass: "p_desc1 p_desc"
  }, [_vm._v(" 1、在活动结束时，季度学术能量排行榜排名前100可领取"), _c("br"), _c("span", {
    staticClass: "p_blodTxt"
  }, [_c("span", {
    staticClass: "txt"
  }, [_vm._v("「季度之星」勋章")]), _vm._v(" "), _c("span", {
    staticClass: "textline1 textline"
  })]), _c("span", {
    staticClass: "textline"
  })]), _c("div", {
    staticClass: "p_desc2 p_desc"
  }, [_vm._v(" 2、活动结束时，季度学术能量排行榜前100名且已加入学术社群学员的所在医院（同一医院每季度仅可领取一次，且同一医院一年内最多只能获得四次书籍） 即可获赠"), _c("br"), _c("span", {
    staticClass: "p_blodTxt"
  }, [_c("span", {
    staticClass: "txt"
  }, [_vm._v("影像专业书籍1本")]), _vm._v(" "), _c("span", {
    staticClass: "textline2 textline"
  })])]), _c("div", {
    staticClass: "prizeImgList"
  }, [_c("div", {
    staticClass: "prizeImgItem prizeImgItem1"
  }, [_c("img", {
    attrs: {
      src: require("img/annual/home/prize1.png"),
      alt: "",
      srcset: ""
    }
  }), _c("span", [_vm._v("「季度之星」勋章")])]), _c("div", {
    staticClass: "prizeImgItem"
  }, [_c("img", {
    attrs: {
      src: require("img/annual/home/prize2.png"),
      alt: "",
      srcset: ""
    }
  }), _c("span", [_vm._v("影像专业书籍")])])])]), _c("div", {
    staticClass: "prizeTips1"
  }, [_vm._v("*图片仅为示例，实际兑换以选择页面指定书籍为准")]), _c("div", {
    staticClass: "prizeTips"
  }, [_vm._v("*活动详情请查看活动规则")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "commentTop"
  }, [_c("div", {
    staticClass: "commentTxt"
  }, [_vm._v("留言区")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "activeScrollBox notScroll"
  }, [_c("div", {
    staticClass: "activeScrollContent"
  }, [_c("div", {
    staticClass: "activeTitle"
  }), _c("div", {
    staticClass: "activeContent"
  }), _c("div", {
    staticClass: "zwBox"
  })])]);
}];
render._withStripped = true;