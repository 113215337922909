var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "aiPopup",
    staticClass: "ai-popup-wrap"
  }, [_c("van-popup", {
    attrs: {
      "close-on-popstate": "",
      duration: _vm.duration,
      "close-on-click-overlay": Boolean(_vm.overlay),
      "overlay-style": {
        backgroundColor: "rgba(0,0,0,".concat(_vm.opacity, ")")
      }
    },
    on: {
      closed: _vm.closed
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c("popup", {
    attrs: {
      data: _vm.data,
      close: _vm.close,
      callback: function callback() {
        return _vm.callback(_vm.close);
      },
      confirm: function confirm() {
        return _vm.confirm(_vm.close);
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };