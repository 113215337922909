import http from 'utils/http';
export default {
  // 接口示例
  getTest: function getTest(params) {
    return http({
      url: "/api/test",
      method: 'get',
      params: params
      // loading: 1, // 请求时默认加载一个 loading
      // origin: 1, // 接口返回数据，原格式返回，需要自己判断 code 时使用
      // custom: 1, // 自定义链接请求地址，不会拼接全局 baseUrl
      // openid: 1, // 是否在参数中拼接 openid
      // upload: 1, // 修改 header 头为文件类型，formData 时使用
    });
  },
  // 接口示例
  postTest: function postTest(data) {
    return http({
      url: "/api/post",
      method: 'post',
      data: data
    });
  }
};