import "vant/es/image-preview/style";
import _ImagePreview from "vant/es/image-preview";
import "D:\\ApacheServer\\web_php\\yxxy_html\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\ApacheServer\\web_php\\yxxy_html\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\ApacheServer\\web_php\\yxxy_html\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\ApacheServer\\web_php\\yxxy_html\\node_modules\\core-js\\modules\\es.promise.finally.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
// 兼容 IE
// https://github.com/zloirock/core-js/blob/master/docs/2019-03-19-core-js-3-babel-and-a-look-into-the-future.md#babelpolyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

/* 监测 用于统计 */
import "@/utils/monitor.js";
import { chooseImage } from "@/utils/wechat.js";
Vue.prototype.$chooseImage = chooseImage;

/* 挂入全局登录弹层 */
import toRegister from "@/utils/toRegister.js";
Vue.use(toRegister);

/* 生成二维码 */
import VueQriously from 'vue-qriously';
Vue.use(VueQriously);
import '@/plugins/vant'; // vant 按需引入
import 'lib-flexible/flexible'; // 移动端适配
import './filters'; // 全局过滤器

import "@/utils/route.js";

/* 对比版本 清除缓存 */
import "@/utils/version.js";

/* swiper样式 */
import 'swiper/css/swiper.min.css';

/* 禁止手动缩放字体 */
import "@/utils/banSetSize.js";

/* 列表内容类型映射 */
Vue.prototype.$MAPTYPE = function (val) {
  return store.getters.contentType[val] || "MAPTYPE未定义：" + val;
};
Vue.prototype.$NUMF = function (val) {
  if (typeof val !== 'number') {
    return val;
  }
  if (val >= 10000) {
    return (val / 10000).toFixed(1) + 'w';
  }
  return val.toString();
};
Vue.prototype.$DEFAULTAVATAR = require("@/assets/img/personal/avatarUserNull.png");
Vue.prototype.$STAFFVEEVAICON = require("@/assets/img/personal/veevapass/noIcon.png");

/* 转换时间戳 */
Vue.prototype.$GETNOWMILLISECOND = function (value) {
  return new Date(value.replace(/\-/g, "/")).getTime();
};
function test(html) {
  var wechatInfo = JSON.parse(localStorage.getItem("wechatInfo") || "{}");
  var re = /<a[^>]*href=['"]([^"]*)['"][^>]*>(.*?)<\/a>/g;
  var str = html;
  var arr = [];
  var arr1 = [];
  while (re.exec(str) != null) {
    arr.push({
      href: RegExp.$1,
      //链接
      msg: RegExp.$2 //内容
    }); //如果是RegExp.$1那么匹配的就是href里的属性了!
  }
  var newhtml = html;
  for (var i = 0; i < arr.length; i++) {
    var hrefhtml = arr[i]['href'];
    // let gohref = "/bayer-rad-academy-shadowcloud-academy-html/personal/intercept?link=" + encodeURIComponent(hrefhtml) + "&title=" + arr[i]['msg']
    var gohref = void 0;
    if (hrefhtml.indexOf("radbayer.wjx.cn") != -1) {
      gohref = hrefhtml.indexOf("?") != -1 ? "".concat(hrefhtml, "&sojumpparm=").concat(wechatInfo === null || wechatInfo === void 0 ? void 0 : wechatInfo.unionid) : "".concat(hrefhtml, "?sojumpparm=").concat(wechatInfo === null || wechatInfo === void 0 ? void 0 : wechatInfo.unionid);
    } else {
      gohref = hrefhtml;
    }
    newhtml = newhtml.replace(hrefhtml, gohref);
  }
  console.log(arr, '==>arr---');
  console.log(newhtml);
  return newhtml;
}

/* ios视频播放不脱离文本流 */
Vue.prototype.$VIDEOPLAYSINLINE = function (value) {
  var handleStr = value ? value.replace(/<video/g, "<video webkit-playsinline=\"true\" playsinline=\"true\"") : value;
  // handleStr = handleStr ? handleStr.replace(/<a.*href="/g, `<a href="/bayer-rad-academy-shadowcloud-academy-html/personal/intercept?link=`) : handleStr

  handleStr = test(handleStr);
  return handleStr;
};

/* 放大图片预览 */

Vue.prototype.$imageEnlargement = function (evt) {
  var nodeName = evt.target.nodeName;
  if (nodeName == 'IMG') {
    console.log(evt.target.currentSrc, '==> .target.currentSrc');
    _ImagePreview({
      images: [evt.target.currentSrc],
      closeable: true
    });
  }
};
Vue.prototype.$SHOWIMGPREVIEW = function (url) {
  _ImagePreview({
    images: [url],
    closeable: true
  });
};

/* 时间截取到日期 */
Vue.prototype.$SPLITDATE = function (val, type) {
  var tempTimeArr = val === null || val === void 0 ? void 0 : val.split(" ");
  if (type == "yyyy-mm-dd h:m") {
    var tempSecArr = tempTimeArr[1].split(":");
    var tempSecStr = tempSecArr[0] + ":" + tempSecArr[1];
    return (tempTimeArr === null || tempTimeArr === void 0 ? void 0 : tempTimeArr[0]) + " " + tempSecStr;
  }
  return (tempTimeArr === null || tempTimeArr === void 0 ? void 0 : tempTimeArr[0]) || val;
};

/* 列表类型映射 */
Vue.prototype.$MAPLISTTYPE = function (val) {
  return store.getters.listType[val] || "MAPLISTTYPE未定义：" + val;
};
import tabbar from "@/components/common/tabbar.vue";
import customPopview from "@/components/customPopview/index.vue";
import Picture from "@/components/common/Picture.vue";
Vue.component("tabbar", tabbar);
Vue.component("customPop", customPopview);
Vue.component("Picture", Picture);

/* 后台使用quill--富文本编辑器的css样式 */
// import "quill/dist/quill.core.css"

/* 默认配置 */
import setting from './defaultSetting';
Vue.use(setting);

/**
 * 以上为默认引入
 * 以下为自定义内容
 * */
// import some from 'some'
// Vue.use(some)

import http from 'utils/http';
Vue.prototype.$hasRegister = function (callback) {
  if (store.getters.hasRegister) {
    callback();
    return;
  }
  // 请求接口
  http({
    url: "/api/wap/empty-user",
    method: 'get',
    params: {},
    origin: 1
  }).then(function (res) {
    if (res.code === 1000 || res.code === '1000') {
      store.dispatch('setRegister', true);
      callback();
      return;
    }
    router.push('/register');
  });
};
Vue.prototype.$getUser = function (callback) {};
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});