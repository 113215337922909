import "core-js/modules/es.number.constructor.js";
export default {
  components: {},
  props: {
    width: [String, Number],
    height: [String, Number]
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  mounted: function mounted() {},
  computed: {
    w: function w() {
      var W = this.width;
      return W ? "".concat(W / 8.28, "vw") : '100%';
    },
    h: function h() {
      var H = this.height;
      return H ? "".concat(H / 8.28, "vw") : '.1px';
    }
  },
  filters: {},
  methods: {}
};