"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/toast/style");
var _toast = _interopRequireDefault(require("vant/es/toast"));
require("core-js/modules/es.array.push.js");
var _data = require("./data.js");
var _utils = require("./utils.js");
var _html2canvas = _interopRequireDefault(require("html2canvas"));
var _swiper = _interopRequireDefault(require("swiper"));
var _index = require("@/utils/index.js");
var _default = exports.default = window.$initPage({
  data() {
    return {
      isShowDownIcon: false,
      infoData: null,
      yearBackTxt: _data.yearBackTxt,
      answerList: _data.answerList,
      currentQuestion: 1,
      curItemIndex: null,
      // 当前选中项的索引
      curQuestionData: null,
      // 当前问题的数据
      userChoose: {},
      urlFormOpenid: null,
      posterResult: 1,
      //答题结果展示对应海报
      posterDescTxt: null,
      //你的未来影像描述
      pageFlag: {
        poster: false,
        question: false,
        dataPage: true
      },
      base64QRCode: '',
      isOldUser: true,
      cutIsShowBtn: true,
      studyTime: 0,
      //格式化学习时间
      studyTimeTxt: '',
      rankTxtObj: null,
      calssName: '',
      posterImg: null,
      swiperInstance: null,
      lines: [],
      savedScrollPosition: 0
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    textColor() {
      let arr = ['textColor1', 'textColor2', 'textColor3', 'textColor4'];
      return arr[this.posterResult - 1];
    },
    dynamicFontSizeStyle() {
      if (this.calssName) {
        const baseSize = 35; // 基础字号大小
        const scale = 0.7; // 文本长度对字号的影响系数
        // 计算字号：基础字号 - 文本长度*影响系数
        let fontSize = baseSize - this.calssName.length * scale;
        // 设置字号下限
        fontSize = Math.max(fontSize, 15);
        return {
          fontSize: `${fontSize}px`
        };
      } else {
        return '';
      }
    }
  },
  async mounted() {
    this.urlQuery = this.$route.query;
    this.urlFormOpenid = this.urlQuery.from_openid || this.urlQuery.source || '';
    console.log('this.urlFormOpenid ---:', this.urlFormOpenid);
    console.log('this.appmessage ---:', this.urlQuery.from);
    if (this.urlQuery.from == 'appmessage') {
      this.$PUSH({
        path: `/annual/task?from_openid=${this.urlFormOpenid}`
      });
      return;
    }
    this.infoData = await this.get2024Data();
    console.log('this.infoData', this.infoData);

    // setTimeout(() => {
    //   this.infoData.learn_times = 5000
    //   this.infoData.rank = 10000
    //   this.userInfo.type = 1
    //   this.infoData.is_new_user = 0;

    //   console.log("auth:", (this.infoData.is_new_user == 0 && this.userInfo.type == 2 && this.infoData.learn_times >= 5000) || (this.infoData.is_new_user == 0 && this.userInfo.type == 1 && (this.infoData.learn_times >= 5000 || this.infoData.rank <= 10000)));
    //   console.log(' this.userInfo ---:', this.userInfo.type, this.infoData.rank, this.infoData.learn_times, this.infoData.is_new_user);

    // }, 1000)
    this.questionInit();
    this.$nextTick(() => {
      this.initSwiper();
      this.yearBackCb(); //回顾开始
      this.yearDataInit(); //设置2024数据
      this.getBase64QRCode();
      this.setMonitor('pageDesc');
      this.setWeChatShare();
    });
  },
  methods: {
    showInfo() {
      var _this$userInfo, _this$userInfo2;
      /* this.userInfo.show: 1：头像 2：昵称 */
      const deCrypt = new _index.Crypt();
      let params = {
        title: deCrypt.decrypt(this.userInfo.name),
        avatar: this.userInfo.avatar
      };
      if (this.userInfo.type == '2') {
        return params;
      }
      if ((_this$userInfo = this.userInfo) !== null && _this$userInfo !== void 0 && (_this$userInfo = _this$userInfo.shows) !== null && _this$userInfo !== void 0 && _this$userInfo.includes('1')) {
        params['avatar'] = this.userInfo.avatar_virtual;
      }
      if ((_this$userInfo2 = this.userInfo) !== null && _this$userInfo2 !== void 0 && (_this$userInfo2 = _this$userInfo2.shows) !== null && _this$userInfo2 !== void 0 && _this$userInfo2.includes('2')) {
        params['title'] = this.userInfo.nickname;
      }
      return params;
    },
    calculateLines() {
      this.$nextTick(() => {
        const containerWidth = this.$refs.textContainer.clientWidth;
        let line = '';
        this.lines = [];
        for (let char of this.calssName) {
          const testLine = line + char;
          const metrics = this.measureText(testLine, containerWidth);
          if (metrics <= containerWidth) {
            line = testLine;
          } else {
            this.lines.push(line);
            line = char;
          }
        }
        if (line) {
          this.lines.push(line);
        }
      });
    },
    measureText(text, containerWidth) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      // 根据需要设置字体样式
      return context.measureText(text).width;
    },
    initSwiper() {
      let _this = this;
      try {
        this.swiperInstance = new _swiper.default('.mySwiper2', {
          effect: 'fade',
          // spaceBetween: 22,
          direction: 'vertical',
          // vertical 设置成竖向排列
          resistanceRatio: 0,
          on: {
            transitionEnd: function () {
              let that = this;
              if (that.activeIndex == 0) {
                let arr2 = document.getElementsByClassName('swiper_item2');
                for (let i = 0; i < arr2.length; i++) {
                  arr2[i].style.opacity = 0; // 将 'opatiy' 改为 'opacity'
                }
                _this.yearBackCb();
              } else {
                _this.isShowDownIcon = false;
                let arr = document.getElementsByClassName('swiper_item');
                for (let i = 0; i < arr.length; i++) {
                  arr[i].style.opacity = 0; // 将 'opatiy' 改为 'opacity'
                }
                _this.dataPageCb();
              }
            }
          }
        });
      } catch (error) {
        setTimeout(() => {
          this.initSwiper();
        }, 500);
      }
    },
    setWeChatShare(params) {
      let env = process.env.VUE_APP_ENV;
      let url;
      if (env == 'test') {
        url = `https://dev-bayer.blue-dot.com.cn/bayer-rad-academy-shadowcloud-academy-html/annual/task`;
      } else if (env == 'development') {
        url = `https://newradacademy-q.wechat.bayer.cn/bayer-rad-academy-shadowcloud-academy-html/annual/task`;
      } else {
        url = `https://newradacademy.wechat.bayer.cn/bayer-rad-academy-shadowcloud-academy-html/annual/task`;
      }
      this.weChatShare.title = '影领学途';
      this.weChatShare.desc = '影领学途，生成我的2024学习报告';
      this.weChatShare.selfLink = url;
      this.weChatShare.imgUrl = 'https://cn-ph-new-rad-p.s3.cn-north-1.amazonaws.com.cn/minisite/20241224/rc-upload-1735022048660-7.png';
      this.initWechat();
    },
    yearDataInit() {
      this.setInitDataPage();
    },
    async get2024Data() {
      let data = await this.$api.get2024Data({
        from_openid: this.urlFormOpenid
      });
      // console.log('data111111111', data)
      // // 新老用户判断 0 旧  1 新
      // data.is_new_user = 0
      return data;
    },
    getBase64QRCode() {
      this.base64QRCode = this.$refs.QRCode.qrious.canvas.toDataURL();
    },
    yearBackCb() {
      this.swiperInstance.allowSlideNext = false;
      this.swiperInstance.allowSlidePrev = false;
      (0, _utils.staggerAni)({
        className: 'yearBackSynopsis',
        dur: 0.8,
        animationDirectionStart: 'bottom-top',
        gap: 0.3,
        distance: 100,
        deep: true
      }, () => {
        this.swiperInstance.allowSlideNext = true;
        this.swiperInstance.allowSlidePrev = true;
        this.isShowDownIcon = true;
      });
      this.setMonitor('pageDataBack');
      // this.pageFlag.dataPage = true
      //       this.pageFlag.back = false
      //       this.dataPageCb()
      //       this.setMonitor('pageDataBack');
    },
    questionAniFunc() {
      setTimeout(() => {
        (0, _utils.staggerAni)({
          className: 'questionAni',
          deep: false,
          animationDirectionStart: 'left-right'
        });
      }, 0);
    },
    dataPageCb() {
      this.swiperInstance.allowSlideNext = false;
      this.swiperInstance.allowSlidePrev = false;
      console.log('this.infoData.has_data', this.infoData.has_data);
      // className: (this.infoData.is_new_user ||  !this.infoData.has_data) ? 'newDataBox' : 'oldDataBox',
      setTimeout(() => {
        (0, _utils.staggerAni)({
          className: this.infoData.is_new_user ? 'newDataBox' : 'oldDataBox',
          dur: 1.3,
          animationDirectionStart: 'bottom-top',
          gap: 0.9,
          distance: 0,
          deep: false
        }, () => {
          this.swiperInstance.allowSlideNext = true;
          this.swiperInstance.allowSlidePrev = true;
          // setTimeout(() => {
          //   this.pageFlag.dataPage = true;
          //   this.pageFlag.back = false;
          // }, 3000)
        });
      }, 0);
    },
    goBackTaskPage() {
      this.$PUSH({
        path: '/annual/task'
      });
      this.setMonitor('posterBackHome');
    },
    createValue() {
      const openid = localStorage.getItem('openid');
      let env = process.env.VUE_APP_ENV;
      let url;
      if (env == 'test') {
        url = `https://dev-bayer.blue-dot.com.cn/bayer-rad-academy-shadowcloud-academy-html/annual/task?from_openid=${openid}`;
      } else if (env == 'development') {
        url = `https://newradacademy-q.wechat.bayer.cn/bayer-rad-academy-shadowcloud-academy-html/annual/task?from_openid=${openid}`;
      } else {
        url = `https://newradacademy.wechat.bayer.cn/bayer-rad-academy-shadowcloud-academy-html/annual/task?from_openid=${openid}`;
      }
      return url;
    },
    setInitDataPage() {
      var _this$infoData;
      this.studyTime = (0, _utils.formatSecondsAsMinutes)(this.infoData.learn_times);
      this.rankTxtObj = (0, _data.getRankTxt)(this.infoData.rank, this.infoData.rank_percent);
      this.studyTimeTxt = (0, _data.displayQuestion)(this.studyTime.time);
      this.calssName = ((_this$infoData = this.infoData) === null || _this$infoData === void 0 ? void 0 : _this$infoData.title) || '';
      //  this.calssName = '影像医'
      // this.$nextTick(() => {
      //   this.calculateLines()
      // }, 200)
    },
    goQusetionPage() {
      this.pageFlag.dataPage = false;
      this.pageFlag.question = true;
      this.questionAniFunc();
      this.setMonitor('pageAnswer');
      this.setMonitor('descBtn');
    },
    async cutImage() {
      setTimeout(() => {
        const detailPosterInner = document.getElementById(`posterId`);
        let w = detailPosterInner.offsetWidth * 4;
        let h = detailPosterInner.offsetHeight * 4;
        (0, _html2canvas.default)(detailPosterInner, {
          useCORS: true,
          scale: 3,
          width: detailPosterInner.offsetWidth
        }).then(canvas => {
          let url = canvas.toDataURL();
          console.log('url', url);
          this.posterImg = url;
          this.$api.setFinishedNum({});
          this.reportPoster(document.getElementById('posterImage'));
        });
      }, 1000);

      //   const detailPosterInner = document.getElementById(`posterId`)
      //  let res = await html2canvas(detailPosterInner, { useCORS: true, scale:3 });
      //   this.posterImg  = res.toDataURL("image/jpg", 1);
    },
    questionInit() {
      this.curQuestionData = this.answerList.question1;
    },
    chooseQuestionItem(optionId) {
      this.curItemIndex = optionId;
      this.userChoose[this.currentQuestion] = optionId;
    },
    answerHandle() {
      if (!this.userChoose[this.currentQuestion]) {
        return (0, _toast.default)({
          message: '您还没有作答',
          position: 'middle'
        });
      }
      if (this.currentQuestion === 1) {
        this.currentQuestion = 2;
        let otherQuestion = {};
        otherQuestion.title = this.answerList.question2.title;
        otherQuestion.options = this.answerList.question2.options[this.curItemIndex];
        this.curQuestionData = otherQuestion;
        this.curItemIndex = null; // 重置选中项索引，以便下一题的选项不会预先被选中
        this.questionAniFunc();
      } else if (this.currentQuestion === 2) {
        // 可以在这里处理完成第二题后的逻辑
        this.questionEnd();
      }
    },
    questionEnd() {
      this.userChoose = Object.values(this.userChoose).join('');
      this.posterResult = (0, _data.getPosterResult)(this.userChoose); //根据答题显示结果
      console.log('海报结果:', this.posterResult);
      this.posterDescTxt = _data.posterDesc[this.posterResult - 1];
      this.pageFlag.poster = true;
      this.pageFlag.question = false;
      this.cutImage(); //截图

      this.setMonitor(`answerResult-${this.userChoose}`);
      this.setMonitor(`renderPoster-${this.posterResult}`);
      this.setMonitor(`pagePoster`);
      console.log('完成了第二题', this.userChoose);
    },
    // chooseQuestionItem(id){
    //     this.curItemIndex = id;
    //     this.userChoose[this.questionIndex] = id;
    // }
    async setMonitor(key) {
      await this.$api.setViewLog({
        action_key: key,
        from_openid: this.urlFormOpenid || ''
      });
    },
    reportPoster(imgEle) {
      console.log('imgEle,', imgEle);
      let _startTime = 0;
      const touchEndAndCancel = () => {
        if (Date.now() - _startTime > 200) {
          console.log('上传海报');
          this.setMonitor(`longPosterCount`);
          _startTime = Date.now();
        }
      };
      imgEle.addEventListener('touchstart', () => _startTime = Date.now());
      imgEle.addEventListener('touchmove', () => _startTime = Date.now());
      imgEle.addEventListener('touchend', touchEndAndCancel);
      imgEle.addEventListener('touchcancel', touchEndAndCancel);
    }
  }
});