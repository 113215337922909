"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.answerList = void 0;
exports.displayQuestion = displayQuestion;
exports.getPosterResult = getPosterResult;
exports.getRankTxt = getRankTxt;
exports.yearBackTxt = exports.posterDesc = void 0;
let yearBackTxt = exports.yearBackTxt = {
  oldUser: ` <span class="swiper_item" style="font-weight:bold;opacity:0"> “想练就火眼金睛，<br />轻松辨别各种疑难杂症？”<br /><br /></span>
<span class="swiper_item">向新而行，迎难而上<br/>
步向影像高峰的崭新起点<br/><br/></span>

<span class="swiper_item">此行山高路远<br/>
我们与你继续「医」路同行<br/>
不负时光，努力攀登<br/>
<span class="backBlodTxt swiper_item"  style="font-weight:bold">共谱影像岁月的新篇章</span></span>`,
  newUser: ` <span class="swiper_item"  style="font-weight:bold">“如何横扫知识盲区，<br/>让影像 levels up up up？”<br/><br/></span>
<span class="swiper_item">
心之所向是影像高山<br/>   
坚持向上，没有高不可攀<br/><br/></span>



<span class="swiper_item">在这场登山新旅中<br/>
我们会陪你一路逐级而上<br/>
攀登知识新领域<br/>
<span class="swiper_item backBlodTxt" style="font-weight:bold">征服影像专业新高地</span></span>`
};
let answerList = exports.answerList = {
  question1: {
    title: '如果用一个词形容您2024年的工作状态，您会选？',
    options: [{
      name: 'A. 初涉影像',
      id: '0'
    }, {
      name: 'B. 渐入佳境',
      id: '1'
    }, {
      name: 'C. 轻车熟路',
      id: '2'
    }, {
      name: 'D. 求索高峰',
      id: '3'
    }]
  },
  question2: {
    title: '过去一年，<br />让您记忆最深刻的影像科碎片是？',
    options: [[{
      name: 'A. 打报告打到手抖',
      id: '0'
    }, {
      name: 'B. 家人朋友聚会变成「读片大会」',
      id: '1'
    }, {
      name: 'C. 发现微小病灶，喜提主任认可',
      id: '2'
    }], [{
      name: 'A. 最忙的一天，连续工作超过16小时',
      id: '0'
    }, {
      name: 'B. 熬夜科研，终于取得成果',
      id: '1'
    }, {
      name: 'C. 参与会诊，获得其他科室好评',
      id: '2'
    }], [{
      name: 'A. 被患者信任，点名找我拍片',
      id: '0'
    }, {
      name: 'B. 开始带学生一起科研，年轻人的热情超乎想象',
      id: '1'
    }, {
      name: 'C. 和大佬一同会诊，自己的意见越来越被重视',
      id: '2'
    }], [{
      name: 'A. 各科大佬排队等我会诊',
      id: '0'
    }, {
      name: 'B. 跨院、海外交流，带领医院影像科被更多同行认可',
      id: '1'
    }, {
      name: 'C. 学无止境，发现依然有很多技术、文献亟待学习',
      id: '2'
    }]]
  }
};
let posterDesc = exports.posterDesc = ['忙而不乱，为患者生成专业影像，也能和家人一起留下温暖的时光之影。', '勇担「医生的医生」，能力不受限，和各科室一起披荆斩棘。', '让更多人了解、信任影像科医生，让影像科也能闪闪发光。', '精进专业，探索技术前沿处，挑战影像更高峰。'];
function getPosterResult(idStr) {
  let result = {
    // 1: ['00', '10', '30'],
    // 2: ['11', '21', '32'],
    // 3: ['01', '20', '31'],
    // 4: ['02', '12', '22']
    1: ['00', '10', '32'],
    2: ['11', '21', '30'],
    3: ['01', '20', '31'],
    4: ['02', '12', '22']
  };

  // 遍历result对象的每个键值对
  for (let poster in result) {
    // 检查每个poster数组是否包含idStr
    if (result[poster].includes(idStr)) {
      return poster; // 返回匹配的poster键
    }
  }

  // 如果没有找到匹配项，返回null或者相应提示
  return null;
}
function displayQuestion(minutes) {
  if (minutes > 48000) {
    return '哇塞！学习时间竟然超过了100个工作日，每天8小时的全勤投入！';
  } else if (minutes > 6000 && minutes <= 48000) {
    return '相当于深度学习了10本影像学书籍！';
  } else if (minutes > 3000 && minutes <= 6000) {
    return '相当于飞到巴黎细细观摩了卢浮宫博物馆的所有艺术展品！';
  } else if (minutes > 1200 && minutes <= 3000) {
    return '相当于徒步攀登华山，并在山顶看到了第一抹旭日初升！';
  } else if (minutes > 300 && minutes <= 1200) {
    return '相当于0基础学会单板滑雪，小试牛刀顺利滑下了雪坡！';
  } else if (minutes > 60 && minutes <= 300) {
    return '向山而行，勤学笃行，就能一直走在影像的上坡路！';
  } else if (minutes >= 0 && minutes <= 60) {
    return '每天多学一小时，未来定能攀到影像行业之巅！';
  } else {
    return '每天多学一小时，未来定能攀到影像行业之巅！';
  }
}
function getRankTxt(rank, percent) {
  let rankText;
  if (rank == 1) {
    rankText = `排名第${rank}名`;
  } else if (rank > 1 && rank <= 10) {
    rankText = `排名第${rank}名`;
  } else if (rank > 10 && rank <= 50) {
    rankText = `排名第${rank}名`;
  } else if (rank > 50 && rank <= 100) {
    rankText = `排名第${rank}名`;
  } else if (rank > 100 && rank <= 200) {
    rankText = `排名第${rank}名`;
  } else {
    // 计算档次，每500名一个档次
    let bracket = Math.ceil(rank / 500) * 500;
    rankText = `排名前${bracket}名`;
  }
  let surpassText;
  if (rank == 1) {
    surpassText = `你已经超过<span style="font-size:19px;font-weight:bold;">${percent}%</span>的影像同行者，`;
  } else if (rank > 1 && rank <= 3000) {
    surpassText = `你已经超过了<span style="font-size:19px;font-weight:bold;">${percent}%</span>的影像同行者，`;
  } else {
    surpassText = '';
  }
  let encouragementText;
  if (rank == 1) {
    encouragementText = '祝你始终占据山顶，是永远的NO.1。';
  } else if (rank > 1 && rank <= 10) {
    encouragementText = '祝你勇攀巅峰站到更高的山峰。';
  } else if (rank > 10 && rank <= 50) {
    encouragementText = '祝你持续积蓄能量向着高出跃进。';
  } else if (rank > 50 && rank <= 100) {
    encouragementText = '祝你持续攀登探索更多未知风景。';
  } else if (rank > 100 && rank <= 200) {
    encouragementText = '愿你未来在医海中顺风远航。';
  } else if (rank > 200 && rank <= 1000) {
    encouragementText = '愿你御风而行赶赴新征程。';
  } else if (rank > 1000 && rank <= 3000) {
    encouragementText = '祝你攀登更高峰。';
  } else {
    encouragementText = '探索更多学术资源，拜耳影像学苑为你的影像征途护航。';
  }
  return {
    lv: rankText,
    surpass: surpassText,
    txt: encouragementText
  };
}