import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
var dataBases = require("./base");
var dataBase = dataBases.dataBase;
export function selectPrev() {
  return dataBase.filter(function (im) {
    return im.AREACODE.length == 2;
  });
}
export function selectCity() {
  return dataBase.filter(function (im) {
    return im.AREACODE.length == 4;
  });
}
export function selectArea() {
  return dataBase.filter(function (im) {
    return im.AREACODE.length == 6;
  });
}
export function selectNumCtiy(num) {
  return selectCity().filter(function (im) {
    return im.AREACODE.substring(0, 2) == num;
  });
}
export function selectNumArea(num) {
  return selectArea().filter(function (im) {
    return im.AREACODE.substring(0, 4) == num;
  });
}