var _typeof = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/typeof.js").default;
if ((typeof WeixinJSBridge === "undefined" ? "undefined" : _typeof(WeixinJSBridge)) == "object" && typeof WeixinJSBridge.invoke == "function") {
  handleFontSize();
} else {
  if (document.addEventListener) {
    document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
  } else if (document.attachEvent) {
    document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
  }
}
function handleFontSize() {
  // 设置网页字体为默认大小
  WeixinJSBridge.invoke('setFontSizeCallback', {
    'fontSize': 0
  });
  // 重写设置网页字体大小的事件
  WeixinJSBridge.on('menu:setfont', function () {
    WeixinJSBridge.invoke('setFontSizeCallback', {
      'fontSize': 0
    });
  });
}