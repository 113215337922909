import http from 'utils/http';
export default {
  /* 首页-每日推荐 */getHomeRec: function getHomeRec(params) {
    return http({
      url: "/api/platform/home/recommend",
      method: 'get'
    });
  },
  /* 首页-Banner推荐 */getHomeBanner: function getHomeBanner(params) {
    return http({
      url: "/api/platform/home/banner",
      method: 'get'
    });
  },
  /* 首页-功能隐藏-设置 */getHomeSetting: function getHomeSetting(params) {
    return http({
      url: "/api/platform/home/setting",
      method: 'get'
    });
  },
  /* 首页-课程推荐(最新0/最热1) 返回5条 */getContentHome: function getContentHome(params, show_type) {
    return http({
      url: "/api/platform/content/home/".concat(show_type),
      method: 'get'
    });
  },
  /* 成就分享的回流 */setMedalcertificateback: function setMedalcertificateback(params) {
    return http({
      url: "/api/platform/medalcertificateback",
      method: 'post',
      data: params
    });
  },
  /* 首页-直播推荐 返回3条 */getHomeMeeting: function getHomeMeeting(params, show_type) {
    return http({
      url: "/api/platform/home/home-meeting",
      method: 'get'
    });
  },
  imageupload: function imageupload(params) {
    return http({
      url: "/api/platform/imageupload",
      method: 'post',
      upload: 1,
      data: params
    });
  },
  homeSearch: function homeSearch(params) {
    return http({
      url: "/api/platform/home-search",
      method: 'get',
      params: params
    });
  }
};