import "core-js/modules/es.array.concat.js";
import { version } from '@/config';
import cookies from 'vue-cookies';
var localVersion = localStorage.getItem("version");

/* 监听input 输入version时弹出当前版本和本地版本 */
var alertTimer = null;
window.addEventListener("input", function (evt) {
  if (alertTimer) {
    clearTimeout(alertTimer);
    alertTimer = null;
  }
  alertTimer = setTimeout(function () {
    var _evt$target$value;
    if (((_evt$target$value = evt.target.value) === null || _evt$target$value === void 0 ? void 0 : _evt$target$value.toLocaleLowerCase()) == 'version') {
      alert("\u5F53\u524D\u7248\u672C\uFF1A".concat(version, "\uFF1B\u672C\u5730\u7248\u672C\uFF1A").concat(localVersion));
    }
    clearTimeout(alertTimer);
    alertTimer = null;
  }, 3000);
});

/* 对比版本，版本不一致重新授权 */
if (!localVersion || localVersion != version) {
  cookies.set("openid", "", "1s", "/bayer-rad-academy-shadowcloud-academy-html");
  cookies.set("wechatInfo", "", "1s", "/bayer-rad-academy-shadowcloud-academy-html");
  cookies.set("redirect", "", "1s", "/bayer-rad-academy-shadowcloud-academy-html");
  cookies.remove('redirect', '');
  cookies.remove('openid', '');
  cookies.remove('wechatInfo', '');
  localStorage.clear();

  /* 清除完成以后，直接重置版本【可以不用在路由里赋值】 */
  localStorage.setItem("version", version);
}