"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _index = _interopRequireDefault(require("@/components/customSelect/index.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    customSelect: _index.default
  },
  data() {
    return {
      pageQuery: null,
      tipWords: "",
      formData: {
        privacy: false,
        // terms: false,
        firstNick: false,
        firstAvatar: false
      },
      refArr: ["work_upgrades", "intrasting_sys", "intrasting_topic", "skill_area"],
      isSubmiting: false
    };
  },
  watch: {},
  filter: {},
  created() {
    this.pageQuery = this.$route.query;
    if (this.pageQuery.mode == "edit") {
      this.formData = {
        ...this.formData,
        ...this.userInfo
      };
      this.formData.shows.includes("2") && (this.formData.firstNick = true);
      this.formData.shows.includes("1") && (this.formData.firstAvatar = true);
      this.formData.shows = [];
    }
  },
  mounted() {},
  computed: {
    positionSelectList() {
      return this.$store.getters.positionSelectList;
    },
    skillAreaSelectList() {
      return this.$store.getters.skillAreaSelectList;
    },
    intrastingTopicSelectList() {
      return this.$store.getters.intrastingTopicSelectList;
    },
    intrastingSysSelectList() {
      return this.$store.getters.intrastingSysSelectList;
    },
    workUpgradesSelectList() {
      return this.$store.getters.workUpgradesSelectList;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    }
  },
  methods: {
    closeAllRef(refName) {
      this.refArr.forEach(im => {
        var _this$$refs$im;
        if (im == refName) return;
        (_this$$refs$im = this.$refs[im]) === null || _this$$refs$im === void 0 || _this$$refs$im.closePop();
      });
    },
    /* 文件上传 Start */
    async uploadImg() {
      // this.$refs["fileInput"].click()
      let res = await this.$chooseImage();
      if (res !== null && res !== void 0 && res.imageBase64) {
        this.fileInputChange(res.imageBase64);
      }
    },
    async fileInputChange(base64) {
      const self = this;
      const selectFileInfo = self.$refs.fileInput.files;
      console.log(selectFileInfo, '==> selectFileInfo');
      let params = new FormData();
      params.append("file", base64);
      let res = await this.$api.imageuploadBase64(params);
      if (res !== null && res !== void 0 && res.errcode) {
        // alert("文件上传失败")
        this.$toatsView.show({
          tipWords: `文件上传失败，请重试`
        });
        return;
      }
      this.formData.avatar_virtual = res.url;
      this.$forceUpdate();
    },
    /* 文件上传 End */
    checkValue(params) {
      this.formData[params.field] = params.check;
    },
    to(value) {
      console.log(value, '==>value');
    },
    async registerSubmit() {
      /*
      if (!this.formData.privacy) {
        this.tipWords = "请浏览并同意签署《拜耳隐私政策》"
        this.$refs["customPop-tip"].open()
        return
      }
      */

      let params = JSON.parse(JSON.stringify(this.formData));
      // if (params.firstAvatar || params.firstNick) {
      // }
      /* 99无用，但是传空数组会被去掉（/(ㄒoㄒ)/~~） */
      params["shows"] = ['99'];
      if (params.firstAvatar) {
        params["shows"].push('1');
      }
      if (params.firstNick) {
        params["shows"].push('2');
      }
      if (params.firstNick && !this.formData.nickname) {
        this.tipWords = "您还没有填写昵称";
        this.$refs["customPop-tip"].open();
        return;
      }
      if (params.firstAvatar && !this.formData.avatar_virtual) {
        this.tipWords = "您还没有上传虚拟头像";
        this.$refs["customPop-tip"].open();
        return;
      }
      delete params["privacy"];
      delete params["firstAvatar"];
      delete params["firstNick"];

      /* flag用于区分是基础信息和完善信息     0：基础信息 1：完善信息 */
      params['flag'] = 1;

      // this.$refs["customPop-cus"].open()
      // this.$refs.customPop.open()

      if (this.isSubmiting) return;
      this.isSubmiting = true;
      delete params["sync_scrm_flag"];
      let res = await this.$api.updateUserInfo(params);
      this.isSubmiting = false;
      if (res !== null && res !== void 0 && res.errcode) {
        // alert("提交失败")
        this.$toatsView.show({
          tipWords: `提交失败，请重试`
        });
        return;
      }
      this.$store.dispatch("getUserInfo");
      if (Number(res === null || res === void 0 ? void 0 : res.score) > 0) {
        setTimeout(() => {
          this.$toatsView.show({
            tipWords: "提交成功"
          });
          setTimeout(() => {
            var _this$pageQuery;
            const registerBackUrl = localStorage.getItem("registerBackUrl");
            if (registerBackUrl) {
              window.location.href = decodeURIComponent(registerBackUrl);
              localStorage.removeItem("registerBackUrl");
              return;
            }
            if ((_this$pageQuery = this.pageQuery) !== null && _this$pageQuery !== void 0 && _this$pageQuery.channel) {
              this.$REDIRECT({
                path: '/personal'
              });
              return;
            }
            this.$GO(-1);
          }, 1500);
        }, 2500);
        return;
      }
      this.$toatsView.show({
        tipWords: "提交成功"
      });
      setTimeout(() => {
        var _this$pageQuery2;
        const registerBackUrl = localStorage.getItem("registerBackUrl");
        if (registerBackUrl) {
          window.location.href = decodeURIComponent(registerBackUrl);
          localStorage.removeItem("registerBackUrl");
          return;
        }
        if ((_this$pageQuery2 = this.pageQuery) !== null && _this$pageQuery2 !== void 0 && _this$pageQuery2.channel) {
          this.$REDIRECT({
            path: '/personal'
          });
          return;
        }
        this.$GO(-1);
      }, 1500);

      // this.$REDIRECT({ path: '/' })
    },
    customPopCancle() {},
    customPopConfirm() {},
    registerSuccessHandleDelay() {
      this.$refs['customPop-cus'].close();
    },
    registerSuccessHandleNow() {
      this.$refs['customPop-cus'].close();
    }
  }
});