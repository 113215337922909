import Vue from 'vue';
import registerView from '@/components/customPopview/login.vue';
import privacyView from '@/components/customPopview/privacy.vue';
import termsView from '@/components/customPopview/terms.vue';
import toatsView from '@/components/customPopview/index.vue';
import newAgreeView from '@/components/customPopview/newAgree.vue';
import importRegisterView from '@/components/customPopview/ImportRegister.vue';
import hospitalLoseView from '@/components/customPopview/hospitalLose.vue';
import experiencePopView from '@/components/customPopview/experiencePop.vue';

//使用extend创建的是一个组件构造器
var registerViewConstructor = Vue.extend(registerView);
var privacyViewConstructor = Vue.extend(privacyView);
var termsViewConstructor = Vue.extend(termsView);
var toatsViewConstructor = Vue.extend(toatsView);
var newAgreeViewConstructor = Vue.extend(newAgreeView);
var importRegisterViewConstructor = Vue.extend(importRegisterView);
var hospitalLoseViewConstructor = Vue.extend(hospitalLoseView);
var experiencePopConstructor = Vue.extend(experiencePopView);

/* 注册弹窗 Start */
//实例化组并创建一个div
var instance = new registerViewConstructor({
  el: document.createElement('div')
});
var newRegisterView = {
  show: function show(callBack) {
    // 接受传递过来的问题，调用此方法时同事显示弹窗
    instance.open(callBack);
    document.body.appendChild(instance.$el);
  },
  hide: function hide() {
    // 隐藏方法
    instance.close();
  }
};
/* 注册弹窗 End */

/* 30天体验权限弹窗 Start */
//实例化组并创建一个div
var instanceExperience = new experiencePopConstructor({
  el: document.createElement('div')
});
var instanceExperienceView = {
  show: function show(options) {
    // 接受传递过来的问题，调用此方法时同事显示弹窗
    // let {isBack,callBack,days} = options;
    instanceExperience.open(options);
    document.body.appendChild(instanceExperience.$el);
  },
  hide: function hide() {
    // 隐藏方法
    instanceExperience.close();
  }
};
/* 30天体验权限弹窗 End */

/* 医院无效弹窗 Start */
//实例化组并创建一个div
var hospitalLoseInstance = new hospitalLoseViewConstructor({
  el: document.createElement('div')
});
var hospitalLose = {
  show: function show(callBack) {
    // 接受传递过来的问题，调用此方法时同事显示弹窗
    hospitalLoseInstance.open(callBack);
    document.body.appendChild(hospitalLoseInstance.$el);
  },
  hide: function hide() {
    // 隐藏方法
    hospitalLoseInstance.close();
  }
};
/* 医院无效弹窗 End */

/* 导入用户的完善信息弹窗 Start */
//实例化组并创建一个div
var importRegisterInstance = new importRegisterViewConstructor({
  el: document.createElement('div')
});
var newImportRegister = {
  show: function show(callBack) {
    // 接受传递过来的问题，调用此方法时同事显示弹窗
    importRegisterInstance.open(callBack);
    document.body.appendChild(importRegisterInstance.$el);
  },
  hide: function hide() {
    // 隐藏方法
    importRegisterInstance.close();
  }
};
/* 导入用户的完善信息弹窗 End */

/* 全局弹窗 Start */
//实例化组并创建一个div
var toatsInstance = new toatsViewConstructor({
  el: document.createElement('div')
});
var newToatsView = {
  show: function show(callBack) {
    // 接受传递过来的问题，调用此方法时同事显示弹窗
    toatsInstance.open(callBack);
    document.body.appendChild(toatsInstance.$el);
  },
  hide: function hide(params) {
    // 隐藏方法
    toatsInstance.close(params);
  }
};
/* 全局弹窗 End */

/* 个人信息披露知情同意 Start */
//实例化组并创建一个div
var newAgreeViewinstance = new newAgreeViewConstructor({
  el: document.createElement('div')
});
var newPrivacyInstanceView = {
  show: function show(callBack) {
    // 接受传递过来的问题，调用此方法时同事显示弹窗
    newAgreeViewinstance.open(callBack);
    document.body.appendChild(newAgreeViewinstance.$el);
  },
  hide: function hide() {
    // 隐藏方法
    newAgreeViewinstance.close();
  }
};
/* 个人信息披露知情同意 End */

/* 隐私声明 Start */
//实例化组并创建一个div
var privacyViewinstance = new privacyViewConstructor({
  el: document.createElement('div')
});
var newPrivacyView = {
  show: function show(callBack) {
    // 接受传递过来的问题，调用此方法时同事显示弹窗
    privacyViewinstance.open(callBack);
    document.body.appendChild(privacyViewinstance.$el);
  },
  hide: function hide() {
    // 隐藏方法
    privacyViewinstance.close();
  }
};
/* 隐私声明 End */

/* 使用条款 Start */
//实例化组并创建一个div
var termsViewinstance = new termsViewConstructor({
  el: document.createElement('div')
});
var newtermsView = {
  show: function show(callBack) {
    // 接受传递过来的问题，调用此方法时同事显示弹窗
    termsViewinstance.open(callBack);
    document.body.appendChild(termsViewinstance.$el);
  },
  hide: function hide() {
    // 隐藏方法
    termsViewinstance.close();
  }
};
/* 使用条款 End */

var install = function install() {
  if (!Vue.prototype.$registerView) {
    Vue.prototype.$registerView = newRegisterView;
  }
  ;
  if (!Vue.prototype.$importRegisterView) {
    Vue.prototype.$importRegisterView = newImportRegister;
  }
  ;
  if (!Vue.prototype.$hospitalLoseView) {
    Vue.prototype.$hospitalLoseView = hospitalLose;
  }
  ;
  if (!Vue.prototype.$privacyView) {
    Vue.prototype.$privacyView = newPrivacyView;
  }
  ;
  if (!Vue.prototype.$toatsView) {
    Vue.prototype.$toatsView = newToatsView;
  }
  ;
  if (!Vue.prototype.$termsView) {
    Vue.prototype.$termsView = newtermsView;
  }
  ;
  if (!Vue.prototype.$privacyInstanceView) {
    Vue.prototype.$privacyInstanceView = newPrivacyInstanceView;
  }
  ;
  if (!Vue.prototype.$instanceExperienceView) {
    Vue.prototype.$instanceExperienceView = instanceExperienceView;
  }
  ;
  Vue.mixin({
    created: function created() {
      Vue.prototype.$toatsView = Vue.prototype.$toatsView;
      Vue.prototype.$registerView = Vue.prototype.$registerView;
      Vue.prototype.$importRegisterView = Vue.prototype.$importRegisterView;
      Vue.prototype.$privacyView = Vue.prototype.$privacyView;
      Vue.prototype.$termsView = Vue.prototype.$termsView;
      Vue.prototype.$privacyInstanceView = Vue.prototype.$privacyInstanceView;
      Vue.prototype.$instanceExperienceView = Vue.prototype.$instanceExperienceView;
    }
  });
};
export default install;