import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import Vue from 'vue';
import router from '@/router';
import store from '@/store';
import { title, type, appid, middlePlatform, deploy, baseUrl, monitor } from '@/config';
import http from 'utils/http';
import { getUrlParams, handleParmas } from './index';
var whiteMonitor = ["/coursedetail", "/previewmeetingdetail", "/activitymoduledetail", "/activitycontentdetail"];

// 监测 api 文档 https://middle-platform.blue-dot.cn/docs/analysis
export function dataStatistics(_ref, json) {
  var page_title = _ref.page_title,
    event_type = _ref.event_type,
    event_key = _ref.event_key,
    typeMap = _ref.typeMap,
    content_id = _ref.content_id,
    campain_id = _ref.campain_id,
    section_id = _ref.section_id,
    firstParams = _ref.firstParams;
  page_title = page_title || "".concat(title, ":").concat(document.title);
  /** 通用参数配置 */
  var wechatInfo = store.getters.wechatInfo;
  if (!wechatInfo) return;
  var openid = wechatInfo.openid,
    unionid = wechatInfo.unionid;
  var href = window.location.href;
  var query = getUrlParams(); // 获取页面参数

  var url = window.location.href;
  whiteMonitor.forEach(function (im) {
    console.log(url, '==?> url -------------');
    console.log(im, '==?> im -------------');
    if (url.indexOf(im) != -1) {
      console.log("======================================");
      throw "";
    }
  });

  /* if(whiteMonitor.includes(value.route.path)){
    return
  } */

  console.log(url, '==> monitor url ');

  // 未遇到 keywords 的情况
  var _keywords = '';
  if (json && json.keywords) {
    _keywords = json.keywords || [];
    if (_keywords.length > 0) {
      _keywords = _keywords.map(function (item) {
        return item.tag_id;
      });
    } else {
      _keywords = '';
    }
  }
  var user_id = '';
  var params = {
    /* 原本是config中的配置，现在修改为自定义的 */
    type: query.typeMap || typeMap,
    content_id: content_id || query.id,
    user_id: user_id,
    // 用户id
    unionid: unionid,
    openid: openid,
    page_title: page_title,
    // 页面标题
    // page_description: '', // 页面描述
    page_url: encodeURIComponent(href),
    // 页面 url
    page_referer_url: '',
    // 页面来源 (从哪里跳转过来)
    page_param: query,
    // 页面参数
    keywords: _keywords,
    // 标签 id (---目前未接触---)
    page_event_key: event_key,
    page_event_type: event_type,
    // view 浏览, click 点击, share 分享
    campain_id: campain_id
  };
  if (query !== null && query !== void 0 && query.typeMap || typeMap) {
    params["monior_type"] = 1;
  }
  if (section_id) {
    params['section_id'] = section_id;
  }
  if (firstParams) {
    params["type"] = typeMap;
  }
  if (typeMap + '' == '6') {
    params["campain_id"] = query.campain_id;
  }
  if (query.fromsource) {
    params["fromsource"] = query.fromsource;
  }
  if (query.opentype) {
    params["opentype"] = query.opentype;
  }
  // if(url.indexOf("/personal/history") != -1){
  //   params["monior_type"] = 1
  // }

  var isRegister = localStorage.getItem("isRegister");
  if (!isRegister && !(query !== null && query !== void 0 && query.fromsource || query !== null && query !== void 0 && query.channel)) return;
  var request_api = "".concat(baseUrl.base, "/api/platform/monitor"); // 中台域名
  http({
    url: request_api,
    custom: 1,
    method: 'post',
    params: handleParmas(params)
  }).then(function (res) {});
}
export function monitorView() {
  dataStatistics({
    event_type: 'view',
    // view = 浏览  click = 点击   share = 分享
    page_title: "".concat(title, ":").concat(document.title)
  });
}
export function monitorClick(event_key) {
  dataStatistics({
    event_type: 'click',
    event_key: event_key,
    page_title: "".concat(title, ":").concat(document.title)
  });
}
export function monitorShare() {
  dataStatistics({
    event_type: 'share'
  });
}

// 注册指令
(function () {
  if (!store.getters.monitor) {
    store.dispatch('directiveMonitor');
    Vue.directive('monitor', {
      bind: function bind(el, binding, vnode) {
        function clickHandler(e) {
          if (!monitor.open_state) return;
          // 判断点击的元素是否是本身，提交监测
          if (el.contains(e.target)) {
            var eventKey = el.getAttribute("event-key");
            var pate_title = router.app._route.meta.title || document.title;
            var value = binding.value || "\u70B9\u51FB:".concat(pate_title, ":").concat(eventKey);
            monitorClick(value);
          }
        }
        el.__vueClickOutside__ = clickHandler;
        document.addEventListener('click', clickHandler);
      },
      unbind: function unbind(el, binding, vnode) {
        // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
      }
    });
  }
})();