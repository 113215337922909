import http from 'utils/http';
export default {
  /* 会议页面-预览接口 */getPreviewMeeting: function getPreviewMeeting(params, id) {
    return http({
      url: "/api/platform/demo/meeting/".concat(id),
      method: 'get',
      params: params
    });
  },
  /* 活动-模块-预览接口 */getPreviewCampainSection: function getPreviewCampainSection(params, id) {
    return http({
      url: "/api/platform/demo/campain-section/".concat(id),
      method: 'get',
      params: params
    });
  },
  /* 活动-内容-预览接口 */getPreviewCampainContent: function getPreviewCampainContent(params, id) {
    return http({
      url: "/api/platform/demo/campain-content",
      method: 'get',
      params: params
    });
  },
  /* 课程-预览接口 */getPreviewCourseContent: function getPreviewCourseContent(params, id) {
    return http({
      url: "/api/platform/demo/content/".concat(id),
      method: 'get',
      params: params
    });
  },
  /* 活动-内容-图文-预览接口 */getPreviewCampainContentRich: function getPreviewCampainContentRich(params, id) {
    return http({
      url: "/api/platform/demo/campain-content/".concat(id),
      method: 'get',
      params: params
    });
  }
};