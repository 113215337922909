import Vue from 'vue';
import aiPopup from 'aui/senior/ai-popup-vue';
var AiPopup = Vue.extend(aiPopup);
aiPopup.install = function (data) {
  var instance = new AiPopup({
    data: data
  }).$mount();
  document.body.appendChild(instance.$el);
  Vue.nextTick(function () {
    instance.show = true;
  });
};
export default aiPopup;