import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import _typeof from "D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.starts-with.js";
import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import router from "@/router/index.js";
import qs from 'qs';
import { baseUrl, middlePlatform, env, deploy } from '@/config';
var queue = []; // 请求队列

var http = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded' // need qs
    // 'Content-Type': 'application/json; charset=utf-8',
    // 'Content-Type': 'multipart/form-data', // need form data
  },
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000
});

/* 请求拦截器 */
http.interceptors.request.use(function (config) {
  // console.log(' --- config --- ', config)
  // 根据环境自动替换 /api -> /api-dev
  if (deploy.service === 'k8s' && env === 'dev' && !config.custom) {
    config.url = config.url.replace('/api/', '/api-dev/');
  }
  if (config.method == "put" || config.method == "PUT") {
    config.headers['Content-Type'] = "application/json; charset=utf-8";
  }
  if (config.isJson) {
    config.headers['Content-Type'] = "application/json; charset=utf-8";
  }
  /**
     * custom 说明：
     * @params {any} custom 是否开启自定义，默认为 false
     *
     * 传入 1 | true 结果为完全自定义
     * 调用时需要传入完整 url 地址，在请求第三方接口时使用
     *
     * 传入字符，表示取配置的域名进行拼接（对应 config.baseUrl）
     * 适合多域名情况，传入 config 中定义的 key, 比如 'baidu'，运行的结果就会取 config 下对应的 url
     */
  if (!config.custom) {
    config.url = "".concat(baseUrl['base']).concat(config.url); // 默认拼接
  } else {
    // 仅判断 typeof === string 不够健壮，但实现了业务逻辑，后续可优化
    if (typeof config.custom === 'string') {
      config.url = "".concat(baseUrl[config.custom]).concat(config.url); // 多域名
    } else {
      config.url = "".concat(config.url); // 完全自定义
    }
  }
  if (isQueue(config.url)) return Promise.reject(new Error('重复请求！已被拦截')); // 拦截重复
  // if (config.loading) Toast.loading({ message: '加载中...' }) // 开启 loading
  // 文件上传
  if (config.upload) {
    config.headers['Content-Type'] = 'multipart/form-data';
    config.onUploadProgress = function (progressEvent) {
      var persent = progressEvent.loaded / progressEvent.total * 100 | 0; //上传进度百分比
      config === null || config === void 0 || config.callBack(String(persent) + '%');
    };
  }
  // 设置 token
  if (deploy.oauth === 'look-star') {
    var token = store.getters.wechatInfo && store.getters.wechatInfo.token;
    config.headers['Authorization'] = "Bearer ".concat(token); // 设置 token // '123' //
  }
  if (config.openid) {
    var openid = store.getters.openid || localStorage.getItem("openid");
    if (config.method === 'get') {
      if (config.params && _typeof(config.params) === 'object') {
        config.params.openid = openid;
      } else {
        config.params = {
          openid: openid
        };
      }
    } else {
      if (config.data && _typeof(config.data) === 'object') {
        config.data.openid = openid;
      } else {
        config.data = {
          openid: openid
        };
      }
    }
  }

  // 转换参数（application/x-www-form-urlencoded 的请求需要转换参数）
  if (config.headers['Content-Type'].startsWith('application/x-www-form-urlencoded')) config.data = qs.stringify(config.data);
  queue.push(config.url); // 添加队列
  return config;
}, function (error) {
  handleError(error);
  return Promise.reject(error);
});

/* 响应拦截器 */
http.interceptors.response.use(function (res) {
  // console.log(' --- res --- ', res)
  // 移除队列
  _Toast.clear();

  // console.log(res,'==> res,=');
  removeQueue(res.config.url);
  var resData = res.data;
  if (res.config.origin) {
    // console.log('origin 钩子', res)
    return Promise.resolve(resData);
  } else {
    // console.log('normal 钩子', res)
    var code = resData.code,
      msg = resData.msg;
    var data = resData.data;
    // console.log('信息提示：', code, data, msg)
    if (code && code === 1000) {
      // 1000 请求成功
      return Promise.resolve(data);
    } else {
      var whiteList = [middlePlatform.baseUrl, '/api/monitor', '/api/js-sdk', '/api/platform'];
      var whiteRes = whiteList.some(function (api) {
        return res.config.url.indexOf(api) > -1;
      });
      if (resData !== null && resData !== void 0 && resData.result && resData !== null && resData !== void 0 && resData.score) {
        if (Number(resData === null || resData === void 0 ? void 0 : resData.score) > 0) {
          setTimeout(function () {
            store.commit('SET_INTEGRAL', {
              show: true,
              integral: resData.score
            });
          }, 500);
        }
      }
      if ((resData === null || resData === void 0 ? void 0 : resData.errcode) == "50001") {
        /* if (window.location.href.indexOf("ishomerecommend=true") != -1) {
          alert("首页推来的")
          store.commit("SET_SHOWAUTHPOP", true)
            return Promise.resolve(resData)
        } */

        store.commit("SET_SHOWAUTHPOP", true);
        return Promise.resolve({
          hasErrCode: "50001"
        });
      }
      if (whiteRes) {
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("数据获取失败：" + res.errmsg)
          return Promise.resolve({});
        }

        // 中台接口不返回 code
        // 中台接口只返回 res.data
        return Promise.resolve(res.data);
      } else {
        // 请求失败
        handleError(msg);
        return Promise.reject(msg);
      }
    }
  }
}, function (error) {
  _Toast.clear();
  console.log('请求错误: ' + error); // for debug
  // 特殊情况
  var exp = String(error).indexOf('401') !== -1;
  if (exp) {
    localStorage.clear();
    store.dispatch('clearWechatInfo');
    window.location.href = window.location.href;
    return Promise.reject(error);
  }
  handleError(error);
  return Promise.reject(error);
});

/** 是否在队列 */
function isQueue(url) {
  queue.includes(url);
}

/** 移除请求 */
function removeQueue(url) {
  var index = queue.findIndex(function (item) {
    return item === url;
  });
  queue.splice(index, 1);
}
function handleError(err) {
  setTimeout(function (t) {
    _Toast(err.message || err || '未捕获的错误');
  }, 30);
}
export default http;