import Vue from 'vue';
import aiConfirm from 'aui/senior/ai-confirm-vue';
var AiConfirm = Vue.extend(aiConfirm);
aiConfirm.install = function (data) {
  var instance = new AiConfirm({
    data: data
  }).$mount();
  document.body.appendChild(instance.$el);
  Vue.nextTick(function () {
    instance.show = true;
  });
};
export default aiConfirm;