import "core-js/modules/es.number.constructor.js";
export default {
  components: {},
  props: {
    src: String,
    width: [String, Number],
    height: [String, Number],
    radius: [String, Number],
    showLoading: {
      type: [Boolean, String, Number],
      default: true
    },
    showError: {
      type: [Boolean, String, Number],
      default: true
    }
  },
  data: function data() {
    return {
      img: null,
      loading: true,
      error: false,
      color: '#eeeeee'
    };
  },
  created: function created() {
    var _this = this;
    if (this.src) {
      var newImg = new Image();
      newImg.src = this.src;
      newImg.onload = function () {
        setTimeout(function (t) {
          _this.img = newImg.src;
          _this.loading = false;
        }, 2000);
      };
      newImg.onerror = function () {
        _this.error = true;
        _this.loading = false;
      };
    }
  },
  mounted: function mounted() {},
  computed: {
    w: function w() {
      var W = this.width;
      return W ? "".concat(W / 8.28, "vw") : '100%';
    },
    h: function h() {
      var H = this.height;
      return H ? "".concat(H / 8.28, "vw") : 'auto';
    },
    r: function r() {
      var R = this.radius;
      return R ? "".concat(R / 8.28, "vw") : 0;
    },
    wrapHeight: function wrapHeight() {
      var H = this.height;
      return H ? "".concat(H / 8.28, "vw") : "".concat(100 / 8.28, "vw");
    }
  },
  filters: {},
  methods: {}
};