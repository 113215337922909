var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "form-container"
  }, [_c("div", {
    staticClass: "form"
  }, [_c("van-form", {
    ref: "form",
    on: {
      failed: _vm.onFailed,
      submit: _vm.onSubmit
    }
  }, [_vm._l(_vm.form, function (item, i) {
    return _c("div", {
      key: i,
      staticClass: "form-item-wrapper"
    }, [_c("ai-gap", {
      attrs: {
        height: _vm.option.top
      }
    }), _vm.commonType(item.type) === "input" ? _c("div", {
      staticClass: "form-item"
    }, [item.label ? _c("div", {
      staticClass: "form-label"
    }, [_c("p", [_vm._v(_vm._s(item.label))]), item.required ? _c("span", [_vm._v("*")]) : _vm._e()]) : _vm._e(), _c("p", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.errorVisiable && _vm.errorIndex === i,
        expression: "errorVisiable && errorIndex === i"
      }],
      staticClass: "form-error"
    }, [_vm._v(_vm._s(_vm.errorMessage))]), _c("van-field", {
      attrs: {
        name: item.prop,
        rules: [{
          required: item.required,
          validator: function validator(value, rule) {
            return _vm.validate(value, rule, item);
          },
          message: function message(value, rule) {
            return _vm.message(value, rule, item);
          }
        }]
      },
      on: {
        input: function input($event) {
          return _vm.input($event, item, i);
        },
        blur: _vm.blur
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), _vm.commonType(item.type) === "textarea" ? _c("div", {
      staticClass: "form-item form-textarea"
    }, [item.label ? _c("div", {
      staticClass: "form-label"
    }, [_c("p", [_vm._v(_vm._s(item.label))]), item.required ? _c("span", [_vm._v("*")]) : _vm._e()]) : _vm._e(), _c("p", {
      staticClass: "form-error"
    }), _c("van-field", {
      attrs: {
        name: item.prop,
        type: "textarea",
        rules: [{
          required: item.required,
          validator: function validator(value, rule) {
            return _vm.validate(value, rule, item);
          },
          message: function message(value, rule) {
            return _vm.message(value, rule, item);
          }
        }]
      },
      on: {
        input: _vm.input,
        blur: _vm.blur
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1) : _vm._e(), _vm.commonType(item.type) === "double" && _vm.countShowCodeButton ? _c("div", {
      staticClass: "form-double"
    }, [_c("div", {
      staticClass: "form-item"
    }, [item.label ? _c("div", {
      staticClass: "form-label"
    }, [_c("p", [_vm._v(_vm._s(item.label))]), item.required ? _c("span", [_vm._v("*")]) : _vm._e()]) : _vm._e(), _c("p", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.errorVisiable && _vm.errorIndex === i,
        expression: "errorVisiable && errorIndex === i"
      }],
      staticClass: "form-error"
    }, [_vm._v(_vm._s(_vm.errorMessage))]), _c("van-field", {
      attrs: {
        name: item.prop,
        rules: [{
          required: item.required,
          validator: function validator(value, rule) {
            return _vm.validate(value, rule, item);
          },
          message: function message(value, rule) {
            return _vm.message(value, rule, item);
          }
        }],
        maxlength: _vm.lengthEnum[item.type]
      },
      on: {
        input: _vm.input,
        blur: _vm.blur
      },
      model: {
        value: item.value,
        callback: function callback($$v) {
          _vm.$set(item, "value", $$v);
        },
        expression: "item.value"
      }
    })], 1), _c("div", {
      staticClass: "code-tip",
      class: {
        "can-send": _vm.canSend || _vm.timer
      },
      on: {
        click: function click($event) {
          return _vm.sendCode(item);
        }
      }
    }, [_c("p", [_vm._v(_vm._s(_vm.codeText))])])]) : _vm._e(), _vm.commonType(item.type) === "select" ? _c("div", {
      staticClass: "form-item"
    }, [item.label ? _c("div", {
      staticClass: "form-label"
    }, [_c("p", [_vm._v(_vm._s(item.label))]), item.required ? _c("span", [_vm._v("*")]) : _vm._e()]) : _vm._e(), _c("p", {
      staticClass: "form-error"
    }), _c("div", {
      staticClass: "select-wrapper",
      class: {
        "gray-input": item.value === "请选择"
      }
    }, [_c("van-field", {
      attrs: {
        value: item.value,
        name: item.prop,
        rules: [{
          validateTrigger: "onChange",
          required: item.required,
          validator: function validator(value, rule) {
            return _vm.validate(value, rule, item);
          },
          message: function message(value, rule) {
            return _vm.message(value, rule, item);
          }
        }],
        readonly: "",
        clickable: "",
        placeholder: "请选择"
      },
      on: {
        click: function click($event) {
          _vm.showOptions = true;
        }
      }
    }), _c("img", {
      staticClass: "arrow-icon",
      class: {
        "arrow-act": _vm.showOptions
      },
      attrs: {
        src: require("aui/components/img/arrow-gray.png")
      }
    }), _c("van-popup", {
      attrs: {
        position: "bottom"
      },
      model: {
        value: _vm.showOptions,
        callback: function callback($$v) {
          _vm.showOptions = $$v;
        },
        expression: "showOptions"
      }
    }, [_c("van-picker", {
      attrs: {
        "show-toolbar": "",
        columns: item.options
      },
      on: {
        confirm: function confirm($event) {
          return _vm.onConfirm($event, item);
        },
        cancel: function cancel($event) {
          _vm.showOptions = false;
        }
      }
    })], 1)], 1)]) : _vm._e(), _vm.commonType(item.type) === "file" ? _c("div", {
      staticClass: "form-item"
    }, [item.label ? _c("div", {
      staticClass: "form-label"
    }, [_c("p", [_vm._v(_vm._s(item.label))]), item.required ? _c("span", [_vm._v("*")]) : _vm._e()]) : _vm._e(), _c("p", {
      staticClass: "form-error"
    }), _c("div", {
      staticClass: "file-wrapper",
      class: {
        "default-button": !item.value || !item.value.length,
        "active-button": item.value && item.value.length
      }
    }, [_c("van-field", {
      attrs: {
        name: "uploader"
      },
      scopedSlots: _vm._u([{
        key: "input",
        fn: function fn() {
          return [_c("van-uploader", {
            attrs: {
              multiple: "",
              "max-count": _vm.fileMaxLength
            },
            model: {
              value: item.value,
              callback: function callback($$v) {
                _vm.$set(item, "value", $$v);
              },
              expression: "item.value"
            }
          }, [_c("div", {
            staticClass: "upload-button-wrapper"
          }, [_c("div", {
            staticClass: "upload-button"
          }, [_c("img", {
            attrs: {
              src: require("aui/components/img/upload.png")
            }
          }), _c("ai-gap", {
            attrs: {
              height: "16"
            }
          }), _c("p", [_vm._v("点击上传文件")]), _c("svg", {
            attrs: {
              width: "100%",
              height: "100%"
            }
          }, [_c("rect", {
            staticStyle: {
              fill: "none",
              "stroke-width": "1",
              stroke: "#e2e2e2",
              "stroke-dasharray": "1 1"
            },
            attrs: {
              width: "100%",
              height: "100%"
            }
          })])], 1)])])];
        },
        proxy: true
      }], null, true)
    })], 1)]) : _vm._e(), _c("ai-gap", {
      attrs: {
        height: _vm.option.bottom
      }
    })], 1);
  }), _c("ai-gap", {
    attrs: {
      height: _vm.option.buttonSpace || 50
    }
  }), _c("button", {
    staticClass: "submit-button",
    attrs: {
      type: "submit"
    }
  }, [_vm._v(_vm._s(_vm.buttonText))])], 2)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };