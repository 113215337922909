import "vant/es/loading/style";
import _Loading from "vant/es/loading";
import "vant/es/popup/style";
import _Popup from "vant/es/popup";
import "vant/es/tabbar/style";
import _Tabbar from "vant/es/tabbar";
import "vant/es/tabbar-item/style";
import _TabbarItem from "vant/es/tabbar-item";
import "vant/es/image/style";
import _Image from "vant/es/image";
import "vant/es/icon/style";
import _Icon from "vant/es/icon";
import "vant/es/form/style";
import _Form from "vant/es/form";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/uploader/style";
import _Uploader from "vant/es/uploader";
import "vant/es/picker/style";
import _Picker from "vant/es/picker";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
// 按需全局引入 vant组件
import Vue from 'vue';

// 默认使用

_Toast.allowMultiple();
_Toast.setDefaultOptions('text', {
  position: 'bottom',
  transition: 'van-fade',
  className: 'custom-toast-text'
});
_Toast.setDefaultOptions('loading', {
  forbidClick: true,
  duration: 0,
  message: '加载中...'
});
Vue.use(_Toast).use(_Loading).use(_Popup).use(_Tabbar).use(_TabbarItem).use(_Image).use(_Icon).use(_Form).use(_Field).use(_Uploader).use(_Picker);

// 自行添加
// import { Search } from 'vant'
// Vue.use(Search)