import _objectSpread from "D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import defaultGetters from './defaultGetters';
import { selectPrev } from "@/utils/area/select.js";
var getters = _objectSpread(_objectSpread({}, defaultGetters), {}, {
  // 业务示例
  getTest: function getTest(state) {
    return state.app.test;
  },
  courseClass: function courseClass(state) {
    return state.course.courseClass;
  },
  tabIndex: function tabIndex(state) {
    return state.app.tabIndex;
  },
  courseClassIdArr: function courseClassIdArr(state) {
    return state.course.courseClassIdArr;
  },
  loadingShow: function loadingShow(state) {
    return state.app.loadingShow;
  },
  userInfo: function userInfo(state) {
    return state.app.userInfo;
  },
  callUs: function callUs(state) {
    return state.app.callUs;
  },
  callUsShow: function callUsShow(state) {
    return state.app.callUsShow;
  },
  tologin: function tologin(state) {
    return state.app.tologin;
  },
  projectAuthStatus: function projectAuthStatus(state) {
    return state.app.projectAuthStatus;
  },
  integralInfo: function integralInfo(state) {
    return state.app.integralInfo;
  },
  showSaveBtn: function showSaveBtn(state) {
    return state.app.showSaveBtn;
  },
  showClaim: function showClaim(state) {
    return state.app.showClaim;
  },
  screenTip: function screenTip(state) {
    return state.app.screenTip;
  },
  /* 公用参数 */
  departmentSelectList: function departmentSelectList(state) {
    return ['医学影像科', '超声影像', '肿瘤', '内科', '内科 肝内', '心内科', '外科', '外科 肝外', '介入科', '神经内科', '神经外科', '其他科室'];
  },
  titleSelectList: function titleSelectList(state) {
    return ['医师', '主治医师', '副主任医师', '主任医师', '技师', '主管技师', '副主任技师', '主任技师', '护士', '护师', '主管护师', '副主任护师', '主任护师', '其他'];
  },
  provinceSelectList: function provinceSelectList(state) {
    return selectPrev().map(function (im) {
      return im.AREANAME;
    });
  },
  hospitalSelectList: function hospitalSelectList(state) {
    return ["其他"];
  },
  positionSelectList: function positionSelectList(state) {
    return ['科室主任', 'MRI主任', 'CT主任', '科室副主任', '院长', '书记', '科员', '技师长', '护士长', '其他'];
  },
  skillAreaSelectList: function skillAreaSelectList(state) {
    return ['头颈影像', '心胸影像', '乳腺影像', '腹部影像', '骨肌影像', '技术诊断', '护理规范', '科室管理', 'CT', 'MRI', '儿童', '无特定领域'];
  },
  intrastingTopicSelectList: function intrastingTopicSelectList(state) {
    return ['对比剂安全及规范管理', '成像技术原理', '影像诊断思路', '病例分析', '不同部位或患者人群的扫描方案', '最新文献指南解读', '护理与质控', '科室管理', '科研专题（立项，申请等）', '国家政策国际学术前沿新技术', '数字化医疗', '无'];
  },
  intrastingSysSelectList: function intrastingSysSelectList(state) {
    return ['心血管系统', '呼吸系统', '消化系统', '中枢神经系统', '泌尿系统', '生殖系统', '乳腺疾病', '骨骼肌肉', '儿童', '无'];
  },
  workUpgradesSelectList: function workUpgradesSelectList(state) {
    return ['科室管理能力', '设备操作', '读片能力', '教学能力', '临床沟通技巧', '科研能力', '无'];
  },
  cryptIv: function cryptIv(state) {
    return "bXnJpZoClCchkPNM";
  },
  cryptKey: function cryptKey(state) {
    return "xdz3gagd3M0jMXmI";
  },
  contentType: function contentType(state) {
    return {
      0: "未选",
      1: "视频",
      2: "图文"
    };
  },
  listType: function listType(state) {
    return {
      1: "课程",
      2: "病例",
      3: "其他",
      4: "会议",
      5: "活动"
    };
  }
});
export default getters;