import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: '/register',
  name: 'Register',
  component: function component() {
    return import('views/user/register');
  },
  meta: {
    title: '用户注册',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜用户注册',
      desc: ''
    },
    hideTabbar: true,
    registerNoEnter: true,
    getUser: true
  }
}, {
  path: '/welcome',
  name: 'Welcome',
  component: function component() {
    return import('views/entry/welcome');
  },
  meta: {
    title: '拜耳影像学苑',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑',
      desc: ''
    },
    hideTabbar: true
  }
}, {
  path: '/staffregister',
  name: 'staffRegister',
  component: function component() {
    return import('views/user/staffRegister');
  },
  meta: {
    title: '内部员工注册',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜内部员工注册',
      desc: ''
    },
    hideTabbar: true,
    registerNoEnter: true,
    getUser: true
  }
}, {
  path: '/staffinfoedit',
  name: 'staffInfoEdit',
  component: function component() {
    return import('views/user/staffInfoEdit');
  },
  meta: {
    title: '编辑资料',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    hideTabbar: true,
    checkUser: true
  }
}, {
  path: '/userinfoedit',
  name: 'userInfoEdit',
  component: function component() {
    return import('views/user/userInfoEdit');
  },
  meta: {
    title: '编辑资料',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    hideTabbar: true,
    checkUser: true
  }
}, {
  path: '/userInformationedit',
  name: 'userInformationEdit',
  component: function component() {
    return import('views/user/userInformationEdit');
  },
  meta: {
    title: '编辑资料',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    hideTabbar: true,
    checkUser: true
  }
}, {
  path: '/information',
  name: 'information',
  component: function component() {
    return import('views/user/information');
  },
  meta: {
    title: '完善信息',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    hideTabbar: true,
    checkUser: true
  }
}, {
  path: '/rankinfo',
  name: 'rankinfo',
  component: function component() {
    return import('views/user/rankInfo');
  },
  meta: {
    title: '积分排行榜',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜学术能量排行榜',
      desc: ''
    },
    hideTabbar: true,
    checkUser: true
  }
}, /* 导入用户的完善信息 -- 相当于注册 */
{
  path: '/importregister',
  name: 'importRegister',
  component: function component() {
    return import('views/user/importRegister');
  },
  meta: {
    title: '检验信息',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    hideTabbar: true,
    checkUser: true
  }
}, /* 用户医院填写问题 */
{
  path: '/hospitallose',
  name: 'hospitalLose',
  component: function component() {
    return import('views/user/hospitalLose');
  },
  meta: {
    title: '检验信息',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    hideTabbar: true,
    checkUser: true
  }
}];