var dataBase = [{
  "AREACODE": "11",
  "AREANAME": "北京市",
  "ISHOT": 0
}, {
  "AREACODE": "1101",
  "AREANAME": "北京市",
  "ISHOT": 1
}, {
  "AREACODE": "12",
  "AREANAME": "天津市",
  "ISHOT": 0
}, {
  "AREACODE": "1201",
  "AREANAME": "天津市",
  "ISHOT": 1
}, {
  "AREACODE": "13",
  "AREANAME": "河北省",
  "ISHOT": 0
}, {
  "AREACODE": "1301",
  "AREANAME": "石家庄市",
  "ISHOT": 1
}, {
  "AREACODE": "130101",
  "AREANAME": "桥东区",
  "ISHOT": 0
}, {
  "AREACODE": "130102",
  "AREANAME": "桥西区",
  "ISHOT": 0
}, {
  "AREACODE": "130103",
  "AREANAME": "裕华区",
  "ISHOT": 0
}, {
  "AREACODE": "130104",
  "AREANAME": "长安区",
  "ISHOT": 0
}, {
  "AREACODE": "130105",
  "AREANAME": "新华区",
  "ISHOT": 0
}, {
  "AREACODE": "1302",
  "AREANAME": "唐山市",
  "ISHOT": 0
}, {
  "AREACODE": "1303",
  "AREANAME": "秦皇岛市",
  "ISHOT": 0
}, {
  "AREACODE": "1304",
  "AREANAME": "邯郸市",
  "ISHOT": 0
}, {
  "AREACODE": "1305",
  "AREANAME": "邢台市",
  "ISHOT": 0
}, {
  "AREACODE": "1306",
  "AREANAME": "保定市",
  "ISHOT": 0
}, {
  "AREACODE": "1307",
  "AREANAME": "张家口市",
  "ISHOT": 0
}, {
  "AREACODE": "1308",
  "AREANAME": "承德市",
  "ISHOT": 0
}, {
  "AREACODE": "1309",
  "AREANAME": "沧州市",
  "ISHOT": 0
}, {
  "AREACODE": "1310",
  "AREANAME": "廊坊市",
  "ISHOT": 0
}, {
  "AREACODE": "1311",
  "AREANAME": "衡水市",
  "ISHOT": 0
}, {
  "AREACODE": "14",
  "AREANAME": "山西省",
  "ISHOT": 0
}, {
  "AREACODE": "1401",
  "AREANAME": "太原市",
  "ISHOT": 0
}, {
  "AREACODE": "1402",
  "AREANAME": "大同市",
  "ISHOT": 0
}, {
  "AREACODE": "1403",
  "AREANAME": "阳泉市",
  "ISHOT": 0
}, {
  "AREACODE": "1404",
  "AREANAME": "长治市",
  "ISHOT": 0
}, {
  "AREACODE": "1405",
  "AREANAME": "晋城市",
  "ISHOT": 0
}, {
  "AREACODE": "1406",
  "AREANAME": "朔州市",
  "ISHOT": 0
}, {
  "AREACODE": "1407",
  "AREANAME": "晋中市",
  "ISHOT": 0
}, {
  "AREACODE": "1408",
  "AREANAME": "运城市",
  "ISHOT": 0
}, {
  "AREACODE": "1409",
  "AREANAME": "忻州市",
  "ISHOT": 0
}, {
  "AREACODE": "1410",
  "AREANAME": "临汾市",
  "ISHOT": 0
}, {
  "AREACODE": "1411",
  "AREANAME": "吕梁市",
  "ISHOT": 0
}, {
  "AREACODE": "15",
  "AREANAME": "内蒙古自治区",
  "ISHOT": 0
}, {
  "AREACODE": "1501",
  "AREANAME": "呼和浩特市",
  "ISHOT": 1
}, {
  "AREACODE": "1502",
  "AREANAME": "包头市",
  "ISHOT": 0
}, {
  "AREACODE": "1503",
  "AREANAME": "乌海市",
  "ISHOT": 0
}, {
  "AREACODE": "1504",
  "AREANAME": "赤峰市",
  "ISHOT": 0
}, {
  "AREACODE": "1505",
  "AREANAME": "通辽市",
  "ISHOT": 0
}, {
  "AREACODE": "1506",
  "AREANAME": "鄂尔多斯市",
  "ISHOT": 0
}, {
  "AREACODE": "1507",
  "AREANAME": "呼伦贝尔市",
  "ISHOT": 0
}, {
  "AREACODE": "1508",
  "AREANAME": "巴彦淖尔市",
  "ISHOT": 0
}, {
  "AREACODE": "1509",
  "AREANAME": "乌兰察布市",
  "ISHOT": 0
}, {
  "AREACODE": "1522",
  "AREANAME": "兴安盟",
  "ISHOT": 0
}, {
  "AREACODE": "1525",
  "AREANAME": "锡林郭勒盟",
  "ISHOT": 0
}, {
  "AREACODE": "1529",
  "AREANAME": "阿拉善盟",
  "ISHOT": 0
}, {
  "AREACODE": "21",
  "AREANAME": "辽宁省",
  "ISHOT": 0
}, {
  "AREACODE": "2101",
  "AREANAME": "沈阳市",
  "ISHOT": 0
}, {
  "AREACODE": "2102",
  "AREANAME": "大连市",
  "ISHOT": 0
}, {
  "AREACODE": "2103",
  "AREANAME": "鞍山市",
  "ISHOT": 0
}, {
  "AREACODE": "2104",
  "AREANAME": "抚顺市",
  "ISHOT": 0
}, {
  "AREACODE": "2105",
  "AREANAME": "本溪市",
  "ISHOT": 0
}, {
  "AREACODE": "2106",
  "AREANAME": "丹东市",
  "ISHOT": 0
}, {
  "AREACODE": "2107",
  "AREANAME": "锦州市",
  "ISHOT": 0
}, {
  "AREACODE": "2108",
  "AREANAME": "营口市",
  "ISHOT": 0
}, {
  "AREACODE": "2109",
  "AREANAME": "阜新市",
  "ISHOT": 0
}, {
  "AREACODE": "2110",
  "AREANAME": "辽阳市",
  "ISHOT": 0
}, {
  "AREACODE": "2111",
  "AREANAME": "盘锦市",
  "ISHOT": 0
}, {
  "AREACODE": "2112",
  "AREANAME": "铁岭市",
  "ISHOT": 0
}, {
  "AREACODE": "2113",
  "AREANAME": "朝阳市",
  "ISHOT": 0
}, {
  "AREACODE": "2114",
  "AREANAME": "葫芦岛市",
  "ISHOT": 0
}, {
  "AREACODE": "22",
  "AREANAME": "吉林省",
  "ISHOT": 0
}, {
  "AREACODE": "2201",
  "AREANAME": "长春市",
  "ISHOT": 0
}, {
  "AREACODE": "2202",
  "AREANAME": "吉林市",
  "ISHOT": 0
}, {
  "AREACODE": "2203",
  "AREANAME": "四平市",
  "ISHOT": 0
}, {
  "AREACODE": "2204",
  "AREANAME": "辽源市",
  "ISHOT": 0
}, {
  "AREACODE": "2205",
  "AREANAME": "通化市",
  "ISHOT": 0
}, {
  "AREACODE": "2206",
  "AREANAME": "白山市",
  "ISHOT": 0
}, {
  "AREACODE": "2207",
  "AREANAME": "松原市",
  "ISHOT": 0
}, {
  "AREACODE": "2208",
  "AREANAME": "白城市",
  "ISHOT": 0
}, {
  "AREACODE": "2224",
  "AREANAME": "延边朝鲜族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "23",
  "AREANAME": "黑龙江省",
  "ISHOT": 0
}, {
  "AREACODE": "2301",
  "AREANAME": "哈尔滨市",
  "ISHOT": 0
}, {
  "AREACODE": "2302",
  "AREANAME": "齐齐哈尔市",
  "ISHOT": 0
}, {
  "AREACODE": "2303",
  "AREANAME": "鸡西市",
  "ISHOT": 0
}, {
  "AREACODE": "2304",
  "AREANAME": "鹤岗市",
  "ISHOT": 0
}, {
  "AREACODE": "2305",
  "AREANAME": "双鸭山市",
  "ISHOT": 0
}, {
  "AREACODE": "2306",
  "AREANAME": "大庆市",
  "ISHOT": 0
}, {
  "AREACODE": "2307",
  "AREANAME": "伊春市",
  "ISHOT": 0
}, {
  "AREACODE": "2308",
  "AREANAME": "佳木斯市",
  "ISHOT": 0
}, {
  "AREACODE": "2309",
  "AREANAME": "七台河市",
  "ISHOT": 0
}, {
  "AREACODE": "2310",
  "AREANAME": "牡丹江市",
  "ISHOT": 0
}, {
  "AREACODE": "2311",
  "AREANAME": "黑河市",
  "ISHOT": 0
}, {
  "AREACODE": "2312",
  "AREANAME": "绥化市",
  "ISHOT": 0
}, {
  "AREACODE": "2327",
  "AREANAME": "大兴安岭地区",
  "ISHOT": 0
}, {
  "AREACODE": "31",
  "AREANAME": "上海市",
  "ISHOT": 0
}, {
  "AREACODE": "3101",
  "AREANAME": "上海",
  "ISHOT": 1
}, {
  "AREACODE": "32",
  "AREANAME": "江苏省",
  "ISHOT": 0
}, {
  "AREACODE": "3201",
  "AREANAME": "南京市",
  "ISHOT": 1
}, {
  "AREACODE": "3202",
  "AREANAME": "无锡市",
  "ISHOT": 0
}, {
  "AREACODE": "3203",
  "AREANAME": "徐州市",
  "ISHOT": 0
}, {
  "AREACODE": "3204",
  "AREANAME": "常州市",
  "ISHOT": 0
}, {
  "AREACODE": "3205",
  "AREANAME": "苏州市",
  "ISHOT": 0
}, {
  "AREACODE": "3206",
  "AREANAME": "南通市",
  "ISHOT": 0
}, {
  "AREACODE": "3207",
  "AREANAME": "连云港市",
  "ISHOT": 0
}, {
  "AREACODE": "3208",
  "AREANAME": "淮安市",
  "ISHOT": 0
}, {
  "AREACODE": "3209",
  "AREANAME": "盐城市",
  "ISHOT": 0
}, {
  "AREACODE": "3210",
  "AREANAME": "扬州市",
  "ISHOT": 0
}, {
  "AREACODE": "3211",
  "AREANAME": "镇江市",
  "ISHOT": 0
}, {
  "AREACODE": "3212",
  "AREANAME": "泰州市",
  "ISHOT": 0
}, {
  "AREACODE": "3213",
  "AREANAME": "宿迁市",
  "ISHOT": 0
}, {
  "AREACODE": "33",
  "AREANAME": "浙江省",
  "ISHOT": 0
}, {
  "AREACODE": "3301",
  "AREANAME": "杭州市",
  "ISHOT": 1
}, {
  "AREACODE": "3302",
  "AREANAME": "宁波市",
  "ISHOT": 1
}, {
  "AREACODE": "3303",
  "AREANAME": "温州市",
  "ISHOT": 0
}, {
  "AREACODE": "3304",
  "AREANAME": "嘉兴市",
  "ISHOT": 0
}, {
  "AREACODE": "3305",
  "AREANAME": "湖州市",
  "ISHOT": 0
}, {
  "AREACODE": "3306",
  "AREANAME": "绍兴市",
  "ISHOT": 0
}, {
  "AREACODE": "3307",
  "AREANAME": "金华市",
  "ISHOT": 0
}, {
  "AREACODE": "3308",
  "AREANAME": "衢州市",
  "ISHOT": 0
}, {
  "AREACODE": "3309",
  "AREANAME": "舟山市",
  "ISHOT": 0
}, {
  "AREACODE": "3310",
  "AREANAME": "台州市",
  "ISHOT": 0
}, {
  "AREACODE": "3311",
  "AREANAME": "丽水市",
  "ISHOT": 0
}, {
  "AREACODE": "34",
  "AREANAME": "安徽省",
  "ISHOT": 0
}, {
  "AREACODE": "3401",
  "AREANAME": "合肥市",
  "ISHOT": 0
}, {
  "AREACODE": "3402",
  "AREANAME": "芜湖市",
  "ISHOT": 0
}, {
  "AREACODE": "3403",
  "AREANAME": "蚌埠市",
  "ISHOT": 0
}, {
  "AREACODE": "3404",
  "AREANAME": "淮南市",
  "ISHOT": 0
}, {
  "AREACODE": "3405",
  "AREANAME": "马鞍山市",
  "ISHOT": 0
}, {
  "AREACODE": "3406",
  "AREANAME": "淮北市",
  "ISHOT": 0
}, {
  "AREACODE": "3407",
  "AREANAME": "铜陵市",
  "ISHOT": 0
}, {
  "AREACODE": "3408",
  "AREANAME": "安庆市",
  "ISHOT": 0
}, {
  "AREACODE": "3410",
  "AREANAME": "黄山市",
  "ISHOT": 0
}, {
  "AREACODE": "3411",
  "AREANAME": "滁州市",
  "ISHOT": 0
}, {
  "AREACODE": "3412",
  "AREANAME": "阜阳市",
  "ISHOT": 0
}, {
  "AREACODE": "3413",
  "AREANAME": "宿州市",
  "ISHOT": 0
}, {
  "AREACODE": "3414",
  "AREANAME": "巢湖市",
  "ISHOT": 0
}, {
  "AREACODE": "3415",
  "AREANAME": "六安市",
  "ISHOT": 0
}, {
  "AREACODE": "3416",
  "AREANAME": "亳州市",
  "ISHOT": 0
}, {
  "AREACODE": "3417",
  "AREANAME": "池州市",
  "ISHOT": 0
}, {
  "AREACODE": "3418",
  "AREANAME": "宣城市",
  "ISHOT": 0
}, {
  "AREACODE": "35",
  "AREANAME": "福建省",
  "ISHOT": 0
}, {
  "AREACODE": "3501",
  "AREANAME": "福州市",
  "ISHOT": 0
}, {
  "AREACODE": "3502",
  "AREANAME": "厦门市",
  "ISHOT": 0
}, {
  "AREACODE": "3503",
  "AREANAME": "莆田市",
  "ISHOT": 0
}, {
  "AREACODE": "3504",
  "AREANAME": "三明市",
  "ISHOT": 0
}, {
  "AREACODE": "3505",
  "AREANAME": "泉州市",
  "ISHOT": 0
}, {
  "AREACODE": "3506",
  "AREANAME": "漳州市",
  "ISHOT": 0
}, {
  "AREACODE": "3507",
  "AREANAME": "南平市",
  "ISHOT": 0
}, {
  "AREACODE": "3508",
  "AREANAME": "龙岩市",
  "ISHOT": 0
}, {
  "AREACODE": "3509",
  "AREANAME": "宁德市",
  "ISHOT": 0
}, {
  "AREACODE": "36",
  "AREANAME": "江西省",
  "ISHOT": 0
}, {
  "AREACODE": "3601",
  "AREANAME": "南昌市",
  "ISHOT": 0
}, {
  "AREACODE": "3602",
  "AREANAME": "景德镇市",
  "ISHOT": 0
}, {
  "AREACODE": "3603",
  "AREANAME": "萍乡市",
  "ISHOT": 0
}, {
  "AREACODE": "3604",
  "AREANAME": "九江市",
  "ISHOT": 0
}, {
  "AREACODE": "3605",
  "AREANAME": "新余市",
  "ISHOT": 0
}, {
  "AREACODE": "3606",
  "AREANAME": "鹰潭市",
  "ISHOT": 0
}, {
  "AREACODE": "3607",
  "AREANAME": "赣州市",
  "ISHOT": 0
}, {
  "AREACODE": "3608",
  "AREANAME": "吉安市",
  "ISHOT": 0
}, {
  "AREACODE": "3609",
  "AREANAME": "宜春市",
  "ISHOT": 0
}, {
  "AREACODE": "3610",
  "AREANAME": "抚州市",
  "ISHOT": 0
}, {
  "AREACODE": "3611",
  "AREANAME": "上饶市",
  "ISHOT": 0
}, {
  "AREACODE": "37",
  "AREANAME": "山东省",
  "ISHOT": 0
}, {
  "AREACODE": "3701",
  "AREANAME": "济南市",
  "ISHOT": 1
}, {
  "AREACODE": "3702",
  "AREANAME": "青岛市",
  "ISHOT": 1
}, {
  "AREACODE": "3703",
  "AREANAME": "淄博市",
  "ISHOT": 0
}, {
  "AREACODE": "3704",
  "AREANAME": "枣庄市",
  "ISHOT": 0
}, {
  "AREACODE": "3705",
  "AREANAME": "东营市",
  "ISHOT": 0
}, {
  "AREACODE": "3706",
  "AREANAME": "烟台市",
  "ISHOT": 0
}, {
  "AREACODE": "3707",
  "AREANAME": "潍坊市",
  "ISHOT": 0
}, {
  "AREACODE": "3708",
  "AREANAME": "济宁市",
  "ISHOT": 0
}, {
  "AREACODE": "3709",
  "AREANAME": "泰安市",
  "ISHOT": 0
}, {
  "AREACODE": "3710",
  "AREANAME": "威海市",
  "ISHOT": 0
}, {
  "AREACODE": "3711",
  "AREANAME": "日照市",
  "ISHOT": 0
}, {
  "AREACODE": "3712",
  "AREANAME": "莱芜市",
  "ISHOT": 0
}, {
  "AREACODE": "3713",
  "AREANAME": "临沂市",
  "ISHOT": 0
}, {
  "AREACODE": "3714",
  "AREANAME": "德州市",
  "ISHOT": 0
}, {
  "AREACODE": "3715",
  "AREANAME": "聊城市",
  "ISHOT": 0
}, {
  "AREACODE": "3716",
  "AREANAME": "滨州市",
  "ISHOT": 0
}, {
  "AREACODE": "3717",
  "AREANAME": "荷泽市",
  "ISHOT": 0
}, {
  "AREACODE": "41",
  "AREANAME": "河南省",
  "ISHOT": 0
}, {
  "AREACODE": "4101",
  "AREANAME": "郑州市",
  "ISHOT": 1
}, {
  "AREACODE": "4102",
  "AREANAME": "开封市",
  "ISHOT": 0
}, {
  "AREACODE": "4103",
  "AREANAME": "洛阳市",
  "ISHOT": 0
}, {
  "AREACODE": "4104",
  "AREANAME": "平顶山市",
  "ISHOT": 0
}, {
  "AREACODE": "4105",
  "AREANAME": "安阳市",
  "ISHOT": 0
}, {
  "AREACODE": "4106",
  "AREANAME": "鹤壁市",
  "ISHOT": 0
}, {
  "AREACODE": "4107",
  "AREANAME": "新乡市",
  "ISHOT": 0
}, {
  "AREACODE": "4108",
  "AREANAME": "焦作市",
  "ISHOT": 0
}, {
  "AREACODE": "4109",
  "AREANAME": "濮阳市",
  "ISHOT": 0
}, {
  "AREACODE": "4110",
  "AREANAME": "许昌市",
  "ISHOT": 0
}, {
  "AREACODE": "4111",
  "AREANAME": "漯河市",
  "ISHOT": 0
}, {
  "AREACODE": "4112",
  "AREANAME": "三门峡市",
  "ISHOT": 0
}, {
  "AREACODE": "4113",
  "AREANAME": "南阳市",
  "ISHOT": 0
}, {
  "AREACODE": "4114",
  "AREANAME": "商丘市",
  "ISHOT": 0
}, {
  "AREACODE": "4115",
  "AREANAME": "信阳市",
  "ISHOT": 0
}, {
  "AREACODE": "4116",
  "AREANAME": "周口市",
  "ISHOT": 0
}, {
  "AREACODE": "4117",
  "AREANAME": "驻马店市",
  "ISHOT": 0
}, {
  "AREACODE": "42",
  "AREANAME": "湖北省",
  "ISHOT": 0
}, {
  "AREACODE": "4201",
  "AREANAME": "武汉市",
  "ISHOT": 1
}, {
  "AREACODE": "4202",
  "AREANAME": "黄石市",
  "ISHOT": 0
}, {
  "AREACODE": "4203",
  "AREANAME": "十堰市",
  "ISHOT": 0
}, {
  "AREACODE": "4205",
  "AREANAME": "宜昌市",
  "ISHOT": 0
}, {
  "AREACODE": "4206",
  "AREANAME": "襄樊市",
  "ISHOT": 0
}, {
  "AREACODE": "4207",
  "AREANAME": "鄂州市",
  "ISHOT": 0
}, {
  "AREACODE": "4208",
  "AREANAME": "荆门市",
  "ISHOT": 0
}, {
  "AREACODE": "4209",
  "AREANAME": "孝感市",
  "ISHOT": 0
}, {
  "AREACODE": "4210",
  "AREANAME": "荆州市",
  "ISHOT": 0
}, {
  "AREACODE": "4211",
  "AREANAME": "黄冈市",
  "ISHOT": 0
}, {
  "AREACODE": "4212",
  "AREANAME": "咸宁市",
  "ISHOT": 0
}, {
  "AREACODE": "4213",
  "AREANAME": "随州市",
  "ISHOT": 0
}, {
  "AREACODE": "4228",
  "AREANAME": "恩施土家族苗族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "43",
  "AREANAME": "湖南省",
  "ISHOT": 0
}, {
  "AREACODE": "4301",
  "AREANAME": "长沙市",
  "ISHOT": 1
}, {
  "AREACODE": "4302",
  "AREANAME": "株洲市",
  "ISHOT": 0
}, {
  "AREACODE": "4303",
  "AREANAME": "湘潭市",
  "ISHOT": 0
}, {
  "AREACODE": "4304",
  "AREANAME": "衡阳市",
  "ISHOT": 0
}, {
  "AREACODE": "4305",
  "AREANAME": "邵阳市",
  "ISHOT": 0
}, {
  "AREACODE": "4306",
  "AREANAME": "岳阳市",
  "ISHOT": 0
}, {
  "AREACODE": "4307",
  "AREANAME": "常德市",
  "ISHOT": 0
}, {
  "AREACODE": "4308",
  "AREANAME": "张家界市",
  "ISHOT": 0
}, {
  "AREACODE": "4309",
  "AREANAME": "益阳市",
  "ISHOT": 0
}, {
  "AREACODE": "4310",
  "AREANAME": "郴州市",
  "ISHOT": 0
}, {
  "AREACODE": "4311",
  "AREANAME": "永州市",
  "ISHOT": 0
}, {
  "AREACODE": "4312",
  "AREANAME": "怀化市",
  "ISHOT": 0
}, {
  "AREACODE": "4313",
  "AREANAME": "娄底市",
  "ISHOT": 0
}, {
  "AREACODE": "4331",
  "AREANAME": "湘西土家族苗族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "44",
  "AREANAME": "广东省",
  "ISHOT": 0
}, {
  "AREACODE": "4401",
  "AREANAME": "广州市",
  "ISHOT": 1
}, {
  "AREACODE": "4402",
  "AREANAME": "韶关市",
  "ISHOT": 0
}, {
  "AREACODE": "4403",
  "AREANAME": "深圳市",
  "ISHOT": 0
}, {
  "AREACODE": "4404",
  "AREANAME": "珠海市",
  "ISHOT": 0
}, {
  "AREACODE": "4405",
  "AREANAME": "汕头市",
  "ISHOT": 0
}, {
  "AREACODE": "4406",
  "AREANAME": "佛山市",
  "ISHOT": 0
}, {
  "AREACODE": "4407",
  "AREANAME": "江门市",
  "ISHOT": 0
}, {
  "AREACODE": "4408",
  "AREANAME": "湛江市",
  "ISHOT": 0
}, {
  "AREACODE": "4409",
  "AREANAME": "茂名市",
  "ISHOT": 0
}, {
  "AREACODE": "4412",
  "AREANAME": "肇庆市",
  "ISHOT": 0
}, {
  "AREACODE": "4413",
  "AREANAME": "惠州市",
  "ISHOT": 0
}, {
  "AREACODE": "4414",
  "AREANAME": "梅州市",
  "ISHOT": 0
}, {
  "AREACODE": "4415",
  "AREANAME": "汕尾市",
  "ISHOT": 0
}, {
  "AREACODE": "4416",
  "AREANAME": "河源市",
  "ISHOT": 0
}, {
  "AREACODE": "4417",
  "AREANAME": "阳江市",
  "ISHOT": 0
}, {
  "AREACODE": "4418",
  "AREANAME": "清远市",
  "ISHOT": 0
}, {
  "AREACODE": "4419",
  "AREANAME": "东莞市",
  "ISHOT": 0
}, {
  "AREACODE": "4420",
  "AREANAME": "中山市",
  "ISHOT": 0
}, {
  "AREACODE": "4451",
  "AREANAME": "潮州市",
  "ISHOT": 0
}, {
  "AREACODE": "4452",
  "AREANAME": "揭阳市",
  "ISHOT": 0
}, {
  "AREACODE": "4453",
  "AREANAME": "云浮市",
  "ISHOT": 0
}, {
  "AREACODE": "45",
  "AREANAME": "广西壮族自治区",
  "ISHOT": 0
}, {
  "AREACODE": "4501",
  "AREANAME": "南宁市",
  "ISHOT": 0
}, {
  "AREACODE": "4502",
  "AREANAME": "柳州市",
  "ISHOT": 0
}, {
  "AREACODE": "4503",
  "AREANAME": "桂林市",
  "ISHOT": 0
}, {
  "AREACODE": "4504",
  "AREANAME": "梧州市",
  "ISHOT": 0
}, {
  "AREACODE": "4505",
  "AREANAME": "北海市",
  "ISHOT": 0
}, {
  "AREACODE": "4506",
  "AREANAME": "防城港市",
  "ISHOT": 0
}, {
  "AREACODE": "4507",
  "AREANAME": "钦州市",
  "ISHOT": 0
}, {
  "AREACODE": "4508",
  "AREANAME": "贵港市",
  "ISHOT": 0
}, {
  "AREACODE": "4509",
  "AREANAME": "玉林市",
  "ISHOT": 0
}, {
  "AREACODE": "4510",
  "AREANAME": "百色市",
  "ISHOT": 0
}, {
  "AREACODE": "4511",
  "AREANAME": "贺州市",
  "ISHOT": 0
}, {
  "AREACODE": "4512",
  "AREANAME": "河池市",
  "ISHOT": 0
}, {
  "AREACODE": "4513",
  "AREANAME": "来宾市",
  "ISHOT": 0
}, {
  "AREACODE": "4514",
  "AREANAME": "崇左市",
  "ISHOT": 0
}, {
  "AREACODE": "46",
  "AREANAME": "海南省",
  "ISHOT": 0
}, {
  "AREACODE": "4601",
  "AREANAME": "海口市",
  "ISHOT": 1
}, {
  "AREACODE": "4602",
  "AREANAME": "三亚市",
  "ISHOT": 1
}, {
  "AREACODE": "50",
  "AREANAME": "重庆市",
  "ISHOT": 0
}, {
  "AREACODE": "5001",
  "AREANAME": "重庆市",
  "ISHOT": 1
}, {
  "AREACODE": "51",
  "AREANAME": "四川省",
  "ISHOT": 0
}, {
  "AREACODE": "5101",
  "AREANAME": "成都市",
  "ISHOT": 1
}, {
  "AREACODE": "5103",
  "AREANAME": "自贡市",
  "ISHOT": 0
}, {
  "AREACODE": "5104",
  "AREANAME": "攀枝花市",
  "ISHOT": 0
}, {
  "AREACODE": "5105",
  "AREANAME": "泸州市",
  "ISHOT": 0
}, {
  "AREACODE": "5106",
  "AREANAME": "德阳市",
  "ISHOT": 0
}, {
  "AREACODE": "5107",
  "AREANAME": "绵阳市",
  "ISHOT": 0
}, {
  "AREACODE": "5108",
  "AREANAME": "广元市",
  "ISHOT": 0
}, {
  "AREACODE": "5109",
  "AREANAME": "遂宁市",
  "ISHOT": 0
}, {
  "AREACODE": "5110",
  "AREANAME": "内江市",
  "ISHOT": 0
}, {
  "AREACODE": "5111",
  "AREANAME": "乐山市",
  "ISHOT": 0
}, {
  "AREACODE": "5113",
  "AREANAME": "南充市",
  "ISHOT": 0
}, {
  "AREACODE": "5114",
  "AREANAME": "眉山市",
  "ISHOT": 0
}, {
  "AREACODE": "5115",
  "AREANAME": "宜宾市",
  "ISHOT": 0
}, {
  "AREACODE": "5116",
  "AREANAME": "广安市",
  "ISHOT": 0
}, {
  "AREACODE": "5117",
  "AREANAME": "达州市",
  "ISHOT": 0
}, {
  "AREACODE": "5118",
  "AREANAME": "雅安市",
  "ISHOT": 0
}, {
  "AREACODE": "5119",
  "AREANAME": "巴中市",
  "ISHOT": 0
}, {
  "AREACODE": "5120",
  "AREANAME": "资阳市",
  "ISHOT": 0
}, {
  "AREACODE": "5132",
  "AREANAME": "阿坝藏族羌族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "5133",
  "AREANAME": "甘孜藏族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "5134",
  "AREANAME": "凉山彝族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "52",
  "AREANAME": "贵州省",
  "ISHOT": 0
}, {
  "AREACODE": "5201",
  "AREANAME": "贵阳市",
  "ISHOT": 0
}, {
  "AREACODE": "5202",
  "AREANAME": "六盘水市",
  "ISHOT": 0
}, {
  "AREACODE": "5203",
  "AREANAME": "遵义市",
  "ISHOT": 0
}, {
  "AREACODE": "5204",
  "AREANAME": "安顺市",
  "ISHOT": 0
}, {
  "AREACODE": "5222",
  "AREANAME": "铜仁地区",
  "ISHOT": 0
}, {
  "AREACODE": "5223",
  "AREANAME": "黔西南布依族苗族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "5224",
  "AREANAME": "毕节地区",
  "ISHOT": 0
}, {
  "AREACODE": "5226",
  "AREANAME": "黔东南苗族侗族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "5227",
  "AREANAME": "黔南布依族苗族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "53",
  "AREANAME": "云南省",
  "ISHOT": 0
}, {
  "AREACODE": "5301",
  "AREANAME": "昆明市",
  "ISHOT": 0
}, {
  "AREACODE": "5303",
  "AREANAME": "曲靖市",
  "ISHOT": 0
}, {
  "AREACODE": "5304",
  "AREANAME": "玉溪市",
  "ISHOT": 0
}, {
  "AREACODE": "5305",
  "AREANAME": "保山市",
  "ISHOT": 0
}, {
  "AREACODE": "5306",
  "AREANAME": "昭通市",
  "ISHOT": 0
}, {
  "AREACODE": "5307",
  "AREANAME": "丽江市",
  "ISHOT": 0
}, {
  "AREACODE": "5308",
  "AREANAME": "思茅市",
  "ISHOT": 0
}, {
  "AREACODE": "5309",
  "AREANAME": "临沧市",
  "ISHOT": 0
}, {
  "AREACODE": "5323",
  "AREANAME": "楚雄彝族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "5325",
  "AREANAME": "红河哈尼族彝族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "5326",
  "AREANAME": "文山壮族苗族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "5328",
  "AREANAME": "西双版纳傣族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "5329",
  "AREANAME": "大理白族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "5331",
  "AREANAME": "德宏傣族景颇族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "5333",
  "AREANAME": "怒江傈僳族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "5334",
  "AREANAME": "迪庆藏族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "54",
  "AREANAME": "西藏自治区",
  "ISHOT": 0
}, {
  "AREACODE": "5401",
  "AREANAME": "拉萨市",
  "ISHOT": 0
}, {
  "AREACODE": "5421",
  "AREANAME": "昌都地区",
  "ISHOT": 0
}, {
  "AREACODE": "5422",
  "AREANAME": "山南地区",
  "ISHOT": 0
}, {
  "AREACODE": "5423",
  "AREANAME": "日喀则地区",
  "ISHOT": 0
}, {
  "AREACODE": "5424",
  "AREANAME": "那曲地区",
  "ISHOT": 0
}, {
  "AREACODE": "5425",
  "AREANAME": "阿里地区",
  "ISHOT": 0
}, {
  "AREACODE": "5426",
  "AREANAME": "林芝地区",
  "ISHOT": 0
}, {
  "AREACODE": "61",
  "AREANAME": "陕西省",
  "ISHOT": 0
}, {
  "AREACODE": "6101",
  "AREANAME": "西安市",
  "ISHOT": 0
}, {
  "AREACODE": "6102",
  "AREANAME": "铜川市",
  "ISHOT": 0
}, {
  "AREACODE": "6103",
  "AREANAME": "宝鸡市",
  "ISHOT": 0
}, {
  "AREACODE": "6104",
  "AREANAME": "咸阳市",
  "ISHOT": 0
}, {
  "AREACODE": "6105",
  "AREANAME": "渭南市",
  "ISHOT": 0
}, {
  "AREACODE": "6106",
  "AREANAME": "延安市",
  "ISHOT": 0
}, {
  "AREACODE": "6107",
  "AREANAME": "汉中市",
  "ISHOT": 0
}, {
  "AREACODE": "6108",
  "AREANAME": "榆林市",
  "ISHOT": 0
}, {
  "AREACODE": "6109",
  "AREANAME": "安康市",
  "ISHOT": 0
}, {
  "AREACODE": "6110",
  "AREANAME": "商洛市",
  "ISHOT": 0
}, {
  "AREACODE": "62",
  "AREANAME": "甘肃省",
  "ISHOT": 0
}, {
  "AREACODE": "6201",
  "AREANAME": "兰州市",
  "ISHOT": 0
}, {
  "AREACODE": "6202",
  "AREANAME": "嘉峪关市",
  "ISHOT": 0
}, {
  "AREACODE": "6203",
  "AREANAME": "金昌市",
  "ISHOT": 0
}, {
  "AREACODE": "6204",
  "AREANAME": "白银市",
  "ISHOT": 0
}, {
  "AREACODE": "6205",
  "AREANAME": "天水市",
  "ISHOT": 0
}, {
  "AREACODE": "6206",
  "AREANAME": "武威市",
  "ISHOT": 0
}, {
  "AREACODE": "6207",
  "AREANAME": "张掖市",
  "ISHOT": 0
}, {
  "AREACODE": "6208",
  "AREANAME": "平凉市",
  "ISHOT": 0
}, {
  "AREACODE": "6209",
  "AREANAME": "酒泉市",
  "ISHOT": 0
}, {
  "AREACODE": "6210",
  "AREANAME": "庆阳市",
  "ISHOT": 0
}, {
  "AREACODE": "6211",
  "AREANAME": "定西市",
  "ISHOT": 0
}, {
  "AREACODE": "6212",
  "AREANAME": "陇南市",
  "ISHOT": 0
}, {
  "AREACODE": "6229",
  "AREANAME": "临夏回族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "6230",
  "AREANAME": "甘南藏族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "63",
  "AREANAME": "青海省",
  "ISHOT": 0
}, {
  "AREACODE": "6301",
  "AREANAME": "西宁市",
  "ISHOT": 0
}, {
  "AREACODE": "6321",
  "AREANAME": "海东地区",
  "ISHOT": 0
}, {
  "AREACODE": "6322",
  "AREANAME": "海北藏族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "6323",
  "AREANAME": "黄南藏族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "6325",
  "AREANAME": "海南藏族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "6326",
  "AREANAME": "果洛藏族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "6327",
  "AREANAME": "玉树藏族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "6328",
  "AREANAME": "海西蒙古族藏族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "64",
  "AREANAME": "宁夏回族自治区",
  "ISHOT": 0
}, {
  "AREACODE": "6401",
  "AREANAME": "银川市",
  "ISHOT": 0
}, {
  "AREACODE": "6402",
  "AREANAME": "石嘴山市",
  "ISHOT": 0
}, {
  "AREACODE": "6403",
  "AREANAME": "吴忠市",
  "ISHOT": 0
}, {
  "AREACODE": "6404",
  "AREANAME": "固原市",
  "ISHOT": 0
}, {
  "AREACODE": "6405",
  "AREANAME": "中卫市",
  "ISHOT": 0
}, {
  "AREACODE": "65",
  "AREANAME": "新疆维吾尔自治区",
  "ISHOT": 0
}, {
  "AREACODE": "6501",
  "AREANAME": "乌鲁木齐市",
  "ISHOT": 0
}, {
  "AREACODE": "6502",
  "AREANAME": "克拉玛依市",
  "ISHOT": 0
}, {
  "AREACODE": "6521",
  "AREANAME": "吐鲁番地区",
  "ISHOT": 0
}, {
  "AREACODE": "6522",
  "AREANAME": "哈密地区",
  "ISHOT": 0
}, {
  "AREACODE": "6523",
  "AREANAME": "昌吉回族自治州",
  "ISHOT": 0
}, {
  "AREACODE": "6527",
  "AREANAME": "博尔塔拉蒙古自治州",
  "ISHOT": 0
}, {
  "AREACODE": "6528",
  "AREANAME": "巴音郭楞蒙古自治州",
  "ISHOT": 0
}, {
  "AREACODE": "6529",
  "AREANAME": "阿克苏地区",
  "ISHOT": 0
}, {
  "AREACODE": "6530",
  "AREANAME": "克孜勒苏柯尔克孜自治州",
  "ISHOT": 0
}, {
  "AREACODE": "6531",
  "AREANAME": "喀什地区",
  "ISHOT": 0
}, {
  "AREACODE": "6532",
  "AREANAME": "和田地区",
  "ISHOT": 0
}, {
  "AREACODE": "6540",
  "AREANAME": "伊犁哈萨克自治州",
  "ISHOT": 0
}, {
  "AREACODE": "6542",
  "AREANAME": "塔城地区",
  "ISHOT": 0
}, {
  "AREACODE": "6543",
  "AREANAME": "阿勒泰地区",
  "ISHOT": 0
}, {
  "AREACODE": "6590",
  "AREANAME": "省直辖行政单位",
  "ISHOT": 0
}, {
  "AREACODE": "71",
  "AREANAME": "台湾省",
  "ISHOT": 0
}, {
  "AREACODE": "7101",
  "AREANAME": "市区",
  "ISHOT": 0
}, {
  "AREACODE": "81",
  "AREANAME": "香港特别行政区",
  "ISHOT": 0
}, {
  "AREACODE": "8101",
  "AREANAME": "市区",
  "ISHOT": 0
}, {
  "AREACODE": "82",
  "AREANAME": "澳门特别行政区",
  "ISHOT": 0
}, {
  "AREACODE": "8201",
  "AREANAME": "市区",
  "ISHOT": 0
}];
module.exports = {
  dataBase: dataBase
};