var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ai-gap-container"
  }, [_c("div", {
    style: {
      width: _vm.w,
      height: _vm.h
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };