"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.storageKey = exports.default = void 0;
const storageKey = exports.storageKey = 'annualDrainage-20241227';
var _default = exports.default = {
  data() {
    return {
      open: false
    };
  },
  mounted() {
    const val = localStorage.getItem(storageKey);
    this.open = val == 1 ? false : true;
    localStorage.setItem(storageKey, '1');
  },
  methods: {
    onClose() {
      this.open = false;
    },
    onClick() {
      this.$emit('onClick');
      this.onClose();
    }
  }
};