"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container"
  }, [_c("stickyHeader"), _c("input", {
    ref: "fileInput",
    staticClass: "fileInput",
    attrs: {
      type: "file"
    },
    on: {
      change: _vm.fileInputChange
    }
  }), _c("input", {
    ref: "fileInputInfor",
    staticClass: "fileInputInfor fileInput",
    attrs: {
      type: "file"
    },
    on: {
      change: _vm.fileInputChangeInfor
    }
  }), _c("div", {
    staticClass: "pageCenter marginAuto"
  }, [_c("div", {
    staticClass: "pageCenterTitle fontWeight flex align-center justify-between"
  }, [_vm._v(" 基本信息 "), _vm.userInfo.veeva == 0 ? _c("img", {
    attrs: {
      src: require("img/personal/auth/none1.png"),
      alt: ""
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/veeva"
        });
      }
    }
  }) : _vm._e(), _vm.userInfo.veeva == 2 ? _c("img", {
    attrs: {
      src: require("img/personal/auth/wait1.png"),
      alt: ""
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/veeva"
        });
      }
    }
  }) : _vm._e(), _vm.userInfo.veeva == 1 ? _c("img", {
    attrs: {
      src: require("img/personal/auth/pass1.png"),
      alt: ""
    },
    on: {
      click: function ($event) {
        return _vm.$PUSH({
          path: "/veeva"
        });
      }
    }
  }) : _vm._e()]), _c("div", {
    staticClass: "pageCenterSetAvatar marginAuto flex align-center justify-center flex-direction",
    on: {
      click: _vm.uploadImg
    }
  }, [_c("div", {
    staticClass: "backRgba"
  }), _vm.formData.avatar ? _c("img", {
    staticClass: "pageCenterSetAvatarImg",
    attrs: {
      src: _vm.formData.avatar,
      alt: ""
    }
  }) : _vm._e(), _vm._m(0)]), _c("div", {
    staticClass: "pageCenterForm"
  }, [_c("div", {
    staticClass: "pageCenterFormList flex align-center"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.name,
      expression: "formData.name"
    }],
    attrs: {
      type: "text",
      placeholder: "昵称",
      disabled: ""
    },
    domProps: {
      value: _vm.formData.name
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "name", $event.target.value);
      }
    }
  })]), _c("div", {
    staticClass: "pageCenterFormList flex align-center"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.phone,
      expression: "formData.phone"
    }],
    attrs: {
      type: "text",
      placeholder: "请输入您的手机号码",
      disabled: ""
    },
    domProps: {
      value: _vm.formData.phone
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "phone", $event.target.value);
      }
    }
  })]), _c("div", {
    staticClass: "pageCenterFormList flex align-center justify-between"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.province,
      expression: "formData.province"
    }],
    attrs: {
      type: "text",
      disabled: ""
    },
    domProps: {
      value: _vm.formData.province
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "province", $event.target.value);
      }
    }
  })]), _c("div", {
    staticClass: "pageCenterFormList flex align-center justify-between"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.hospital,
      expression: "formData.hospital"
    }],
    attrs: {
      type: "text",
      disabled: ""
    },
    domProps: {
      value: _vm.formData.hospital
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "hospital", $event.target.value);
      }
    }
  })]), _vm.formData.hospital == "其他" ? _c("div", {
    staticClass: "pageCenterFormList flex align-center"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.hospitalOther,
      expression: "hospitalOther",
      modifiers: {
        trim: true
      }
    }],
    attrs: {
      type: "text",
      placeholder: "* 请输入其它医院名称",
      disabled: ""
    },
    domProps: {
      value: _vm.hospitalOther
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.hospitalOther = $event.target.value.trim();
      },
      blur: function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]) : _vm._e(), _c("div", {
    staticClass: "pageCenterFormList flex align-center justify-between"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.department,
      expression: "formData.department"
    }],
    attrs: {
      type: "text",
      disabled: ""
    },
    domProps: {
      value: _vm.formData.department
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "department", $event.target.value);
      }
    }
  })]), _c("div", {
    staticClass: "pageCenterFormList flex align-center justify-between"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.title,
      expression: "formData.title"
    }],
    attrs: {
      type: "text",
      disabled: ""
    },
    domProps: {
      value: _vm.formData.title
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "title", $event.target.value);
      }
    }
  })]), _c("div", {
    staticClass: "pageCenterFormList flex align-center justify-between"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.position,
      expression: "formData.position"
    }],
    attrs: {
      type: "text",
      disabled: ""
    },
    domProps: {
      value: _vm.formData.position
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "position", $event.target.value);
      }
    }
  })])]), _c("div", {
    staticClass: "pageCenterTitle editTitle60 fontWeight"
  }, [_vm._v("完善信息")]), _vm.isLoaded ? _c("div", {
    staticClass: "pageCenterForm"
  }, [_c("div", {
    staticClass: "pageCenterAgree marginAuto flex align-center justify-between"
  }, [_c("div", {
    staticClass: "pageCenterAgreeLeft mt78 pageCenterAgreeLeftFirst flex align-center",
    on: {
      click: function ($event) {
        _vm.formData.firstNick = !_vm.formData.firstNick;
      }
    }
  }, [_c("div", {
    staticClass: "pageCenterAgreeCheckAgree"
  }, [_vm.formData.firstNick ? _c("img", {
    attrs: {
      src: require("img/home/checkFilter.png"),
      alt: ""
    }
  }) : _vm._e()]), _vm._v(" 优先显示昵称 ")])]), _c("div", {
    staticClass: "pageCenterAgree marginAuto flex align-center justify-between"
  }, [_c("div", {
    staticClass: "pageCenterAgreeLeft mt36 pageCenterAgreeLeftFirst flex align-center",
    on: {
      click: function ($event) {
        _vm.formData.firstAvatar = !_vm.formData.firstAvatar;
      }
    }
  }, [_c("div", {
    staticClass: "pageCenterAgreeCheckAgree"
  }, [_vm.formData.firstAvatar ? _c("img", {
    attrs: {
      src: require("img/home/checkFilter.png"),
      alt: ""
    }
  }) : _vm._e()]), _vm._v(" 优先显示虚拟头像 ")])]), _c("div", {
    staticClass: "pageCenterSetAvatar marginAuto flex align-center justify-center flex-direction",
    on: {
      click: _vm.fileInputInforup
    }
  }, [_vm.formData.avatar_virtual ? _c("img", {
    staticClass: "pageCenterSetAvatarImg",
    attrs: {
      src: _vm.formData.avatar_virtual,
      alt: ""
    }
  }) : _vm._e(), _c("div", {
    staticClass: "backRgba"
  }), _vm._m(1)]), _c("div", {
    staticClass: "pageCenterFormList flex align-center"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.nickname,
      expression: "formData.nickname"
    }],
    attrs: {
      type: "text",
      placeholder: "昵称",
      maxlength: "10"
    },
    domProps: {
      value: _vm.formData.nickname
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "nickname", $event.target.value);
      }
    }
  })]), _c("div", {
    staticClass: "pageCenterFormList flex align-center justify-between"
  }, [_c("customSelect", {
    ref: "skill_area",
    attrs: {
      isEdit: true,
      editSelectData: _vm.formData,
      field: "skill_area",
      transmitData: _vm.skillAreaSelectList,
      tipWord: "擅长领域",
      mode: "multiple",
      refName: "skill_area"
    },
    on: {
      checkValue: _vm.checkValue
    }
  })], 1), _c("div", {
    staticClass: "pageCenterFormList flex align-center justify-between"
  }, [_c("customSelect", {
    ref: "intrasting_topic",
    attrs: {
      isEdit: true,
      editSelectData: _vm.formData,
      field: "intrasting_topic",
      transmitData: _vm.intrastingTopicSelectList,
      tipWord: "感兴趣的话题",
      mode: "multiple",
      refName: "intrasting_topic"
    },
    on: {
      checkValue: _vm.checkValue
    }
  })], 1), _c("div", {
    staticClass: "pageCenterFormList flex align-center justify-between"
  }, [_c("customSelect", {
    ref: "intrasting_sys",
    attrs: {
      isEdit: true,
      editSelectData: _vm.formData,
      field: "intrasting_sys",
      transmitData: _vm.intrastingSysSelectList,
      tipWord: "感兴趣的系统",
      mode: "multiple",
      refName: "intrasting_sys"
    },
    on: {
      checkValue: _vm.checkValue
    }
  })], 1), _c("div", {
    staticClass: "pageCenterFormList flex align-center justify-between"
  }, [_c("customSelect", {
    ref: "work_upgrades",
    attrs: {
      isEdit: true,
      editSelectData: _vm.formData,
      field: "work_upgrades",
      transmitData: _vm.workUpgradesSelectList,
      tipWord: "当前工作中最需要重点提升的",
      mode: "multiple",
      refName: "work_upgrades"
    },
    on: {
      checkValue: _vm.checkValue
    }
  })], 1)]) : _vm._e(), _c("div", {
    staticClass: "pageCenterAgree marginAuto flex align-center justify-between"
  }), _c("div", {
    staticClass: "registerBtn handleBtn flex align-center justify-center",
    on: {
      click: _vm.registerSubmit
    }
  }, [_vm._v("保存")]), _c("div", {
    staticClass: "cancleBtn handleBtn flex align-center justify-center",
    on: {
      click: function ($event) {
        return _vm.$refs["customPop-back"].open();
      }
    }
  }, [_vm._v("取消")])]), _c("customPop", {
    ref: "customPop-tip",
    attrs: {
      tipWords: _vm.tipWords
    },
    on: {
      cancle: _vm.customPopCancle,
      confirm: _vm.customPopConfirm
    }
  }), _c("customPop", {
    ref: "customPop-cus",
    attrs: {
      showType: "slot"
    }
  }, [_c("div", {
    staticClass: "registerSuccess"
  }, [_c("div", {
    staticClass: "registerSuccessTip"
  }, [_vm._v("注册成功！"), _c("br"), _vm._v(" 可前往完善信息")]), _c("div", {
    staticClass: "registerSuccessHandle flex align-center justify-between"
  }, [_c("div", {
    staticClass: "registerSuccessHandleNow flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: _vm.registerSuccessHandleNow
    }
  }, [_vm._v("立即完善")]), _c("div", {
    staticClass: "registerSuccessHandleDelay flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: _vm.registerSuccessHandleDelay
    }
  }, [_vm._v("稍后")])])])]), _c("customPop", {
    ref: "customPop-back",
    attrs: {
      showType: "slot"
    }
  }, [_c("div", {
    staticClass: "registerSuccess"
  }, [_c("div", {
    staticClass: "registerSuccessTip"
  }, [_vm._v("您修改的资料还没有保存")]), _c("div", {
    staticClass: "registerSuccessHandle flex align-center justify-between"
  }, [_c("div", {
    staticClass: "registerSuccessHandleNow flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: function ($event) {
        return _vm.$refs["customPop-back"].close();
      }
    }
  }, [_vm._v("去保存")]), _c("div", {
    staticClass: "registerSuccessHandleDelay flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: function ($event) {
        return _vm.$REDIRECT({
          path: "/personal"
        });
      }
    }
  }, [_vm._v("返回个人中心")])])])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "setAvatarOut flex align-center justify-center flex-direction"
  }, [_c("img", {
    staticClass: "setAvatar",
    attrs: {
      src: require("img/common/setAvatar.png"),
      alt: ""
    }
  }), _vm._v(" 设置头像 ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "setAvatarOut flex align-center justify-center flex-direction"
  }, [_c("img", {
    staticClass: "setAvatar",
    attrs: {
      src: require("img/common/setAvatar.png"),
      alt: ""
    }
  }), _vm._v(" 设置虚拟头像 ")]);
}];
render._withStripped = true;