import http from 'utils/http';
export default {
  /* 短信发送 */smsSend: function smsSend(params) {
    return http({
      url: "/api/platform/sms/send",
      method: 'post',
      data: params
    });
  },
  /* 邮件发送 */mailSend: function mailSend(params) {
    return http({
      url: "/api/platform/mail/send",
      method: 'post',
      data: params
    });
  },
  /* 普通用户 - 注册 */memberRegister: function memberRegister(params) {
    return http({
      url: "/api/platform/member/member-register",
      method: 'post',
      data: params
    });
  },
  /* 员工 - 注册 */staffRegister: function staffRegister(params) {
    return http({
      url: "/api/platform/member/staff-register",
      method: 'post',
      data: params
    });
  },
  /* 普通用户 / 员工 - 个人信息 */userInfo: function userInfo() {
    return http({
      url: "/api/platform/member/me",
      method: 'get'
    });
  },
  /* 普通用户 - 编辑 */updateUserInfo: function updateUserInfo(params) {
    return http({
      url: "/api/platform/member/member-update",
      method: 'put',
      data: params
    });
  },
  /* 员工 - 编辑 */updateStaffInfo: function updateStaffInfo(params) {
    return http({
      url: "/api/platform/member/staff-update",
      method: 'put',
      data: params
    });
  }
};