import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import Vue from 'vue';
import router from "@/router/index.js";
Vue.prototype.$PUSH = function (params) {
  /* 不用 setTimeout 修改为异步的话，无法触发 v-monitor */
  setTimeout(function () {
    var pathinfo = JSON.parse(sessionStorage.getItem('path'));
    if (!pathinfo) {
      pathinfo = [];
    }
    pathinfo.push({
      path: params.path,
      query: params.query,
      replace: params.replace
    });
    console.log(JSON.stringify(pathinfo));

    //sessionStorage.setItem("pages", Number(sessionStorage.getItem("pages"))+1)
    sessionStorage.setItem("path", JSON.stringify(pathinfo));
    router.push({
      path: params.path,
      query: params.query,
      replace: params.replace
    });
  }, 0);
};
Vue.prototype.$REDIRECT = function (params) {
  router.replace({
    path: params.path,
    query: params.query
  });
};
Vue.prototype.$GO = function (param) {
  //sessionStorage.setItem("pages", sessionStorage.getItem("pages")-1)
  var pathinfo = sessionStorage.getItem('path');
  if (!pathinfo) {
    router.replace({
      path: "/"
    });
    return;
  }
  // if(sessionStorage.getItem("pages")-1 <= 1){

  // }

  // sessionStorage.setItem("pages", sessionStorage.getItem("pages")-1)

  router.go(param);
};