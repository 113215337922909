"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _http = _interopRequireDefault(require("utils/http"));
var _default = exports.default = {
  /* 首页-每日推荐 */
  loginChech(params) {
    return (0, _http.default)({
      url: `/api/platform/member/login-log/check`,
      method: 'get'
    });
  },
  /* 用户签到-创建 */
  setLogin(params) {
    return (0, _http.default)({
      url: `/api/platform/member/login-log`,
      method: 'post'
    });
  },
  /* 我的收藏 */
  getMyFaver(params) {
    return (0, _http.default)({
      url: `/api/platform/member/my-faver`,
      method: 'get',
      params
    });
  },
  /* 我的-学习记录 */
  getLearnLog(params) {
    return (0, _http.default)({
      url: `/api/platform/learn-log`,
      method: 'get'
    });
  },
  /* 排行榜 */
  getRankingList(type) {
    return (0, _http.default)({
      url: `/api/platform/member/ranking-list/${type}`,
      method: 'get'
    });
  },
  /* 勋章列表 */
  getBadgeList(type) {
    return (0, _http.default)({
      url: `/api/platform/medal/get`,
      method: 'get'
    });
  },
  /* 证书列表 */
  getCerList(type) {
    return (0, _http.default)({
      url: `/api/platform/certificate/get`,
      method: 'get'
    });
  },
  /* 未领取弹窗 */
  getMemberPopover(type) {
    return (0, _http.default)({
      url: `/api/platform/member/popover`,
      method: 'get'
    });
  },
  /* 一个勋章的信息 */
  getMedalDetail(id) {
    return (0, _http.default)({
      url: `/api/platform/medal/get/${id}`,
      method: 'get'
    });
  },
  /* 一个证书的信息 */
  getCertificateDetail(id) {
    return (0, _http.default)({
      url: `/api/platform/certificate/get/${id}`,
      method: 'get'
    });
  },
  /* 领取奖章 */
  putDrawMedal(params) {
    return (0, _http.default)({
      url: `/api/platform/medal/draw-medal`,
      method: 'put',
      data: params
    });
  },
  /* 领取证书 */
  putDrawCertificate(params) {
    return (0, _http.default)({
      url: `/api/platform/certificate/draw-certificate`,
      method: 'put',
      data: params
    });
  },
  /* 通知cms生成了海报 */
  setMedalcertificatelog(params) {
    return (0, _http.default)({
      url: `/api/platform/medalcertificatelog`,
      method: 'post',
      data: params
    });
  },
  /* 问卷列表 */
  getQuestionList(params) {
    return (0, _http.default)({
      url: `/api/platform/question/list`,
      method: 'get',
      params
    });
  },
  /* 问卷详情 */
  getQuestionDetail(params, id) {
    return (0, _http.default)({
      url: `/api/platform/question/${id}`,
      method: 'get',
      params
    });
  },
  /* 站内信 */
  getMessagecneter(params) {
    return (0, _http.default)({
      url: `/api/platform/messagecneter`,
      method: 'get',
      params
    });
  },
  /* 获取消息未读数量接口 */
  getMessagecneterCount(params) {
    return (0, _http.default)({
      url: `/api/platform/messagecneter/msg-unread-num`,
      method: 'get',
      params
    });
  },
  /* 用户vevea审核创建接口 */
  createVeeva(params) {
    return (0, _http.default)({
      url: `/api/platform/examine/vevea-examine-create`,
      method: 'post',
      data: params
    });
  },
  /* 获取vevea审核详情接口 */
  getVeevaInfo(params) {
    return (0, _http.default)({
      url: `/api/platform/examine/get-examine-info`,
      method: 'get',
      data: params
    });
  },
  /* 用户vevea审核更新接口 */
  PutVeevaInfo(params) {
    return (0, _http.default)({
      url: `/api/platform/examine/vevea-examine-update`,
      method: 'post',
      data: params,
      isJson: true
    });
  },
  /* 用户vevea 查询弹窗接口 */
  getVeevaPop(params) {
    return (0, _http.default)({
      url: `/api/platform/istip/get-istip`,
      method: 'get',
      params
    });
  },
  /* 用户vevea 修改弹窗状态接口 */
  updateVeevaPop(params) {
    return (0, _http.default)({
      url: `/api/platform/istip/up-istip`,
      method: 'get',
      params
    });
  },
  send_truman(params) {
    return (0, _http.default)({
      url: `/api/platform/member/sendtruman`,
      method: 'post',
      data: params
    });
  },
  get_truman(params) {
    return (0, _http.default)({
      url: `/api/platform/member/gettruman`,
      method: 'post',
      data: params
    });
  }
};