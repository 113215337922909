export default {
  components: {},
  props: {
    data: null,
    close: Function,
    callback: Function,
    confirm: Function
  },
  data: function data() {
    return {};
  },
  created: function created() {
    this.init();
  },
  mounted: function mounted() {},
  computed: {},
  filters: {},
  methods: {}
};