import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.push.js";
import cookies from 'vue-cookies';
import aui from 'aui';
import api from 'api';
import { baseUrl, deploy, debug } from '@/config';
import mixin from '@/mixins';
import vc from 'vconsole';
var install = function install(Vue) {
  /* cookies */
  Vue.use(cookies);

  /* 自定义组件（后续移至 npm 包管理） */
  Vue.use(aui);

  /* 全局 API */
  Vue.use(api);

  /* 域名地址 */
  Vue.prototype.$domain = baseUrl.base;
  Vue.prototype.$publicPath = "".concat(baseUrl.base, "/").concat(deploy.path);
  if (!deploy.path) Vue.prototype.$publicPath = "".concat(baseUrl.base);

  /* 全局混入 */
  window.$initPage = mixin;

  /* 自定义计算单位 */
  Vue.prototype.$rem = function (val) {
    return "".concat(val / 8.28, "vw");
  };

  /* 生成session_num */
  Vue.prototype.$GetSessionNum = function () {
    var str = "abcdefghijklmnopqrstuvwxyz0123456789";
    var tmp = [];
    var random;
    for (var i = 0; i < 8; i++) {
      random = Math.floor(Math.random() * str.length);
      if (tmp.indexOf(str[random]) === -1) {
        tmp.push(str[random]);
      } else {
        i--;
      }
    }
    return String(Date.now()) + tmp.join("");
  };

  /* 调试插件 */
  if (debug) new vc();
};
export default {
  install: install
};