import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
export default {
  components: {},
  props: {
    /* 点击蒙层是否关闭 */
    mask: {
      type: Boolean,
      default: false
    },
    /* 
      展示形式
        words： 文字提示
        slot  插槽
    */
    showType: {
      type: String,
      default: 'words'
    },
    /* 提示文字 */
    tipWords: {
      type: String,
      default: '请传入提示文字'
    }
  },
  data: function data() {
    return {
      grayFlag: false,
      showPop: false,
      outCallBack: null,
      isNeedBack: false,
      days: 0,
      query: 1231
    };
  },
  filter: {},
  created: function created() {},
  mounted: function mounted() {
    // this.query = this.$router
  },
  computed: {
    isRegister: function isRegister() {
      return localStorage.getItem('isRegister');
    }
  },
  methods: {
    handleShareRouting: function handleShareRouting() {
      var queryParams = new URLSearchParams(window.location.search);
      var shareData = queryParams.get('sharedata');
      if (shareData === 'true') {
        // 如果shareData参数为true，重定向到首页
        return true;
      }
      return '';
    },
    isNewUser: function isNewUser() {
      return this.days > 0;
    },
    toRegister: function toRegister() {
      var _this = this;
      this.outCallBack({
        next: '/veeva'
      });
      setTimeout(function () {
        _this.showPop = false;
      }, 700);
    },
    cancleRegister: function cancleRegister() {
      this.outCallBack({
        next: 'from'
      });
      this.close();
    },
    open: function open(options) {
      var isNeedBack = options.isNeedBack,
        callBack = options.callBack,
        days = options.days;
      this.isNeedBack = isNeedBack;
      this.outCallBack = callBack;
      /* 从路由拦截过来以后，点击closeicon的时候调取取消的方法 */
      this.days = days;
      this.showPop = true;
      if (this.days <= 0) {
        this.setGrayBg();
      }
    },
    setGrayBg: function setGrayBg() {
      this.grayFlag = true;
    },
    close: function close() {
      this.popFullClose();
    },
    maskHandle: function maskHandle() {
      if (this.mask) {
        this.popFullClose();
      }
    },
    popFullClose: function popFullClose() {
      var _this2 = this;
      if (this.isNewUser()) {
        this.showPop = false;
        this.$emit('cancle');
        return;
      }
      if (this.isNeedBack == 'cur') {
        this.showPop = false;
        return;
      }
      if (!this.isNeedBack) {
        if (this.handleShareRouting()) {
          this.showPop = false;
          this.$REDIRECT({
            path: '/'
          });
        } else {
          this.$GO(-1);
          setTimeout(function () {
            _this2.showPop = false;
          }, 700);
        }
      } else {
        this.showPop = false;
      }
    }
  }
};