var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c("div", {
    staticClass: "global-loading-container"
  }, [_c("div", {
    staticClass: "ai-loading-wrap",
    class: {
      rect: _vm.styles === "rect",
      opacity: _vm.styles === "opacity"
    }
  }, [_c("van-loading", {
    attrs: {
      type: _vm.type,
      color: _vm.color,
      "text-color": _vm.color,
      vertical: true
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")])], 1)]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };