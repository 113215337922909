import http from 'utils/http';
export default {
  /* 学术助手/联系我们 */getAssistantsInfo: function getAssistantsInfo(params) {
    return http({
      url: "/api/platform/assistants/info",
      method: 'get'
    });
  },
  getShowPopDaysInfo: function getShowPopDaysInfo(params) {
    return http({
      url: "/api/platform/veeva/info",
      method: 'get',
      params: params
    });
  },
  /* 课程分类
    type
    1 ： 课程/病例
    0 全部
  */
  getCourseCate: function getCourseCate(type) {
    return http({
      url: "/api/platform/category/tree/".concat(type),
      method: 'get'
    });
  },
  /* 表单-评论-点赞/取消点赞 */setFormZanLog: function setFormZanLog(params) {
    return http({
      url: "/api/platform/form/form-question-answer-zan-log",
      method: 'post',
      data: params
    });
  },
  /* 表单-评论-log */getOtherComments: function getOtherComments(params) {
    return http({
      url: "/api/platform/form/form-question-answer-comment",
      method: 'get',
      params: params
    });
  },
  /* 收集积分-行为 */setScoreLog: function setScoreLog(params) {
    return http({
      url: "/api/platform/score-log",
      method: 'post',
      data: params
    });
  },
  /* 单独传本次学习时长 */setLearnTimeLog: function setLearnTimeLog(params) {
    return http({
      url: "/api/platform/learnlog/create",
      method: 'post',
      data: params
    });
  },
  /* 监测是否被加积分 */isAddScore: function isAddScore(params) {
    return http({
      url: "/api/platform/score-log/scorelog",
      method: 'get',
      params: params
    });
  },
  /* 通知后台已弹出加积分的行为 传单个【已暂停使用】 */
  /* putAddScore(id,params) {
    return http({
      url: `/api/platform/score-log/scorelog/${id}`,
      method: 'put',
      data: params
    })
  }, */
  /* 通知后台已弹出加积分的行为 传多id个【使用中】 */
  putAddScore: function putAddScore(params) {
    return http({
      url: "/api/platform/score-log/up-tip-status",
      method: 'post',
      data: params
    });
  },
  /* 判断是否关注服务号 */isWatchService: function isWatchService(openid) {
    return http({
      url: "/api/platform/token?openid=".concat(openid),
      method: 'get'
    });
  },
  /* 是否有未弹窗的证书、勋章（个人中心小红点） */claimStatus: function claimStatus() {
    return http({
      url: "/api/platform/content/claim/status",
      method: 'get'
    });
  },
  /* 通过省份查询医院 */getHospital: function getHospital(params) {
    return http({
      url: "/api/platform/hospital/get-hospital",
      method: 'get',
      params: params
    });
  },
  /* 首页-发放会议学术能量 */getmeetingview: function getmeetingview() {
    return http({
      url: "/api/platform/score-log/meetingview",
      method: 'post',
      data: {
        action_type: "MEETING_VIEW"
      }
    });
  },
  /* 提升-airep状态监测 */memberAiRep: function memberAiRep() {
    return http({
      url: "/api/platform/member/ai-rep/check",
      method: 'get'
    });
  },
  imageupload: function imageupload(params) {
    return http({
      url: "/api/platform/imageupload",
      method: 'post',
      upload: 1,
      data: params
    });
  },
  imageuploadBase64: function imageuploadBase64(params, callBack) {
    return http({
      url: "/api/platform/imageupload-base64",
      method: 'post',
      upload: 1,
      data: params,
      callBack: callBack
    });
  }
};