import _toConsumableArray from "D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
/**
 * 基础路由
 * @type { *[] }
 */
import course from "./modules/course";
import activity from "./modules/activity";
import personal from "./modules/personal";
import sign from "./modules/sign";
import preview from "./modules/preview";
var hideApprovalRouterArr = _toConsumableArray(preview);
hideApprovalRouterArr.forEach(function (im) {
  im.meta.hideApproval = true;
});
export var constantRouterMap = [{
  path: '/',
  component: function component() {
    return import('views/layouts/index');
  },
  redirect: '/',
  meta: {
    title: '',
    keepAlive: false
  },
  children: [
  /*
  * share
  * title: '' // 默认取页面 title
  * desc: '' // 默认拼接 (点击查看 + title)
  * link: '' // 默认自动拼接
  * imgUrl: '' // 默认取 public/logo.png
  * */
  /*
  * setting
  * keepAlive: false // 页面缓存
  * wechatAuth: true // 微信授权
  * customTitle: false // 动态标题，默认 false 为 true 时取 query 中的 title，需要在搭配链接中参数
  * */
  {
    path: '/',
    name: '',
    component: function component() {
      return import('views/entry/index');
    },
    meta: {
      title: '首页',
      keepAlive: false,
      wechatAuth: true,
      share: {
        title: '拜耳影像学苑｜首页',
        desc: ''
      },
      tabIndex: '0'
    }
  }, {
    path: '/template',
    name: '',
    component: function component() {
      return import('views/template');
    },
    meta: {
      title: '首页',
      keepAlive: false,
      wechatAuth: false,
      share: {
        title: '拜耳影像学苑｜清除缓存',
        desc: '清除缓存'
      },
      tabIndex: '0'
    }
  }, {
    path: '/allcontent',
    name: 'AllContent',
    component: function component() {
      return import('views/allContent/index');
    },
    meta: {
      title: '全部内容',
      keepAlive: false,
      wechatAuth: true,
      share: {
        title: '拜耳影像学苑｜全部内容',
        desc: ''
      },
      reachBottom: true
    }
  }, {
    path: '/activity',
    name: 'Activity',
    component: function component() {
      return import('views/entry/activity');
    },
    meta: {
      title: '活动列表',
      keepAlive: false,
      wechatAuth: true,
      share: {
        title: '拜耳影像学苑｜热门活动',
        desc: ''
      },
      reachBottom: true
    }
  }, {
    path: '/casearea',
    name: 'caseArea',
    component: function component() {
      return import('views/entry/caseArea');
    },
    meta: {
      title: '病例专区',
      keepAlive: false,
      wechatAuth: true,
      share: {
        title: '拜耳影像学苑｜病例专区',
        desc: ''
      },
      reachBottom: true
    }
  }, {
    path: '/personal',
    name: 'personal',
    component: function component() {
      return import('views/entry/personal');
    },
    meta: {
      title: '个人中心',
      keepAlive: false,
      wechatAuth: true,
      share: {
        title: '拜耳影像学苑｜个人中心',
        desc: ''
      },
      tabIndex: '3'
    }
  }, {
    path: '/meeting',
    name: 'meeting',
    component: function component() {
      return import('views/meeting/index');
    },
    meta: {
      title: '会议',
      keepAlive: false,
      wechatAuth: true,
      share: {
        title: '拜耳影像学苑｜拜耳会议',
        desc: ''
      },
      tabIndex: '1',
      reachBottom: true
    }
  }, {
    path: '/meetingdetail',
    name: 'meetingDetail',
    component: function component() {
      return import('views/meeting/detail');
    },
    meta: {
      title: '会议详情',
      keepAlive: false,
      wechatAuth: true,
      share: {
        title: '拜耳影像学苑｜会议详情',
        desc: '会议详情'
      },
      tabIndex: '1',
      hideApproval: true
    }
  }, {
    path: '/meetingdetailplayback',
    name: 'detailplayback',
    component: function component() {
      return import('views/meeting/detailplayback');
    },
    meta: {
      title: '会议详情',
      keepAlive: false,
      wechatAuth: true,
      share: {
        title: '拜耳影像学苑｜会议详情',
        desc: '会议详情'
      },
      tabIndex: '1',
      hideApproval: true
    }
  }, {
    path: '/allsearch',
    name: 'allSearch',
    component: function component() {
      return import('views/entry/allSearch');
    },
    meta: {
      title: '搜索',
      keepAlive: false,
      wechatAuth: true,
      share: {
        title: '拜耳影像学苑｜拜耳搜索',
        desc: ''
      },
      tabIndex: '0'
    }
  }].concat(_toConsumableArray(course), _toConsumableArray(activity), _toConsumableArray(personal), _toConsumableArray(sign), _toConsumableArray(preview))
}];