export default {
  name: 'App',
  created: function created() {
    var _this$$store$getters,
      _this$$store$getters2,
      _this = this;
    this.$store.dispatch("course/getCourseClass");
    ((_this$$store$getters = this.$store.getters) === null || _this$$store$getters === void 0 || (_this$$store$getters = _this$$store$getters.wechatInfo) === null || _this$$store$getters === void 0 ? void 0 : _this$$store$getters.token) && this.$store.dispatch("getUserInfo");
    ((_this$$store$getters2 = this.$store.getters) === null || _this$$store$getters2 === void 0 || (_this$$store$getters2 = _this$$store$getters2.wechatInfo) === null || _this$$store$getters2 === void 0 ? void 0 : _this$$store$getters2.token) && this.$store.dispatch("getAssistantsInfo");
    var setScreenTip = function setScreenTip() {
      var width = document.body.offsetWidth;
      var height = document.body.offsetHeight;
      if (height < width) {
        _this.$store.commit("SET_SCREENTIP", true);
      } else {
        _this.$store.commit("SET_SCREENTIP", false);
      }
    };
    setTimeout(function () {
      setScreenTip();
    }, 1000);
    window.addEventListener("resize", function (evt) {
      setScreenTip();
    }, false);
  }
};