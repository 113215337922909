"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("cusLoading"), _c("customPop", {
    ref: "customPop-ewm",
    attrs: {
      showType: "slot"
    },
    on: {
      cancle: function ($event) {
        return _vm.$store.commit("SET_CALLUSSHOW", false);
      }
    }
  }, [_c("div", {
    staticClass: "enterActivity-All"
  }, [_vm.userInfo.veeva != 1 ? _c("div", {
    staticClass: "enterActivityTip"
  }, [_vm._v("您还在认证中，暂无法查看")]) : _vm._e(), !_vm.callUs.qrcode && _vm.userInfo.veeva == 1 ? _c("div", {
    staticClass: "enterActivityTip"
  }, [_vm._v("暂无学术助手联系方式")]) : _vm._e(), _vm.callUs.qrcode && _vm.userInfo.veeva == 1 ? _c("div", {
    staticClass: "enterActivityTip"
  }, [_vm._v("请扫码添加学术助手 "), _c("br"), _vm._v("咨询相关问题")]) : _vm._e(), _vm.callUs.qrcode && _vm.userInfo.veeva == 1 ? _c("div", {
    staticClass: "ewmIcon"
  }, [_c("img", {
    attrs: {
      src: _vm.callUs.qrcode,
      alt: ""
    }
  })]) : _vm._e()])]), _c("customPop", {
    ref: "wecom-ewm",
    attrs: {
      showType: "slot"
    },
    on: {
      cancle: function ($event) {
        return _vm.$store.commit("SET_WECOMSHOW", false);
      }
    }
  }, [_c("div", {
    staticClass: "enterActivity-All"
  }, [_vm.userInfo.veeva != 1 ? _c("div", {
    staticClass: "enterActivityTip"
  }, [_c("div", {
    staticClass: "registerSuccess"
  }, [_c("div", {
    staticClass: "registerSuccessTip"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v(" 您还在认证中，暂无法查看。")]), _c("p", {
    staticClass: "content"
  }, [_vm._v("完成医疗卫生认证，即可与学术助手交流")])]), _c("div", {
    staticClass: "registerSuccessHandle flex align-center justify-between"
  }, [_c("div", {
    staticClass: "registerSuccessHandleNow flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.wecomshow_href();
      }
    }
  }, [_vm._v(" 去认证 ")])])])]) : _vm._e(), !_vm.callUs_WECOMSHOW.qrcode && _vm.userInfo.veeva == 1 ? _c("div", {
    staticClass: "enterActivityTip"
  }, [_vm._v("暂无学术助手联系方式")]) : _vm._e(), _vm.callUs_WECOMSHOW.qrcode && _vm.userInfo.veeva == 1 ? _c("div", {
    staticClass: "enterActivityTip"
  }, [_vm._v("请扫码添加学术助手 "), _c("br"), _vm._v("咨询相关问题")]) : _vm._e(), _vm.callUs_WECOMSHOW.qrcode && _vm.userInfo.veeva == 1 ? _c("div", {
    staticClass: "ewmIcon"
  }, [_c("img", {
    attrs: {
      src: _vm.callUs_WECOMSHOW.qrcode,
      alt: ""
    }
  })]) : _vm._e()])]), _c("customPop", {
    ref: "customPop-integral",
    attrs: {
      showType: "slot",
      transparent: true,
      maskCallBack: evt => _vm.$store.commit("SET_INTEGRAL", {
        show: false,
        integral: null
      })
    }
  }, [_c("div", {
    staticClass: "powerBoxTip"
  }, [_c("img", {
    staticClass: "powerAddIcon",
    attrs: {
      src: require("img/personal/powerAddIcon.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "powerBoxTipInner"
  }, [_c("div", {
    staticClass: "powerBoxTipInnerWords flex align-end justify-center"
  }, [_vm._v(" + "), _c("span", {
    staticClass: "powerBoxTipInnerWordsCount"
  }, [_vm._v(_vm._s(_vm.integralInfo.integral))]), _vm._v(" "), _c("span", {
    staticClass: "powerBoxTipInnerWordsInfo"
  }, [_vm._v("学术能量")])]), _c("div", {
    staticClass: "powerBoxTipInnerSeat"
  })])])]), _c("div", {
    staticClass: "layout-header"
  }), _c("div", {
    staticClass: "layout-content"
  }, [_vm.$route.meta.keepAlive ? _c("keep-alive", {}, [_c("router-view")], 1) : _c("router-view"), !_vm.hideApproval ? _c("ApprovalNo") : _vm._e(), !_vm.hideApproval && _vm.showSelfSeat ? _c("div", {
    staticClass: "hideApprovalSeat"
  }) : _vm._e()], 1), _c("div", {
    staticClass: "layout-footer"
  }, [_vm.showTabbar ? _c("tabbar", {
    attrs: {
      hideSeat: _vm.hideSeat(),
      smallTabbarBoxSeat: _vm.determineSmall()
    }
  }) : _vm._e()], 1), _vm.showSaveBtn ? _c("div", {
    staticClass: "fixedSave flex align-center justify-center"
  }, [_vm._v("长按保存图片")]) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;