// import ApprovalNo from "@/components/common/ApprovalNo.vue"
export default {
  components: {},
  name: "tabbar",
  props: {
    filterMap: {
      type: Object,
      default: function _default() {}
    },
    smallTabbarBoxSeat: {
      type: Boolean,
      default: false
    },
    hideSeat: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      hideApproval: false,
      tabbarUrl: {
        0: "/",
        1: "/meeting",
        2: "/course",
        3: "/personal"
      },
      urlQuery: {},
      show: false
    };
  },
  watch: {
    $route: function $route() {
      console.log(this.$route, '==> $route for tabbar');
      this.urlQuery = this.$route.query;
      if (this.urlQuery.typeMap == '2') {
        document.title = "病例详情";
      }
      this.setShow();
    },
    tabIndex: function tabIndex() {
      console.log(this.tabIndex, '==> tabIndex in tabbar');
    }
  },
  filter: {},
  created: function created() {
    this.urlQuery = this.$route.query;
    if (this.urlQuery.typeMap == '2') {
      document.title = "病例详情";
    }

    /* if(this.$route.meta.hideApproval){
        this.hideApproval = true
    }else{
        this.hideApproval = false
    } */
  },
  mounted: function mounted() {
    this.setShow();
  },
  computed: {
    tabIndex: function tabIndex() {
      return this.$store.getters.tabIndex;
    },
    showClaim: function showClaim() {
      return this.$store.getters.showClaim;
    }
  },
  methods: {
    setShow: function setShow() {
      if (this.$route.path == "/personal" || this.$route.path == "/notify") {
        this.show = false;
      } else {
        this.show = true;
      }
    },
    switchTab: function switchTab(key) {
      this.$store.dispatch("setTabIndex", key);
      this.$PUSH({
        path: this.tabbarUrl[key]
      });
    }
  }
};