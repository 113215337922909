import http from 'utils/http';
export default {
  /* 首页-每日推荐 */loginChech: function loginChech(params) {
    return http({
      url: "/api/platform/member/login-log/check",
      method: 'get'
    });
  },
  /* 用户签到-创建 */setLogin: function setLogin(params) {
    return http({
      url: "/api/platform/member/login-log",
      method: 'post'
    });
  },
  /* 我的收藏 */getMyFaver: function getMyFaver(params) {
    return http({
      url: "/api/platform/member/my-faver",
      method: 'get',
      params: params
    });
  },
  /* 我的-学习记录 */getLearnLog: function getLearnLog(params) {
    return http({
      url: "/api/platform/learn-log",
      method: 'get'
    });
  },
  /* 排行榜 */getRankingList: function getRankingList(type) {
    return http({
      url: "/api/platform/member/ranking-list/".concat(type),
      method: 'get'
    });
  },
  /* 勋章列表 */getBadgeList: function getBadgeList(type) {
    return http({
      url: "/api/platform/medal/get",
      method: 'get'
    });
  },
  /* 证书列表 */getCerList: function getCerList(type) {
    return http({
      url: "/api/platform/certificate/get",
      method: 'get'
    });
  },
  /* 未领取弹窗 */getMemberPopover: function getMemberPopover(type) {
    return http({
      url: "/api/platform/member/popover",
      method: 'get'
    });
  },
  /* 一个勋章的信息 */getMedalDetail: function getMedalDetail(id) {
    return http({
      url: "/api/platform/medal/get/".concat(id),
      method: 'get'
    });
  },
  /* 一个证书的信息 */getCertificateDetail: function getCertificateDetail(id) {
    return http({
      url: "/api/platform/certificate/get/".concat(id),
      method: 'get'
    });
  },
  /* 领取奖章 */putDrawMedal: function putDrawMedal(params) {
    return http({
      url: "/api/platform/medal/draw-medal",
      method: 'put',
      data: params
    });
  },
  /* 领取证书 */putDrawCertificate: function putDrawCertificate(params) {
    return http({
      url: "/api/platform/certificate/draw-certificate",
      method: 'put',
      data: params
    });
  },
  /* 通知cms生成了海报 */setMedalcertificatelog: function setMedalcertificatelog(params) {
    return http({
      url: "/api/platform/medalcertificatelog",
      method: 'post',
      data: params
    });
  },
  /* 问卷列表 */getQuestionList: function getQuestionList(params) {
    return http({
      url: "/api/platform/question/list",
      method: 'get',
      params: params
    });
  },
  /* 问卷详情 */getQuestionDetail: function getQuestionDetail(params, id) {
    return http({
      url: "/api/platform/question/".concat(id),
      method: 'get',
      params: params
    });
  },
  /* 站内信 */getMessagecneter: function getMessagecneter(params) {
    return http({
      url: "/api/platform/messagecneter",
      method: 'get',
      params: params
    });
  },
  /* 获取消息未读数量接口 */getMessagecneterCount: function getMessagecneterCount(params) {
    return http({
      url: "/api/platform/messagecneter/msg-unread-num",
      method: 'get',
      params: params
    });
  },
  /* 用户vevea审核创建接口 */createVeeva: function createVeeva(params) {
    return http({
      url: "/api/platform/examine/vevea-examine-create",
      method: 'post',
      data: params
    });
  },
  /* 获取vevea审核详情接口 */getVeevaInfo: function getVeevaInfo(params) {
    return http({
      url: "/api/platform/examine/get-examine-info",
      method: 'get',
      data: params
    });
  },
  /* 用户vevea审核更新接口 */PutVeevaInfo: function PutVeevaInfo(params) {
    return http({
      url: "/api/platform/examine/vevea-examine-update",
      method: 'post',
      data: params,
      isJson: true
    });
  },
  /* 用户vevea 查询弹窗接口 */getVeevaPop: function getVeevaPop(params) {
    return http({
      url: "/api/platform/istip/get-istip",
      method: 'get',
      params: params
    });
  },
  /* 用户vevea 修改弹窗状态接口 */updateVeevaPop: function updateVeevaPop(params) {
    return http({
      url: "/api/platform/istip/up-istip",
      method: 'get',
      params: params
    });
  }
};