export default {
  components: {},
  props: {
    background: {
      type: String,
      default: "rgba(0, 0, 0, 0.6)"
    },
    transparent: {
      type: Boolean,
      default: false
    },
    /* 点击蒙层是否关闭 */
    mask: {
      type: Boolean,
      default: false
    },
    /* 
      展示形式
        words： 文字提示
        slot  插槽
    */
    showType: {
      type: String,
      default: "words"
    },
    /* 提示文字 */
    tipWords: {
      type: String,
      default: "请传入提示文字"
    },
    /* 积分弹窗任意地方关闭 */
    maskCallBack: {
      type: Function,
      default: null
    }
  },
  data: function data() {
    return {
      showPop: false,
      outCallBack: null,
      showCallUs: false,
      callUs: {},
      userInfo: {}
    };
  },
  watch: {},
  filter: {},
  created: function created() {},
  mounted: function mounted() {},
  computed: {},
  methods: {
    open: function open(value) {
      value && (this.tipWords = value.tipWords);
      (value === null || value === void 0 ? void 0 : value.showCallUs) && (this.showCallUs = true) && (this.userInfo = value.userInfo);
      (value === null || value === void 0 ? void 0 : value.callUs) && (this.callUs = value.callUs);
      this.outCallBack = null;
      this.outCallBack = value === null || value === void 0 ? void 0 : value.callBack;
      this.showPop = true;
      this.$forceUpdate();
    },
    close: function close(params) {
      (params === null || params === void 0 ? void 0 : params.type) == "clean" && (this.outCallBack = null);
      this.popFullClose();
    },
    maskHandle: function maskHandle() {
      if (this.maskCallBack) {
        this.maskCallBack();
      }
      if (this.mask) {
        this.popFullClose();
      }
    },
    popFullClose: function popFullClose() {
      var _this$outCallBack;
      this.showPop = false;
      this.showCallUs = false;
      this.$emit("cancle");
      (_this$outCallBack = this.outCallBack) === null || _this$outCallBack === void 0 || _this$outCallBack.call(this, "close");
      this.outCallBack = null;
    }
  }
};