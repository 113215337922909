"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  components: {},
  props: {
    /* 点击蒙层是否关闭 */
    mask: {
      type: Boolean,
      default: false
    },
    /* 
      展示形式
        words： 文字提示
        slot  插槽
    */
    showType: {
      type: String,
      default: 'words'
    },
    /* 提示文字 */
    tipWords: {
      type: String,
      default: '请传入提示文字'
    }
  },
  data() {
    return {
      showPop: false,
      outCallBack: null,
      isToHome: false,
      formRouter: false,
      returnData: {},
      commit_hash: '',
      document_version_number: ''
    };
  },
  watch: {},
  filter: {},
  created() {},
  mounted() {
    window.addEventListener('message', e => {
      var data = e.data;
      this.returnData = data.data;
      /*
      if (data.data.versionDetail === undefined) {
          this.purposeStatus = data;
          console.log(this.purposeStatus)
      } else {
          this.consentData = data;
          console.log(this.consentData)
      }
      */

      console.log(this.returnData, '=================this.returnData=========================');
      if (this.returnData.versionDetail.commit_hash != undefined && this.commit_hash == '') {
        this.commit_hash = this.returnData.versionDetail.commit_hash;
      }
      if (this.returnData.versionDetail.document_version_number != undefined && this.document_version_number == '') {
        this.document_version_number = this.returnData.versionDetail.document_version_number;
      }
    }, false);
  },
  computed: {
    isRegister() {
      return localStorage.getItem('isRegister');
    }
  },
  methods: {
    async toRegister() {
      //重新赋值1，当作签署成功
      localStorage.setItem("all_active", 1);
      var openid = localStorage.getItem('openid');
      console.log(this.commit_hash, '=================this.returnData.versionDetail.commit_hash=========================');
      console.log(this.document_version_number, '=================this.returnData.versionDetail.document_version_number=========================');
      //var commit_hash = 'a475f579e4420f123d4478f52cdb3292ee8a8644';
      //var document_version_number = 1;

      //选中数量
      var checked_num = 0;
      if (this.returnData.length != undefined && this.returnData.length > 0) {
        for (var i = 0; i < this.returnData.length; i++) {
          if (this.returnData[i].checked == true) {
            checked_num++;
          }
        }
      }
      if (checked_num == 0 || checked_num != this.returnData.length) {
        alert('请您向下滑动政策页面至底部，阅读并点击“同意以上所有”，再点击“签署”以确认。感谢您的支持！');
        return;
      }
      var data = {
        'openid': openid,
        'commit_hash': this.commit_hash,
        'document_version_number': this.document_version_number
      };
      let status = await this.$api.send_truman(data);
      //alert(status);
      //console.log(status, '==>status-----status-----------') 
      //return;
      if (status == 1) {
        var consentBackUrl = window.sessionStorage.getItem('consentBackUrl');
        window.location.href = '/bayer-rad-academy-shadowcloud-academy-html' + consentBackUrl;
        //this.$REDIRECT({ path: consentBackUrl })
      }
      return;
      this.close();
    },
    cancleRegister() {
      this.outCallBack({
        next: 'from'
      });
      this.close();
    },
    open(callBack, isToHome, formRouter) {
      this.isToHome = isToHome;
      this.outCallBack = callBack;
      /* 从路由拦截过来以后，点击closeicon的时候调取取消的方法 */
      this.formRouter = formRouter;
      this.showPop = true;
    },
    close() {
      if (this.formRouter) {
        this.cancleRegister();
        return;
      }
      this.popFullClose();
    },
    maskHandle() {
      if (this.mask) {
        this.popFullClose();
      }
    },
    getQueryString: function (name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    popFullClose(type) {
      this.showPop = false;
      if (type == 'toHome') {
        if (!this.isRegister && this.isToHome) {
          this.isToHome = false;
          this.$REDIRECT({
            path: '/'
          });
        }
        return;
      }
      this.$emit('cancle');
    }
  }
};