var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container-register"
  }, [_c("transition", {
    attrs: {
      name: "popFull",
      appear: ""
    }
  }, [_vm.showPop ? _c("div", {
    staticClass: "popFull",
    on: {
      click: _vm.maskHandle
    }
  }, [_c("div", {
    staticClass: "popFullInner",
    on: {
      click: function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c("img", {
    staticClass: "popClose",
    attrs: {
      src: require("img/common/popClose.png"),
      alt: ""
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.popFullClose.apply(null, arguments);
      }
    }
  }), _c("div", {
    staticClass: "popFullInnerContent"
  }, [_c("div", {
    staticClass: "popFullInnerContentInner"
  }, [_c("h4", {
    staticClass: "fontWeight"
  }, [_vm._v("使用条款")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 访问和使用本应用程序应受以下条件约束。除非你同意这些条件，否则请勿使用本应用程序。本应用程序由拜耳医药保健有限公司（以下称拜耳）开发并管理。我们保留不再提供本应用程序或全部或部分修改本应用程序或使用条件、我们的通用条款和条件以及我们的销售和交货条件的权利。请注意，我们可不经事先通知即自行决定进行如此更改。因此，我们请你务必在下次访问本应用程序时再次阅读条件并留意是否有任何更改或修订。 ")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v(" 放弃使用和利益")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 本应用程序中发布的所有内容、文件和插图均为拜耳的独家财产。允许使用这些财产的前提是在所有副本上展示相关著作权，且该等内容仅可用于个人目的，不得作为商用，且不得对内容进行任何方式的变更且从应用程序获得的所有插图仅可与相应的文本一起使用。 ")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v("商标和著作权")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 除非另有注明或以其他方式表明是第三方的权利，本应用程序中的所有商标均为拜耳的财产。未经授权，禁止使用该等商标或其他材料，否则构成违反著作权、商标法或其他工业产权的行为。 ")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v(" 关于处方药和相关医疗信息的特别条件")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 本应用平台的处方药产品信息和医学信息（如有）由拜耳提供或授权转载，但只供医疗卫生专业人士阅读和使用。若非医疗卫生专业人士，请停止阅读所有拜耳处方药产品信息和医学信息。只有获得医生或有资格的医疗卫生专业人士的建议才能获取此应用平台中列出的处方药物；尽管如此，并非所有产品在所有国家均可获得。任何本应用平台中的信息不得被视为拜耳提供的个人医疗诊断或特定患者治疗建议。您应该随时通过与您的医生或其他医疗卫生专业人士直接讨论药物的适当使用方法，来获得关于您的处方药的完整医疗信息(包括其有益疗效及可能的不良反应)。医疗卫生专业人士可从拜耳产品说明书获得完整医疗信息，但是关于这些产品的说明书在不同国家可能有所不同。另外，中国当前的法律法规限制拜耳直接向患者提出的关于处方产品的问题提供信息和/或回应。然而，拜耳将按照当地规定向有资质的医疗专业人士提出的问题提供答复。 ")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v(" 药物警戒")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 如需报告使用拜耳产品后出现的不适或产品质量问题，可与您的医生药师或本地药品监管部门进行联系，也可通过以下方式直接联系我们。邮箱：AE_China@bayer.com。 ")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v(" 产品责任")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 拜耳已就其所知专业审慎利地用内外资源编辑本应用程序中提供的详细信息。我们努力持续扩展并更新这方面的信息。本应用程序中的信息仅用于展示拜耳和/或其产品和服务。但是，对于本应用程序中的信息之完整性或正确性不做任何明示或默示的声明或保证。请注意，本信息尽管在其发布之日是准确的，但现在可能不是最新的。因此，我们建议你在以任何方式使用从本应用程序获得的任何信息之前对其进行检查。如果你希望获得任何关于我们的产品或服务方面的建议或指导，请直接联系我们。本应用程序的用户声明其同意自己承担访问本应用程序及其内容的风险。拜耳和参与撰写、制作或发送本应用程序的第三方不对访问或无法访问本应用程序、使用或无法使用本应用程序、或用户依赖了本应用程序中提供的信息之事实而导致的损害或伤害承担责任。 ")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v(" 第三方供应商的网站/链接")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 本应用程序包含通向第三方网站的链接/参考。提供该等链接并不表示拜耳同意其内容。拜耳既不对该等网站的可用性或内容承担任何责任，也不对使用该等内容造成的损害或伤害承担任何责任。拜耳不保证链接页面会提供相同质量的信息。向本应用程序的用户提供通向网站的链接仅为方便之目的。用户应自行承担访问该等网站的风险。对于链接的选择绝不是将用户限于链接的页面。 ")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v(" 用户本人提供的内容")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 本应用程序的用户对于其向拜耳发送的内容及其正确性完全负责，也应确保不得侵犯该等内容中可能包含的任何第三方权利。用户同意拜耳存储该等内容并将该等内容用于统计分析之目的或用于任何其他特定商业目的，除非信息中包含个人资料、不属于主数据或常用数据。特别是，拜耳有权为了诸如开发、生产和/或推广产品或服务等任何目的使用该等消息的内容，包括其中包含的想法、发明、蓝图、技巧和技能，复制该信息并毫无限制地将其提供给第三方。 ")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v(" 国际用户")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 本应用程序由拜耳在中华人民共和国查看、运营并更新。本应用程序专为在中华人民共和国使用而开发。拜耳不保证本应用程序中提供的内容在中华人民共和国以外的地方也正确，尤其是，拜耳不保证提供同样形式或同样尺寸的产品或服务或以同样条件提供产品或服务。如果你从中华人民共和国外调用本应用程序或下载内容，请注意，你应自担责任，确保你的行为不违反适用于该地区的当地法律。 ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 本应用程序中提及的产品可能会根据不同的国家而以不同形式包装、不同尺寸包装或带有不同文字或标记。 ")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 拜耳在美国的业务由Bayer Corporation和/或其他拜耳美国实体负责。美国的客户需与该相关实体联系。 ")]), _c("p", {
    staticClass: "t2"
  }, [_vm._v(" 适用法律")]), _c("div", {
    staticClass: "content"
  }, [_vm._v(" 与本应用程序或其使用有关的任何法律主张或诉讼应按照中华人民共和国法律解释，但不包括国际私法和1964年7月1日有关国际货物销售统一法律的海牙公约和1980年4月11日美国销售公约的规定。 ")])])])])]) : _vm._e()])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };