var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page-container-register",
    on: {
      touchmove: function touchmove($event) {
        $event.preventDefault();
      }
    }
  }, [_c("transition", {
    attrs: {
      name: "popFull",
      appear: ""
    }
  }, [_vm.showPop ? _c("div", {
    staticClass: "popFull",
    on: {
      click: _vm.maskHandle
    }
  }, [_c("div", {
    staticClass: "popFullInner",
    on: {
      click: function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c("img", {
    staticClass: "popClose",
    attrs: {
      src: require("img/common/popClose.png"),
      alt: ""
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.cancleRegister.apply(null, arguments);
      }
    }
  }), _c("div", {
    staticClass: "popFullInnerContent popFullInnerContent-slot"
  }, [_c("div", {
    staticClass: "registerSuccess"
  }, [_c("div", {
    staticClass: "registerSuccessTip",
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("您的医院无效，请填写真实完整的医院名字后重新提交")]), _c("div", {
    staticClass: "registerSuccessHandle flex align-center justify-between"
  }, [_c("div", {
    staticClass: "registerSuccessHandleNow flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.toRegister.apply(null, arguments);
      }
    }
  }, [_vm._v("去填写")]), _c("div", {
    staticClass: "registerSuccessHandleDelay flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.cancleRegister.apply(null, arguments);
      }
    }
  }, [_vm._v("取消")])])])])])]) : _vm._e()])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };