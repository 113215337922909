import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: '/course',
  name: 'course',
  component: function component() {
    return import('views/course/index');
  },
  meta: {
    title: '学习',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜拜耳学习',
      desc: ''
    },
    tabIndex: '2'
  }
}, {
  path: '/course/detail',
  name: 'courseDetail',
  component: function component() {
    return import('views/course/detail');
  },
  meta: {
    title: '详情',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜学习详情',
      desc: '学习详情'
    },
    reachBottom: true,
    checkUser: true,
    tabIndex: '2',
    hideApproval: true
  }
}, {
  path: '/course/class',
  name: 'courseClass',
  component: function component() {
    return import('views/course/class');
  },
  meta: {
    title: '学习列表',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜拜耳学习',
      desc: ''
    },
    tabIndex: '2',
    reachBottom: true
  }
}, {
  path: '/course/search',
  name: 'courseSeach',
  component: function component() {
    return import('views/course/search');
  },
  meta: {
    title: '学习搜索',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜拜耳学习',
      desc: ''
    },
    tabIndex: '2',
    reachBottom: true
  }
}];