import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: '/coursedetail',
  name: '',
  component: function component() {
    return import('views/preview/coursedetail');
  },
  meta: {
    title: '课程详情',
    keepAlive: false,
    wechatAuth: false,
    hideTabbar: true
  }
}, {
  path: '/previewmeetingdetail',
  name: '',
  component: function component() {
    return import('views/preview/meetingdetail');
  },
  meta: {
    title: '会议详情',
    keepAlive: false,
    wechatAuth: false,
    hideTabbar: true
  }
}, {
  path: '/activitymoduledetail',
  name: '',
  component: function component() {
    return import('views/preview/activitydetailBefore');
  },
  meta: {
    title: '活动模块详情',
    keepAlive: false,
    wechatAuth: false,
    hideTabbar: true
  }
}, {
  path: '/activitycontentdetail',
  name: '',
  component: function component() {
    return import('views/preview/activityContentdetail');
  },
  meta: {
    title: '活动内容详情',
    keepAlive: false,
    wechatAuth: false,
    hideTabbar: true
  }
}, {
  path: '/activityrichlist',
  name: '',
  component: function component() {
    return import('views/preview/activityRich/list');
  },
  meta: {
    title: '活动内容列表',
    keepAlive: false,
    wechatAuth: false,
    hideTabbar: true
  }
}, {
  path: '/activityrichdetail',
  name: '',
  component: function component() {
    return import('views/preview/activityRich/detail');
  },
  meta: {
    title: '活动内容详情',
    keepAlive: false,
    wechatAuth: false,
    hideTabbar: true
  }
}];