var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "alert-container"
  }, [_c("div", {
    staticClass: "alert-content",
    staticStyle: {
      width: "50vw",
      height: "50vh"
    }
  }, [_c("ai-gap", {
    attrs: {
      height: "22"
    }
  }), _c("p", {
    staticStyle: {
      "font-size": "3vw"
    },
    on: {
      click: _vm.close
    }
  }, [_vm._v("关闭")]), _c("ai-gap", {
    attrs: {
      height: "22"
    }
  }), _c("p", {
    staticStyle: {
      "font-size": "3vw"
    },
    on: {
      click: _vm.callback
    }
  }, [_vm._v("有回调的时候关闭")]), _c("ai-gap", {
    attrs: {
      height: "22"
    }
  }), _c("p", {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.data.text))])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };