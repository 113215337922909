import http from 'utils/http';
export default {
  /* 会议-列表 */getMeetingtList: function getMeetingtList(params) {
    return http({
      url: "/api/platform/meeting",
      method: 'get',
      params: params
    });
  },
  /* 会议-详情 */getMeetingtDetail: function getMeetingtDetail(meetingId) {
    return http({
      url: "/api/platform/meeting/".concat(meetingId),
      method: 'get'
    });
  },
  /* 会议-标签 */getMeetingtTag: function getMeetingtTag(params) {
    return http({
      url: "/api/platform/meeting?get_tag=1",
      // url: `/api/platform/meeting/tags`,
      method: 'get',
      params: params
    });
  },
  /* 会议-报名 */setSubscribe: function setSubscribe(params) {
    return http({
      url: "/api/platform/meeting/subscribe",
      method: 'post',
      data: params
    });
  },
  /* 会议-我的会议 */getMyMeeting: function getMyMeeting(params) {
    return http({
      url: "/api/platform/meeting/my-meeting",
      method: 'get',
      params: params
    });
  },
  /* 会议-时长 */setMeetingTime: function setMeetingTime(params) {
    return http({
      url: "/api/platform/learn-log",
      method: 'post',
      data: params
    });
  }
};