export default {
  components: {},
  props: {
    close: Function,
    callback: Function,
    cancel: Function,
    confirm: Function
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  mounted: function mounted() {},
  computed: {},
  filters: {},
  methods: {}
};