"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _wechat = require("utils/wechat");
var _comment = _interopRequireDefault(require("./comment.vue"));
var _default = exports.default = window.$initPage({
  components: {
    comment: _comment.default
  },
  data() {
    return {
      contentDetail: {},
      isReachBottom: false,
      showTipInfoWords: {
        title: '',
        words: ''
      },
      urlFormOpenid: null,
      infoData: {},
      activePageFlag: false,
      everyTaskData: [{
        title: '生成你的「年度学习报告」',
        desc: '将获赠100学术能量',
        icon: require('@/assets/img/annual/home/icon1.png'),
        id: 1
      }, {
        title: '完成「问卷调研」',
        desc: '将获赠100-600学术能量',
        icon: require('@/assets/img/annual/home/icon2.png'),
        path: '/personal/question',
        id: 2
      }, {
        title: '活动期间内，完成医疗卫生专业人士相关信息认证',
        desc: '将获赠100学术能量',
        icon: require('@/assets/img/annual/home/icon4.png'),
        id: 4
      }, {
        title: '绑定拜耳会议平台',
        desc: '将获赠100学术能量',
        icon: require('@/assets/img/annual/home/icon3.png'),
        id: 3
      }, {
        title: '加入影像医生学习社群，与同行互通有无',
        desc: '将获赠100学术能量',
        icon: require('@/assets/img/annual/home/icon5.png'),
        id: 5
      }],
      overseasTaskData: [{
        title: '心血管影像训练营',
        desc: '14节CMR，6节CTA',
        icon: require('@/assets/img/annual/home/bastIcon1.png'),
        id: 48
      }, {
        title: '江桂华教授：脑血管病影像训练营',
        desc: '14节视频课程',
        icon: require('@/assets/img/annual/home/bastIcon2.png'),
        id: 38
      }, {
        title: '医技护 | CT、MR 规范化训练营',
        desc: '18节医技护课程',
        icon: require('@/assets/img/annual/home/bastIcon3.png'),
        id: 31
      }, {
        title: '肝脏影像CT、MRI 基本功训练营',
        desc: '13节视频课程，3场会议回放',
        icon: require('@/assets/img/annual/home/bastIcon4.png'),
        id: 13
      }],
      storageTaskData: [{
        title: '完成「每日签到」',
        desc: '将获赠5学术能量',
        icon: require('@/assets/img/annual/home/eIcon1.png'),
        id: 1
      }, {
        title: '完成「浏览课程」',
        desc: '将获赠10学术能量',
        icon: require('@/assets/img/annual/home/eIcon2.png'),
        id: 2
      }, {
        title: '完成评论互动',
        desc: '将获赠10学术能量',
        icon: require('@/assets/img/annual/home/eIcon3.png'),
        id: 3
      }, {
        title: '点赞、收藏或转发',
        desc: '将获赠1-10学术能量',
        icon: require('@/assets/img/annual/home/eIcon4.png'),
        id: 4
      }]
    };
  },
  beforeRouteLeave(to, from, next) {
    // 保存当前页面的滚动位置

    sessionStorage.setItem('savedScrollPosition', window.scrollY);
    next();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$nextTick(() => {
        // 从 sessionStorage 中获取保存的滚动位置
        const savedScrollPosition = sessionStorage.getItem('savedScrollPosition') || 0;
        // 设置滚动位置
        window.scrollTo(0, parseInt(savedScrollPosition, 10));
      });
    });
  },
  async mounted() {
    window.sessionStorage.setItem('isResterH5', 'true');
    this.urlQuery = this.$route.query;
    this.urlFormOpenid = this.urlQuery.from_openid || this.urlQuery.source || '';
    this.infoData = await this.get2024Data();
    console.log('this.infoData', this.infoData);
    this.setMonitor('pageTask');
    this.initData();
    this.setWeChatShare();
    this.initReachBottom();
  },
  methods: {
    veevaAuthHandle() {
      this.setMonitor(`everyTaskBtn5`);
      this.$store.commit('SET_CALLUSSHOW', true);
    },
    async initData() {
      let res = await this.$api.getContentDetail({}, 505);
      if (res !== null && res !== void 0 && res.errcode || (res === null || res === void 0 ? void 0 : res.hasErrCode) == '50001') {
        this.hideAll = true;
        return;
      }
      this.contentDetail = res;
      console.log(' this.initData();', this.contentDetail);
    },
    /* 分页 start */
    initReachBottom() {
      window.addEventListener('reachBottom', this.reachBottomHandle);
    },
    reachBottomHandle(evt) {
      var _this$$refs;
      const self = this;
      if (self.isReachBottom) {
        return;
      }

      // if (this.listCampainContent[this.listCampainContentIndex]?.have_comments) {
      self.isReachBottom = true;
      (_this$$refs = this.$refs) === null || _this$$refs === void 0 || (_this$$refs = _this$$refs.mycomment) === null || _this$$refs === void 0 || _this$$refs.reachBottom();
      // }
    },
    async setMonitor(key) {
      await this.$api.setViewLog({
        action_key: key,
        from_openid: this.urlFormOpenid
      });
    },
    async get2024Data() {
      let data = await this.$api.get2024Data({
        from_openid: this.urlFormOpenid
      });
      return data;
    },
    async toDetail(id, index) {
      this.setMonitor(`taskHomeBtn${index + 1}`);
      let authShowPop = await this.$api.getIsShowIntroduceAuth({}, id, 5);
      console.log('authShowPop', authShowPop);
      if (authShowPop.need_show_desc == 1) {
        this.$PUSH({
          path: `/activity/activeShowOncePoster?id=${id}&hotActive=1&secondList=1`
        });
      } else {
        this.$PUSH({
          path: `/activity/secondList?id=${id}`
        });
      }
    },
    everyTaskHandele(item) {
      if (item == 1) {
        this.goYearBackPage(item);
      } else if (item == 2) {
        this.$PUSH({
          path: '/personal/question'
        });
      } else if (item == 3) {
        this.$PUSH({
          path: `/veeva`
        });
      } else if (item == 4) {
        // if(this.infoData.member.veeva == 1)return
        this.$PUSH({
          path: `/veeva`
        });
      }
      this.setMonitor(`everyTaskBtn${item}`);
    },
    goYearBackPage(item) {
      this.$PUSH({
        path: `/annual/retrospect?from_openid=${this.urlFormOpenid}&isTaskPage=true`
      });
      if (item != 1) {
        this.setMonitor('taskHomeBtn');
      }
    },
    toggleActive() {
      this.activePageFlag = true;
      this.setMonitor('activeBtn');
    },
    goPersonalTask() {
      this.setMonitor(`promoteTaskBtn`);
      this.$PUSH({
        path: '/personal/task'
      });
    },
    showTipInfo() {
      this.$refs['customPop-tipInfo'].open();
    },
    setShowTipInfoWords(title, words) {
      this.showTipInfoWords = {
        title,
        words
      };
    },
    setWeChatShare(params) {
      // this.weChatShare.title = '续航学海，生成我的2023学习报告'
      // this.weChatShare.desc = '续航学海，生成我的2023学习报告'
      // this.weChatShare.imgUrl =
      //   'https://cn-ph-new-rad-p.s3.cn-north-1.amazonaws.com.cn/%27content%27/202312291412344/shareImg.png'
      this.weChatShare.title = '影领学途';
      this.weChatShare.desc = '影领学途，生成我的2024学习报告';
      this.weChatShare.imgUrl = 'https://cn-ph-new-rad-p.s3.cn-north-1.amazonaws.com.cn/minisite/20241224/rc-upload-1735022048660-7.png';
      this.initWechat();
    },
    switchTab() {
      const key = 0;
      const tabbarUrl = {
        0: '/',
        1: '/meeting',
        2: '/course',
        3: '/personal'
      };
      this.$store.dispatch('setTabIndex', key);
      this.$PUSH({
        path: tabbarUrl[key]
      });
    }
  }
});