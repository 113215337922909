"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
require("vant/es/sticky/style");
var _sticky = _interopRequireDefault(require("vant/es/sticky"));
require("vant/es/swipe-item/style");
var _swipeItem = _interopRequireDefault(require("vant/es/swipe-item"));
require("vant/es/swipe/style");
var _swipe = _interopRequireDefault(require("vant/es/swipe"));
var _index = _interopRequireDefault(require("@/components/swiperjs/index.vue"));
var _monitor = require("@/utils/monitor");
var _ConfirmDialog = _interopRequireDefault(require("@/components/ConfirmDialog.vue"));
var _veevaAuth = _interopRequireDefault(require("@/components/customPopview/veevaAuth.vue"));
var _collectTips = _interopRequireDefault(require("@/components/collectTips.vue"));
var _AnnualDrainage = _interopRequireDefault(require("@/components/customPopview/AnnualDrainage.vue"));
var _default = exports.default = window.$initPage({
  components: {
    vanSwipe: _swipe.default,
    vanSwipeItem: _swipeItem.default,
    selfSwiper: _index.default,
    vanSticky: _sticky.default,
    veevaAuth: _veevaAuth.default,
    ConfirmDialog: _ConfirmDialog.default,
    collectTips: _collectTips.default,
    MaskAnnualDrainage: _AnnualDrainage.default
  },
  data() {
    return {
      recommendListNeedTop: false,
      meetingList: [],
      resolve: null,
      reject: null,
      homeRecommend: {},
      bannerList: [],
      homeSetting: {},
      showPageEntry: true,
      showSearchArea: true,
      showBanner: true,
      showCourse: true,
      showhomeRecommend: true,
      showMetting: true,
      contentShowType: 0,
      homeContentList: [],
      /* 需要请求的接口数 */
      requestSuccessCount: 0,
      /* 成功返回的接口数 */
      executeSuccessCount: 0,
      searchWords: '',
      loadFinal: false,
      infoData: null
    };
  },
  watch: {
    contentShowType(newValue) {
      (0, _monitor.monitorClick)(`${this.contentShowType == 0 ? '最新' : '最热'}筛选`);
      this.getCourseList();
    },
    executeSuccessCount(newValue) {
      if (this.requestSuccessCount == this.executeSuccessCount) {
        this.$store.commit('SET_LOADINGPOP', false);
      }
    }
  },
  filter: {},
  created() {
    this.$store.commit('SET_LOADINGPOP', true);
    console.log(this.$route.query, '==>route.query');
    this.getmeetingview();
    const {
      query
    } = this.$route;
    if (query !== null && query !== void 0 && query.sourceuser) {
      this.postReflux(query);
    }
    this.initData();
  },
  mounted() {
    // this.$registerView.show(ret => {
    //       if (ret.next == "from") {
    //         this.$store.commit("SET_CALLUSSHOW", false)
    //       } else {
    //         this.$store.commit("SET_CALLUSSHOW", false)
    //         this.$PUSH({ path: `/register`, query: { redirect: encodeURIComponent(this.$route.fullPath) } })
    //       }
    //     })
    // this.$refs.veevaAuth.open();
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    callUs() {
      return this.$store.getters.callUs;
    }
  },
  methods: {
    goYearBackH5() {
      this.$PUSH({
        path: `/annual/task`
      });
    },
    async get2024Data() {
      let data = await this.$api.get2024Data({});
      return data;
    },
    async getmeetingview() {
      let res = await this.$api.getmeetingview();
      console.log(res, '==>res getmeetingview');
    },
    async toDetail(im) {
      //最新最热 跳转
      console.log('im00000', im);
      const moduleUrlMap = {
        1: '/course/detail',
        2: '/course/detail',
        3: '/course/detail',
        4: '/meetingdetail',
        5: '/activity/secondList',
        6: '/activity/detail',
        // '6': "/activity/detailenter",
        7: '/activity/detail'
      };
      if (im.type == '5' || im.type == '6' || im.type == '7') {
        let res = await this.$api.getCampainSectionDetailAuth({}, im.type == '7' ? im.section_id : im.id);
        if ((res === null || res === void 0 ? void 0 : res.hasErrCode) == '50001') {
          return;
        }
        let formsData = await this.$api.getCampainListAuth({}, res.campain_id);
        let authShowPop = await this.$api.getIsShowIntroduceAuth({}, res.campain_id, '5');
        if (im.type == '7') {
          //判断是不是活动内容查看权限
          let res2 = await this.$api.getCampainContentId({}, im.id); //三级权限查看
          console.log(res, '==> reess');
          if (res2 !== null && res2 !== void 0 && res2.errcode || (res2 === null || res2 === void 0 ? void 0 : res2.hasErrCode) == '50001') {
            return;
          }
        }
        console.log('authShowPop', authShowPop);
        if (authShowPop.need_show_desc == 1 && formsData.have_form == 1 && this.userInfo.type == 1) {
          if (im.type + '' == '6' || im.type + '' == '7') {
            this.$PUSH({
              path: `/activity/activeShowOncePoster?id=${res.campain_id}&homeType=ZXZR&_contentId=${res.id}&_typeMap=${im.type}&threeId=${im.id}`
            });
          } else {
            this.$PUSH({
              path: `/activity/activeShowOncePoster?id=${res.campain_id}&_typeMap=${im.type}`
            });
          }

          // this.$refs.confirmDialog
          //   .open('浏览完整活动介绍，参与互动后，即可立即学习！感谢您的支持！', '')
          //   .then(result => {
          //     if (im.type + '' == '6' || im.type + '' == '7') {
          //       console.log('this.dataBase', this.dataBase)
          //       this.$PUSH({
          //         path: `/activity/activeShowOncePoster?id=${res.campain_id}&homeType=ZXZR&_contentId=${res.id}&_typeMap=${im.type}`
          //       })
          //     } else {
          //       this.$PUSH({ path: `/activity/activeShowOncePoster?id=${res.campain_id}&_typeMap=${im.type}` })
          //     }
          //     // 执行确认后的逻辑
          //   })
          //   .catch(error => {
          //     console.log('用户取消了操作:', error)
          //     // 处理取消逻辑
          //   })

          return;
        }
      }
      if (im.type == '7') {
        this.$PUSH({
          path: im.content_type == 2 ? '/activity/richdetail' : moduleUrlMap[im.type],
          query: {
            id: im.section_id,
            campain_id: im.campain_id,
            contentid: im.id,
            // source: 1,
            typeMap: 7,
            section_id: im.section_id
          }
        });
        return;
      }
      this.$PUSH({
        path: moduleUrlMap[im.type],
        query: {
          id: im.id,
          typeMap: im.type
        }
      });
    },
    async postReflux(query) {
      const params = {
        ...query,
        scanneruser: localStorage.getItem('openid')
      };
      await this.$api.setMedalcertificateback(params);
      window.location.href = `${window.location.origin}${window.location.pathname}`;
    },
    async recommendGo(im) {
      //今日推荐
      /* 问卷-- 特殊处理 新增活动内容*/
      let moduleUrlMap = {
        1: '/course/detail',
        2: '/course/detail',
        3: '/course/detail',
        4: '/meetingdetail',
        5: '/activity/secondList',
        6: '/activity/detailenter',
        7: '/activity/detail'
      };
      if (im.type == '5' || im.type == '6' || im.type == '7') {
        let _id = null;
        if (im.type == 5 || im.type == 6) {
          _id = im.id;
        } else if (im.type == 7) {
          _id = im.content_id;
        }
        if (_id) {
          let _isAuth = await this.$api.getCheckPermissions({}, im.type, _id);
          if ((_isAuth === null || _isAuth === void 0 ? void 0 : _isAuth.hasErrCode) == '50001') {
            return;
          }
        }
        let res = await this.$api.getCampainSectionDetailAuth({}, im.type == '7' ? im.section_id : im.content_id);
        // if (res?.hasErrCode == '50001') {
        //   return
        // }
        let formsData = await this.$api.getCampainListAuth({}, res.campain_id);
        let authShowPop = await this.$api.getIsShowIntroduceAuth({}, res.campain_id, '5');
        // if (im.type == '7') {
        //   //判断是不是活动内容查看权限
        //   let res2 = await this.$api.getCampainContentId({}, im.content_id) //三级权限查看
        //   console.log(res, '==> reess')
        //   if (res2?.errcode || res2?.hasErrCode == '50001') {
        //     return
        //   }
        // }
        if (authShowPop.need_show_desc == 1 && formsData.have_form == 1 && this.userInfo.type == 1) {
          if (im.type + '' == '6' || im.type + '' == '7') {
            this.$PUSH({
              path: `/activity/activeShowOncePoster?id=${res.campain_id}&homeType=ZXZR&_contentId=${res.id}&_typeMap=${im.type}&threeId=${im.content_id}`
            });
          } else {
            this.$PUSH({
              path: `/activity/activeShowOncePoster?id=${res.campain_id}&_typeMap=${im.type}`
            });
          }
          return;
        }
        if (im.type == 6) {
          let authShowPop2 = await this.$api.getIsShowIntroduceAuth({}, im.content_id, '6');
          console.log('im---', im);
          if (authShowPop2.need_show_desc == 1) {
            this.$PUSH({
              path: `/activity/detailenter?id=${im.content_id}&typeMap=6&campain_id=${res.campain_id}`
            });
            return;
          }
          let sectionDetail = await this.$api.getCampainSectionDetail({}, im.content_id);
          let res2 = await this.$api.getCampainSectionDetailAuth({}, sectionDetail.id);
          if (sectionDetail.content_type == 1) {
            this.$PUSH({
              path: `/activity/detail?id=${sectionDetail.id}&typeMap=6&campain_id=${res2.campain_id}`
            });
            return;
          }
          this.$PUSH({
            path: `/activity/richlist?id=${sectionDetail.id}&typeMap=6&campain_id=${res2.campain_id}&source=1`
          });
          return;
        }
      }
      if (im.type == '7') {
        this.$PUSH({
          path: im.content_type == 2 ? '/activity/richdetail' : moduleUrlMap[im.type],
          query: {
            id: im.section_id,
            campain_id: im.campain_id,
            contentid: im.content_id,
            // source: 1,
            typeMap: 7,
            section_id: im.section_id
          }
        });
        return;
      }
      if (im.type == 8) {
        if (im.permission == 0) {
          let callUs = this.callUs || (await this.$api.getAssistantsInfo());
          this.$toatsView.show({
            tipWords: '抱歉！您暂无法查看该内容，请联系学术助手',
            showCallUs: true,
            callUs,
            userInfo: this.userInfo
          });
          return;
        }
        this.$PUSH({
          path: `/personal/intercept`,
          query: {
            id: im.content_id,
            typeMap: 8
            // link: im.link,
            // title: im.title
          }
        });
        return;
      }
      this.$PUSH({
        path: `${moduleUrlMap[im.type]}?id=${im.content_id}&typeMap=${im.type}&ishomerecommend=true`
      });

      // if (homeRecommend.type == 6) {
      //   let res = await this.$api.getCampainSectionDetailAuth({}, homeRecommend.content_id)
      //   console.log(res, '==>res>res>res -------------------------')
      //   if (res?.hasErrCode == '50001') {
      //     return
      //   }

      //   let authShowPop = await this.$api.getIsShowIntroduceAuth({}, res.campain_id, '5')
      //   console.log('authShowPop', authShowPop)
      //   let formsData = await this.$api.getCampainListAuth({}, res.campain_id)
      //   if (authShowPop.need_show_desc == 1 && this.userInfo.type == 1 && formsData.have_form == 1) {
      //     //今日推荐 只能配置模块列表  如果不从热门活动进入 模块列表需要重定向回详情页homeType=JRTJ 参数来判断需要跳回得页面
      //     this.$PUSH({
      //       path: `/activity/activeShowOncePoster?id=${res.campain_id}&homeType=JRTJ&_contentId=${homeRecommend.content_id}&_typeMap=${homeRecommend.type}`
      //     })
      //     return
      //   } else {
      //     let authShowPop2 = await this.$api.getIsShowIntroduceAuth({}, homeRecommend.content_id, '6')
      //     console.log('homeRecommend---', homeRecommend)
      //     if (authShowPop2.need_show_desc == 1) {
      //       this.$PUSH({
      //         path: `/activity/detailenter?id=${homeRecommend.content_id}&typeMap=6&campain_id=${res.campain_id}`
      //       })
      //       return
      //     }
      //     let sectionDetail = await this.$api.getCampainSectionDetail({}, homeRecommend.content_id)
      //     let res2 = await this.$api.getCampainSectionDetailAuth({}, sectionDetail.id)
      //     if (sectionDetail.content_type == 1) {
      //       this.$PUSH({ path: `/activity/detail?id=${sectionDetail.id}&typeMap=6&campain_id=${res2.campain_id}` })
      //       return
      //     }
      //     this.$PUSH({
      //       path: `/activity/richlist?id=${sectionDetail.id}&typeMap=6&campain_id=${res2.campain_id}&source=1`
      //     })
      //   }
      //   return
      // }

      // if (homeRecommend.type == 8) {
      //   if (homeRecommend.permission == 0) {
      //     let callUs = this.callUs || (await this.$api.getAssistantsInfo())

      //     this.$toatsView.show({
      //       tipWords: '抱歉！您暂无法查看该内容，请联系学术助手',
      //       showCallUs: true,
      //       callUs,
      //       userInfo: this.userInfo
      //     })

      //     return
      //   }
      //   this.$PUSH({
      //     path: `/personal/intercept`,
      //     query: {
      //       id: homeRecommend.content_id,
      //       typeMap: 8
      //       // link: homeRecommend.link,
      //       // title: homeRecommend.title
      //     }
      //   })
      //   return
      // }

      // this.$PUSH({
      //   path: `${moduleUrlMap[homeRecommend.type]}?id=${homeRecommend.content_id}&typeMap=${
      //     homeRecommend.type
      //   }&ishomerecommend=true`
      // })
    },
    async toLink(im) {
      const moduleUrlMap = {
        1: '/course/detail',
        2: '/course/detail',
        3: '/course/detail',
        4: '/meetingdetail',
        5: '/activity/secondList',
        6: '/activity/detail',
        // '6': "/activity/detailenter",
        7: '/activity/detail'
      };
      const params = this.getAllUrlParams(im.url);
      let _id = null;
      if (params.type == 5) {
        _id = params.id;
      } else if (params.typeMap == 6 || params.type == 6) {
        _id = params.id;
      } else if (params.typeMap == 7) {
        _id = params.contentid;
      }
      if (_id) {
        let _isAuth = await this.$api.getCheckPermissions({}, params.type ? params.type : params.typeMap, _id);
        if ((_isAuth === null || _isAuth === void 0 ? void 0 : _isAuth.hasErrCode) == '50001') {
          return;
        }
      }
      let authShowPop = await this.$api.getIsShowIntroduceAuth({}, params.type == 5 ? params.id : params.campain_id, 5);
      let formsData = await this.$api.getCampainListAuth({}, params.type == 5 ? params.id : params.campain_id);
      // if (formsData?.hasErrCode == '50001') {
      //   return
      // }

      // let res = await this.$api.getCampainSectionDetailAuth({}, params.typeMap == '7' ? params.section_id : params.id)
      // if (res?.hasErrCode == '50001') {
      //   return
      // }
      console.log('authShowPop', authShowPop);
      if (params.typeMap || params.type) {
        if (authShowPop.need_show_desc == 1 && this.userInfo.type == 1 && formsData.have_form == 1) {
          if (params.typeMap + '' == '6' || params.typeMap + '' == '7') {
            this.$PUSH({
              path: `/activity/activeShowOncePoster?id=${params.campain_id}&homeType=ZXZR&_contentId=${params.id}&_typeMap=${params.typeMap}`
            });
          } else {
            this.$PUSH({
              path: `/activity/activeShowOncePoster?id=${params.id}&_typeMap=${params.type}&secondList=1`
            });
          }
          return;
        } else {
          if (params.type == '5') {
            //活动详情
            if (authShowPop.need_show_desc == 1) {
              this.$PUSH({
                path: `/activity/activeShowOncePoster?id=${params.id}&_typeMap=${params.typeMap}&secondList=1`
              });
            } else {
              this.$PUSH({
                path: `/activity/secondList?id=${params.id}`
              });
            }
          } else if (params.typeMap == '6' || params.type == '6') {
            //模块列表
            let authShowPop2 = await this.$api.getIsShowIntroduceAuth({}, params.id, '6');
            console.log('params---', params);
            if (authShowPop2.need_show_desc == 1) {
              this.$PUSH({
                path: `/activity/detailenter?id=${params.id}&typeMap=6&campain_id=${params.campain_id}`
              });
              return;
            }
            let sectionDetail = await this.$api.getCampainSectionDetail({}, params.id);
            let res2 = await this.$api.getCampainSectionDetailAuth({}, sectionDetail.id);
            if (sectionDetail.content_type == 1) {
              this.$PUSH({
                path: `/activity/detail?id=${sectionDetail.id}&typeMap=6&campain_id=${res2.campain_id}`
              });
              return;
            }
            this.$PUSH({
              path: `/activity/richlist?id=${sectionDetail.id}&typeMap=6&campain_id=${res2.campain_id}&source=1`
            });
          } else if (params.typeMap == '7') {
            let sectionDetail = await this.$api.getCampainSectionDetail({}, params.id);
            this.$PUSH({
              path: sectionDetail.content_type == 2 ? '/activity/richdetail' : moduleUrlMap[params.typeMap],
              query: {
                id: params.section_id ? params.section_id : params.id,
                campain_id: params.campain_id,
                contentid: params.contentid,
                // source: 1,
                typeMap: 7,
                section_id: params.section_id
              }
            });
          } else {
            this.$PUSH({
              path: `${moduleUrlMap[params.typeMap]}?id=${params.id}&typeMap=${params.typeMap}`
            });
          }
        }
      } else {
        window.location.href = im.url;
      }

      // if (params.type == '5') {
      //   //活动详情
      //   if (authShowPop.need_show_desc == 1 && this.userInfo.type == 1) {
      //     this.$PUSH({ path: `/activity/activeShowOncePoster?id=${params.id}` })
      //   } else {
      //     this.$PUSH({ path: `/activity/secondList?id=${params.id}` })
      //   }
      // } else if (params.type == '6') {
      //   //模块列表
      //   if (authShowPop.need_show_desc == 1 && this.userInfo.type == 1) {
      //     this.$PUSH({ path: `/activity/activeShowOncePoster?id=${params.campain_id}` })
      //   } else {
      //     if (params.content_type == 2) {
      //       this.$PUSH({ path: `/activity/richlist?id=${params.id}&typeMap=6&campain_id=${params.campain_id}` })
      //       return
      //     }

      //     this.$PUSH({ path: `/activity/detail?id=${params.id}&typeMap=6&campain_id=${params.campain_id}` })
      //   }
      // } else {
      //   window.location.href = im.url
      // }

      // console.log('params-=---', params)

      // if (im.type == '5' || im.type == '6') {
      // } else {
      // }
    },
    getAllUrlParams(url) {
      const queryString = url.split('?')[1];
      if (!queryString) {
        return {};
      }
      const params = new URLSearchParams(queryString);
      const result = {};
      for (const [key, value] of params.entries()) {
        result[key] = value;
      }
      return result;
    },
    async initData() {
      this.getHomeSetting();
      this.infoData = await this.get2024Data();
    },
    async getHomeSetting() {
      try {
        let res = await this.$api.getHomeSetting();
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("数据请求失败for getHomeSetting：" + res.errmsg)
          return;
        }
        this.homeSetting = res;

        /* banner */
        res.banner ? this.getHomeBanner() && this.requestSuccessCount++ : this.showBanner = false;
        /* 今日推荐 */
        res.day_recommend ? this.getHomeRec() && this.requestSuccessCount++ : this.showhomeRecommend = false;
        /* 直播推荐 */
        res.meeting_recommend ? this.getLiveMeeting() && this.requestSuccessCount++ : (this.showMetting = false) && (this.recommendListNeedTop = true);
        /* 课程推荐 */
        res.lession_recommend ? this.getCourseList() && this.requestSuccessCount++ : this.showCourse = false;
        /* 模块入口 */
        !res.func_btn && (this.showPageEntry = false);
        /* 搜索区域 */
        // !res.search_area && (this.showSearchArea = false)
      } catch (error) {
        console.log(error);
      }
    },
    async getLiveMeeting() {
      try {
        let res = await this.$api.getHomeMeeting();
        this.executeSuccessCount++;
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("getMyFaver请求失败：" + res.errmsg)
          return;
        }
        this.meetingList = res;
      } catch (error) {}
    },
    async getCourseList() {
      try {
        let res = await this.$api.getContentHome({}, this.contentShowType);
        this.executeSuccessCount++;
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("数据请求失败for getContentHome:" + res.errmsg)
          return;
        }
        this.homeContentList = res;
        this.loadFinal = true;
      } catch (error) {
        console.log(error);
      }
    },
    async getHomeBanner() {
      try {
        let res = await this.$api.getHomeBanner();
        this.executeSuccessCount++;
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("数据请求失败for getHomeBanner：" + res.errmsg)
          return;
        }
        this.bannerList = res;
      } catch (error) {
        console.log(error, '==> getHomeBanner error');
      }
    },
    async getHomeRec() {
      try {
        let res = await this.$api.getHomeRec();
        this.executeSuccessCount++;
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("数据请求失败for GetHomeRec：" + res.errmsg)
          return;
        }
        this.homeRecommend = res;
      } catch (error) {
        console.log(error, '==> getHomeRec error');
      }
    }
  }
});