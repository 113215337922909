"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "baseInfos"
  }, [_vm.showSelect ? _c("div", [_c("div", {
    staticClass: "baseInfosLine marginAuto flex align-center justify-between"
  }, [_c("div", {
    staticClass: "baseInfosLineLeft"
  }, [_vm._v("真实姓名")]), _c("div", {
    staticClass: "baseInfosLineRight"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.formData.name,
      expression: "formData.name",
      modifiers: {
        trim: true
      }
    }],
    class: _vm.formData.status == 1 && "grayColor",
    attrs: {
      disabled: _vm.formData.status == 1,
      type: "text",
      maxlength: "10",
      placeholder: "请勿填写昵称"
    },
    domProps: {
      value: _vm.formData.name
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "name", $event.target.value.trim());
      },
      blur: function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })])]), _c("div", {
    staticClass: "baseInfosLine marginAuto flex align-center justify-between"
  }, [_c("div", {
    staticClass: "baseInfosLineLeft"
  }, [_vm._v("省份")]), _c("div", {
    staticClass: "baseInfosLineRight"
  }, [_c("customSelect", {
    ref: "provinceComponent",
    attrs: {
      disabled: _vm.formData.status == 1,
      isEdit: true,
      editSelectData: _vm.formData,
      noBorder: true,
      field: "province",
      refName: "provinceComponent",
      transmitData: _vm.provinceSelectList,
      tipWord: "省份",
      required: false
    },
    on: {
      setSwiperTouch: _vm.setSwiperTouch,
      checkValue: _vm.checkValue
    }
  })], 1)]), _c("div", {
    staticClass: "baseInfosLine marginAuto flex align-center justify-between"
  }, [_c("div", {
    staticClass: "baseInfosLineLeft"
  }, [_vm._v("医院")]), _c("div", {
    staticClass: "baseInfosLineRight"
  }, [_c("customSelect", {
    ref: "hospitalComponent",
    attrs: {
      disabled: _vm.formData.status == 1,
      isEdit: true,
      editSelectData: _vm.formData,
      noBorder: true,
      field: "hospital",
      inputFilter: true,
      refName: "hospitalComponent",
      transmitData: _vm.selecthospitalList,
      tipWord: _vm.hospitalTip,
      required: false
    },
    on: {
      setSwiperTouch: _vm.setSwiperTouch,
      checkValue: _vm.checkValue
    }
  })], 1)]), _vm.formData.hospital == "其他" ? _c("div", {
    staticClass: "baseInfosLine marginAuto flex align-center justify-between"
  }, [_c("div", {
    staticClass: "baseInfosLineLeft"
  }, [_vm._v("其他医院")]), _c("div", {
    staticClass: "baseInfosLineRight"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.hospitalOther,
      expression: "hospitalOther",
      modifiers: {
        trim: true
      }
    }],
    class: _vm.formData.status == 1 && "grayColor",
    attrs: {
      disabled: _vm.formData.status == 1,
      type: "text",
      placeholder: "请填写准确医院名称"
    },
    domProps: {
      value: _vm.hospitalOther
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.hospitalOther = $event.target.value.trim();
      },
      blur: function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })])]) : _vm._e(), _c("div", {
    staticClass: "baseInfosLine marginAuto flex align-center justify-between"
  }, [_c("div", {
    staticClass: "baseInfosLineLeft"
  }, [_vm._v("科室")]), _c("div", {
    staticClass: "baseInfosLineRight"
  }, [_c("customSelect", {
    ref: "department",
    attrs: {
      disabled: _vm.formData.status == 1,
      isEdit: true,
      editSelectData: _vm.formData,
      noBorder: true,
      refName: "department",
      field: "department",
      transmitData: _vm.departmentSelectList,
      tipWord: "请选择准确科室",
      required: false
    },
    on: {
      setSwiperTouch: _vm.setSwiperTouch,
      checkValue: _vm.checkValue
    }
  })], 1)]), _vm.formData.department == "其他科室" ? _c("div", {
    staticClass: "baseInfosLine marginAuto flex align-center justify-between"
  }, [_c("div", {
    staticClass: "baseInfosLineLeft"
  }, [_vm._v("其他科室")]), _c("div", {
    staticClass: "baseInfosLineRight"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.departmentOther,
      expression: "departmentOther",
      modifiers: {
        trim: true
      }
    }],
    class: _vm.formData.status == 1 && "grayColor",
    attrs: {
      disabled: _vm.formData.status == 1,
      type: "text",
      placeholder: "请输入科室"
    },
    domProps: {
      value: _vm.departmentOther
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.departmentOther = $event.target.value.trim();
      },
      blur: function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })])]) : _vm._e(), _c("div", {
    staticClass: "baseInfosLine marginAuto flex align-center justify-between"
  }, [_c("div", {
    staticClass: "baseInfosLineLeft"
  }, [_vm._v("职称")]), _c("div", {
    staticClass: "baseInfosLineRight"
  }, [_c("customSelect", {
    ref: "title",
    attrs: {
      disabled: _vm.formData.status == 1,
      isEdit: true,
      editSelectData: _vm.formData,
      noBorder: true,
      refName: "title",
      field: "title",
      transmitData: _vm.titleSelectList,
      tipWord: "职称",
      required: false
    },
    on: {
      setSwiperTouch: _vm.setSwiperTouch,
      checkValue: _vm.checkValue
    }
  })], 1)]), _vm.formData.title == "其他" ? _c("div", {
    staticClass: "baseInfosLine marginAuto flex align-center justify-between"
  }, [_c("div", {
    staticClass: "baseInfosLineLeft"
  }, [_vm._v("其他职称")]), _c("div", {
    staticClass: "baseInfosLineRight"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.titleOther,
      expression: "titleOther",
      modifiers: {
        trim: true
      }
    }],
    class: _vm.formData.status == 1 && "grayColor",
    attrs: {
      disabled: _vm.formData.status == 1,
      type: "text",
      placeholder: "请输入职称"
    },
    domProps: {
      value: _vm.titleOther
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.titleOther = $event.target.value.trim();
      },
      blur: function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })])]) : _vm._e(), _c("div", {
    staticClass: "baseInfosLine marginAuto flex align-center justify-between"
  }, [_c("div", {
    staticClass: "baseInfosLineLeft"
  }, [_vm._v("行政职务")]), _c("div", {
    staticClass: "baseInfosLineRight"
  }, [_c("customSelect", {
    ref: "position",
    attrs: {
      disabled: _vm.formData.status == 1,
      isEdit: true,
      editSelectData: _vm.formData,
      noBorder: true,
      field: "position",
      transmitData: _vm.positionSelectList,
      tipWord: "行政职务",
      refName: "position",
      required: false
    },
    on: {
      setSwiperTouch: _vm.setSwiperTouch,
      checkValue: _vm.checkValue
    }
  })], 1)]), _vm.formData.position == "其他" ? _c("div", {
    staticClass: "baseInfosLine marginAuto flex align-center justify-between"
  }, [_c("div", {
    staticClass: "baseInfosLineLeft"
  }, [_vm._v("其他职务")]), _c("div", {
    staticClass: "baseInfosLineRight"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.positionOther,
      expression: "positionOther",
      modifiers: {
        trim: true
      }
    }],
    class: _vm.formData.status == 1 && "grayColor",
    attrs: {
      disabled: _vm.formData.status == 1,
      type: "text",
      placeholder: "请输入职务"
    },
    domProps: {
      value: _vm.positionOther
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.positionOther = $event.target.value.trim();
      },
      blur: function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })])]) : _vm._e()]) : _vm._e(), _c("customPop", {
    ref: "customPop-error",
    attrs: {
      tipWords: _vm.errorTipWords
    },
    on: {
      cancle: _vm.customPopCancle,
      confirm: _vm.customPopConfirm
    }
  }), _c("div", {
    staticClass: "baseInfosNext flex align-center justify-center",
    on: {
      click: _vm.nextStep
    }
  }, [_vm._v("下一步")])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;