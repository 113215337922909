import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
var ctx = require.context('./modules/', true, /\.js/);
var modules = {};
ctx.keys().forEach(function (key) {
  var name = key.replace(/(^\.\/|\.js$)/g, ''); // 去后缀
  name = name.substr(0, 1).toLowerCase() + name.substr(1); // 首字母小写
  modules[name] = ctx(key).default;
});

// console.log('模块打印', modules)
export default modules;