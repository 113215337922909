import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default [{
  path: '/personal/task',
  name: 'personalTask',
  component: function component() {
    return import('views/personal/task');
  },
  meta: {
    title: '个人中心提升',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    checkUser: true,
    tabIndex: '3'
  }
}, {
  path: '/personal/rank',
  name: 'personalRank',
  component: function component() {
    return import('views/personal/rank');
  },
  meta: {
    title: '排行榜',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜学术能量排行榜',
      desc: ''
    },
    hideTabbar: true,
    checkUser: true,
    tabIndex: '3'
  }
}, {
  path: '/personal/warning',
  name: 'personalWarning',
  component: function component() {
    return import('views/personal/warning');
  },
  meta: {
    title: '药物警戒',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    tabIndex: '3'
  }
}, {
  path: '/personal/badge',
  name: 'personalBadge',
  component: function component() {
    return import('views/personal/badge');
  },
  meta: {
    title: '我的勋章',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    tabIndex: '3',
    checkUser: true
  }
}, {
  path: '/personal/cer',
  name: 'personalCer',
  component: function component() {
    return import('views/personal/cer');
  },
  meta: {
    title: '我的证书',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    tabIndex: '3',
    checkUser: true
  }
}, {
  path: '/personal/achievedetail',
  name: 'achieveDetail',
  component: function component() {
    return import('views/personal/achieveDetail');
  },
  meta: {
    title: '成就详情',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    tabIndex: '3',
    checkUser: true
  }
}, {
  path: '/personal/achieveshare',
  name: 'achieveShare',
  component: function component() {
    return import('views/personal/achieveShare');
  },
  meta: {
    title: '成就分享',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    tabIndex: '3',
    checkUser: true
  }
}, {
  path: '/personal/collection',
  name: 'personalCollection',
  component: function component() {
    return import('views/personal/collection');
  },
  meta: {
    title: '我的收藏',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    checkUser: true,
    tabIndex: '3',
    reachBottom: true
  }
}, {
  path: '/personal/history',
  name: 'personalHistory',
  component: function component() {
    return import('views/personal/history');
  },
  meta: {
    title: '学习记录',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    checkUser: true,
    tabIndex: '3'
  }
}, {
  path: '/personal/signup',
  name: 'personalSignUp',
  component: function component() {
    return import('views/personal/signUp');
  },
  meta: {
    title: '会议报名',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    checkUser: true,
    tabIndex: '3',
    reachBottom: true
  }
}, {
  path: '/personal/question',
  name: 'personalQuestion',
  component: function component() {
    return import('views/personal/question/index');
  },
  meta: {
    title: '我的问卷',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    checkUser: true,
    tabIndex: '3',
    reachBottom: true
  }
}, {
  path: '/personal/intercept',
  name: 'personalIntercept',
  component: function component() {
    return import('views/personal/question/intercept');
  },
  meta: {
    title: '我的问卷',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜个人中心',
      desc: ''
    },
    checkUser: true,
    tabIndex: '3',
    reachBottom: true
  }
}, {
  path: '/notify',
  name: 'notify',
  component: function component() {
    return import('views/notify/index');
  },
  meta: {
    title: '消息中心',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜消息中心',
      desc: ''
    },
    checkUser: true,
    reachBottom: true
  }
}, {
  path: '/veeva',
  name: 'veevaHandle',
  component: function component() {
    return import('views/veevaAuth/index');
  },
  meta: {
    title: '医疗卫生专业人士认证 ',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜医疗卫生专业人士认证 ',
      desc: ''
    },
    checkUser: true,
    reachBottom: true,
    hideApproval: true
  }
}, {
  path: '/veevaupload',
  name: 'uploadInfo',
  component: function component() {
    return import('views/veevaAuth/upload');
  },
  meta: {
    title: '医疗卫生专业人士认证 ',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜医疗卫生专业人士认证 ',
      desc: ''
    },
    checkUser: true,
    reachBottom: true
  }
}, {
  path: '/bindStep',
  name: 'veevaHandle',
  component: function component() {
    return import('views/veevaAuth/bindStep');
  },
  meta: {
    title: '绑定拜耳学术会议平台步骤',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '绑定拜耳学术会议平台步骤 ',
      desc: '绑定拜耳学术会议平台步骤'
    },
    checkUser: true,
    reachBottom: true,
    hideApproval: true
  }
}, {
  path: '/contactUs',
  name: 'contactUs',
  component: function component() {
    return import('views/personal/contactUs');
  },
  meta: {
    title: '联系我们',
    keepAlive: false,
    wechatAuth: true,
    share: {
      title: '拜耳影像学苑｜联系我们',
      desc: ''
    },
    checkUser: true,
    reachBottom: true,
    ingoreCheck: true
  }
}];