import Vue from 'vue';
import aiAlert from 'aui/senior/ai-alert-vue';
var AiAlert = Vue.extend(aiAlert);
aiAlert.install = function (data) {
  var instance = new AiAlert({
    data: data
  }).$mount();
  document.body.appendChild(instance.$el);
  Vue.nextTick(function () {
    instance.show = true;
  });
};
export default aiAlert;