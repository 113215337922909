"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "page-container"
  }, [_c("stickyHeader"), _c("div", {
    staticClass: "pageCenter marginAuto"
  }, [_c("div", {
    staticClass: "pageCenterTitle fontWeight"
  }, [_vm._v("基本信息")]), _vm.showSelect ? _c("div", {
    staticClass: "pageCenterForm"
  }, [_c("div", {
    staticClass: "pageCenterFormList flex align-center"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.formData.name,
      expression: "formData.name",
      modifiers: {
        trim: true
      }
    }],
    attrs: {
      type: "text",
      maxlength: "10",
      placeholder: "* 姓名"
    },
    domProps: {
      value: _vm.formData.name
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "name", $event.target.value.trim());
      },
      blur: function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _c("div", {
    staticClass: "pageCenterFormList flex align-center"
  }, [_c("span", {
    staticStyle: {
      color: "#9d9d9d"
    }
  }, [_vm._v("*")]), _c("span", {
    staticClass: "pageCenterFormListBefore",
    staticStyle: {
      color: "#06070b"
    }
  }, [_vm._v("+86")]), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.formData.phone,
      expression: "formData.phone",
      modifiers: {
        trim: true
      }
    }],
    attrs: {
      type: "text",
      placeholder: "请输入您的手机号码"
    },
    domProps: {
      value: _vm.formData.phone
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "phone", $event.target.value.trim());
      },
      blur: function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _c("div", {
    staticClass: "pageCenterFormList flex align-center justify-between"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.formData.code,
      expression: "formData.code",
      modifiers: {
        trim: true
      }
    }],
    attrs: {
      type: "text",
      maxlength: "6",
      placeholder: "请输入短信验证码"
    },
    domProps: {
      value: _vm.formData.code
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "code", $event.target.value.trim());
      },
      blur: function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _c("div", {
    staticClass: "pageCenterFormListGetCode",
    on: {
      click: _vm.getCode
    }
  }, [_vm._v(_vm._s(_vm.getSms.words))])]), _c("div", {
    staticClass: "pageCenterFormList flex align-center justify-between"
  }, [_c("customSelect", {
    ref: "provinceComponent",
    attrs: {
      field: "province",
      refName: "provinceComponent",
      transmitData: _vm.provinceSelectList,
      isEdit: true,
      editSelectData: _vm.formData,
      tipWord: "省份",
      required: true
    },
    on: {
      checkValue: _vm.checkValue
    }
  })], 1), _c("div", {
    staticClass: "pageCenterFormList flex align-center justify-between"
  }, [_c("customSelect", {
    ref: "hospitalComponent",
    attrs: {
      field: "hospital",
      inputFilter: true,
      refName: "hospitalComponent",
      transmitData: _vm.selecthospitalList,
      tipWord: _vm.hospitalTip,
      editSelectData: _vm.formData,
      required: true
    },
    on: {
      checkValue: _vm.checkValue
    }
  })], 1), _vm.formData.hospital == "其他" ? _c("div", {
    staticClass: "pageCenterFormList flex align-center"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.hospitalOther,
      expression: "hospitalOther",
      modifiers: {
        trim: true
      }
    }],
    attrs: {
      type: "text",
      placeholder: "* 请输入其它医院名称"
    },
    domProps: {
      value: _vm.hospitalOther
    },
    on: {
      input: function ($event) {
        if ($event.target.composing) return;
        _vm.hospitalOther = $event.target.value.trim();
      },
      blur: function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]) : _vm._e(), _c("div", {
    staticClass: "pageCenterFormList flex align-center justify-between"
  }, [_c("customSelect", {
    ref: "department",
    attrs: {
      isEdit: true,
      editSelectData: _vm.formData,
      refName: "department",
      field: "department",
      transmitData: _vm.departmentSelectList,
      tipWord: "科室",
      required: true
    },
    on: {
      checkValue: _vm.checkValue
    }
  })], 1), _c("div", {
    staticClass: "pageCenterFormList flex align-center justify-between"
  }, [_c("customSelect", {
    ref: "title",
    attrs: {
      isEdit: true,
      editSelectData: _vm.formData,
      refName: "title",
      field: "title",
      transmitData: _vm.titleSelectList,
      tipWord: "职称",
      required: true
    },
    on: {
      checkValue: _vm.checkValue
    }
  })], 1), _c("div", {
    staticClass: "pageCenterFormList flex align-center justify-between"
  }, [_c("customSelect", {
    ref: "position",
    attrs: {
      isEdit: true,
      editSelectData: _vm.formData,
      field: "position",
      transmitData: _vm.positionSelectList,
      tipWord: "行政职务",
      refName: "position",
      required: true
    },
    on: {
      checkValue: _vm.checkValue
    }
  })], 1)]) : _vm._e(), _c("div", {
    staticClass: "pageCenterAgree marginAuto flex align-center justify-between"
  }, [_c("div", {
    staticClass: "pageCenterAgreeLeft flex align-center",
    on: {
      click: function ($event) {
        _vm.formData.privacy = !_vm.formData.privacy;
      }
    }
  }, [_c("div", {
    staticClass: "pageCenterAgreeCheckAgree"
  }, [_vm.formData.privacy ? _c("img", {
    attrs: {
      src: require("img/home/checkFilter.png"),
      alt: ""
    }
  }) : _vm._e()]), _c("div", {
    staticStyle: {
      "line-height": "1.3",
      flex: "1"
    }
  }, [_vm._v(" 如果您希望得到该平台学术服务，请浏览并同意签署 "), _c("span", {
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.$privacyInstanceView.show();
      }
    }
  }, [_vm._v("《拜耳隐私政策》 ")])])])]), _c("div", {
    staticClass: "registerBtn handleBtn flex align-center justify-center",
    on: {
      click: _vm.registerSubmit
    }
  }, [_vm._v("提交")]), _c("div", {
    staticClass: "cancleBtn handleBtn flex align-center justify-center",
    on: {
      click: _vm.registerCancle
    }
  }, [_vm._v("取消")])]), _c("customPop", {
    ref: "customPop-error",
    attrs: {
      tipWords: _vm.errorTipWords
    },
    on: {
      cancle: _vm.customPopCancle,
      confirm: _vm.customPopConfirm
    }
  }), _c("customPop", {
    ref: "customPop-cus",
    attrs: {
      showType: "slot"
    }
  }, [_c("div", {
    staticClass: "registerSuccess"
  }, [_c("div", {
    staticClass: "registerSuccessTip"
  }, [_vm._v("提交成功！"), _c("br"), _vm._v(" 可前往完善信息")]), _c("div", {
    staticClass: "registerSuccessHandle flex align-center justify-between"
  }, [_c("div", {
    staticClass: "registerSuccessHandleNow flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: _vm.registerSuccessHandleNow
    }
  }, [_vm._v("立即完善")]), _c("div", {
    staticClass: "registerSuccessHandleDelay flex align-center justify-center registerSuccessHandleBtn",
    on: {
      click: _vm.registerSuccessHandleDelay
    }
  }, [_vm._v("稍后")])])])]), _c("customPop", {
    ref: "customPop-ewm",
    attrs: {
      showType: "slot"
    },
    on: {
      cancle: _vm.ewmpopClose
    }
  }, [_c("div", {
    staticClass: "enterActivity-All"
  }, [_c("div", {
    staticClass: "enterActivityTip"
  }, [_vm._v("请扫码添加学术助手 "), _c("br"), _vm._v("咨询相关问题")]), _c("div", {
    staticClass: "ewmIcon"
  }, [_c("img", {
    attrs: {
      src: _vm.callUs.qrcode,
      alt: ""
    }
  })])])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;