import _typeof from "D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
/**
 * --- 索引 ---
 * 去空格 trim(value, type) 默认 1, 1 所有空格 2 前后空格 3 前空格 4 后空格
 * 日期格式化：formatDate(time, fmt) // yyyy-MM-dd hh:mm:ss 日期格式化
 * 转换大小写：changeCase(value, type) 1 首字母大写 2 首字母小写 3 大小写转换 4 大写 5 小写
 * 隐藏手机号：hidePhone(phone) 隐藏中间 4位
 * 金额大写：toWords(number) 数值转换大写金额
 * 保留2位小数：toDecimal(number) 保留两位小数，缺位补足0
 */

/**
 * 去除空格
 * @param {number|string} value 1 所有空格 2 前后空格 3 前空格 4 后空格
 */
export function trim(value) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  switch (type) {
    case 1:
      return value.replace(/\s+/g, '');
    case 2:
      return value.replace(/(^\s*)|(\s*$)/g, '');
    case 3:
      return value.replace(/(^\s*)/g, '');
    case 4:
      return value.replace(/(\s*$)/g, '');
    default:
      return value;
  }
}

/**
 * 日期格式化
 * @param {Object|string|number} time 需要转换的日期
 * @param {string} fmt yy-MM-dd hh:mm:ss q w （y 年 M 月 d 日 h 时 m 分 s 秒 q 季度 w 周）
 */
export function formatDate(time, fmt) {
  if (time === undefined || '') {
    return;
  }
  var date = new Date(time);
  var o = {
    'M+': date.getMonth() + 1,
    // 月份
    'd+': date.getDate(),
    // 日
    'h+': date.getHours(),
    // 小时
    'm+': date.getMinutes(),
    // 分
    's+': date.getSeconds(),
    // 秒
    'w+': date.getDay(),
    // 星期
    'q+': Math.floor((date.getMonth() + 3) / 3),
    // 季度
    'S': date.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  for (var k in o) {
    if (k === 'w+') {
      if (o[k] === 0) {
        fmt = fmt.replace('w', '周日');
      } else if (o[k] === 1) {
        fmt = fmt.replace('w', '周一');
      } else if (o[k] === 2) {
        fmt = fmt.replace('w', '周二');
      } else if (o[k] === 3) {
        fmt = fmt.replace('w', '周三');
      } else if (o[k] === 4) {
        fmt = fmt.replace('w', '周四');
      } else if (o[k] === 5) {
        fmt = fmt.replace('w', '周五');
      } else if (o[k] === 6) {
        fmt = fmt.replace('w', '周六');
      }
    } else if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    }
  }
  return fmt;
}

/**
 * 转换日期
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;
  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return time_str;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (typeof time !== 'number') time = new Date(time).getTime();
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;
  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}

/**
 * 大小写转换
 * @param {string} str 需要转换的字符
 * @param {number|string} type 1 首字母大写 2 首字母小写 3 大小写转换 4 大写 5 小写
 */
export function changeCase(str, type) {
  function ToggleCase(str) {
    var itemText = '';
    str.split('').forEach(function (item) {
      if (/^([a-z]+)/.test(item)) {
        itemText += item.toUpperCase();
      } else if (/^([A-Z]+)/.test(item)) {
        itemText += item.toLowerCase();
      } else {
        itemText += item;
      }
    });
    return itemText;
  }
  switch (type) {
    case 1:
      return str.replace(/\b\w+\b/g, function (word) {
        return word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase();
      });
    case 2:
      return str.replace(/\b\w+\b/g, function (word) {
        return word.substring(0, 1).toLowerCase() + word.substring(1).toUpperCase();
      });
    case 3:
      return ToggleCase(str);
    case 4:
      return str.toUpperCase();
    case 5:
      return str.toLowerCase();
    default:
      return str;
  }
}

/*
 * 隐藏用户手机号中间四位
 */

/**
 * 隐藏手机号
 * @param {number|string} phone
 */
export function hidePhone(phone) {
  return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
}
export function compareDates(date1, date2) {
  var curdate1 = new Date(date1);
  var curdate2 = new Date(date2);
  if (curdate1.getTime() > curdate2.getTime()) {
    return true;
  } else {
    return false;
  }
}
/**
 * 金额大写
 * @param {*} n 金额数值
 */
export function toWords(n) {
  var fraction = ['角', '分', '厘'];
  var digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  var unit = [['元', '万', '亿'], ['', '拾', '佰', '仟']];
  var head = n < 0 ? '欠' : '';
  n = Math.abs(n);
  var s = '';
  for (var i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
  }
  s = s || '整';
  n = Math.floor(n);
  for (var i = 0; i < unit[0].length && n > 0; i++) {
    var p = '';
    for (var j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p;
      n = Math.floor(n / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
    // s = p + unit[0][i] + s;
  }
  return head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
}

/**
 * 保留 2 位小数
 * @param {number|string} value
 */
export function toDecimal() {
  var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var float = parseFloat(value);
  if (isNaN(float)) return;
  float = Math.round(value * 100) / 100;
  var base = float.toString();
  var dot = base.indexOf('.');
  if (dot < 0) {
    dot = base.length;
    base += '.';
  }
  while (base.length <= dot + 2) {
    base += '0';
  }
  return base;
}