import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.search.js";
export default {
  components: {},
  props: {
    /* 点击蒙层是否关闭 */
    mask: {
      type: Boolean,
      default: false
    },
    /* 
      展示形式
        words： 文字提示
        slot  插槽
    */
    showType: {
      type: String,
      default: 'words'
    },
    /* 提示文字 */
    tipWords: {
      type: String,
      default: '请传入提示文字'
    }
  },
  data: function data() {
    return {
      showPop: false,
      outCallBack: null,
      isToHome: false,
      formRouter: false
    };
  },
  watch: {},
  filter: {},
  created: function created() {},
  mounted: function mounted() {},
  computed: {
    isRegister: function isRegister() {
      return localStorage.getItem('isRegister');
    }
  },
  methods: {
    toRegister: function toRegister() {
      var url = '/register';
      if (this.getQueryString("channel")) {
        //url = '/register?channel=' + this.getQueryString("channel");
      }
      localStorage.setItem('registerBackUrl', encodeURIComponent(window.location.href));
      this.outCallBack({
        next: url
      });
      this.close();
    },
    cancleRegister: function cancleRegister() {
      this.outCallBack({
        next: 'from'
      });
      this.close();
    },
    open: function open(callBack, isToHome, formRouter) {
      this.isToHome = isToHome;
      this.outCallBack = callBack;
      /* 从路由拦截过来以后，点击closeicon的时候调取取消的方法 */
      this.formRouter = formRouter;
      this.showPop = true;
    },
    close: function close() {
      if (this.formRouter) {
        this.cancleRegister();
        return;
      }
      this.popFullClose();
    },
    maskHandle: function maskHandle() {
      if (this.mask) {
        this.popFullClose();
      }
    },
    getQueryString: function getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    popFullClose: function popFullClose(type) {
      this.showPop = false;
      if (type == 'toHome') {
        if (!this.isRegister && this.isToHome) {
          this.isToHome = false;
          this.$REDIRECT({
            path: '/'
          });
        }
        return;
      }
      this.$emit('cancle');
    }
  }
};