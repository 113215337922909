"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/utils/index.js");
var _index2 = _interopRequireDefault(require("@/components/customSelect/index.vue"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _pattern = require("@/utils/pattern.js");
var _hospital = _interopRequireDefault(require("@/utils/hospital.json"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    customSelect: _index2.default
  },
  data() {
    return {
      selecthospitalList: [],
      hospital: _hospital.default,
      /* 其它医院名称 -- 当医院选择为其它时显示 */
      hospitalOther: "",
      errorTipWords: "",
      formData: {
        privacy: false
        // terms: false
      },
      formDataRule: {
        name: [{
          required: true,
          message: "请填写姓名"
        }],
        phone: [{
          required: true,
          message: "请输入您的手机号码"
        }, {
          verify: true,
          verifyArr: [_pattern.isPhone],
          verifyArrMsg: ["手机号输入不正确"]
        }],
        code: [{
          required: true,
          message: "请填写验证码"
        }],
        province: [{
          required: true,
          message: "请选择省份"
        }],
        hospital: [{
          required: true,
          message: "请选择医院"
        }],
        department: [{
          required: true,
          message: "请选择科室"
        }],
        title: [{
          required: true,
          message: "请选择职称"
        }],
        position: [{
          required: true,
          message: "请选择行政职务"
        }],
        privacy: [{
          required: true,
          message: "请浏览并同意签署《拜耳隐私政策》"
        }]
        /* terms: [
          {
            required: true,
            message: "请确认勾选隐私声明 及使用条款"
          }
        ] */
      },
      /* 需要加密的字段 --key */
      encryptionArray: ["name", "phone"],
      getSms: new _index.getSmsAfter(),
      isSubmiting: false,
      showSelect: false,
      isInintData: false,
      hospitalTip: "医院"
    };
  },
  watch: {
    selecthospitalList() {
      // console.log(this.selecthospitalList,'==>  selecthospitalList -- selecthospitalList');
    },
    "$store.getters.userInfo": {
      handler(newValue) {
        newValue.id && this.createFormData();
      },
      deep: true
    }
  },
  filter: {},
  created() {
    this.$store.commit("SET_LOADINGPOP", true);
    this.$store.dispatch("getUserInfo");
  },
  mounted() {
    const {
      query
    } = this.$route;
    if (query.from == 'welcome') {
      this.weChatShare.selfLink = `${window.location.origin}/bayer-rad-academy-shadowcloud-academy-html/welcome?source=SubscribeQRCode`;
      this.initWechat();
    }
  },
  computed: {
    positionSelectList() {
      return this.$store.getters.positionSelectList;
    },
    departmentSelectList() {
      return this.$store.getters.departmentSelectList;
    },
    titleSelectList() {
      return this.$store.getters.titleSelectList;
    },
    provinceSelectList() {
      return this.$store.getters.provinceSelectList;
    },
    hospitalSelectList() {
      return this.$store.getters.hospitalSelectList;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    callUs() {
      return this.$store.getters.callUs;
    }
  },
  methods: {
    ewmpopClose() {
      this.$REDIRECT({
        path: '/'
      });
    },
    setHospital() {
      let hospitalCount = 0;
      setTimeout(() => {
        Promise.resolve();
        for (const key in this.hospitalSelectList) {
          hospitalCount++;
          if (this.formData.hospital != '其他' && this.formData.hospital == this.hospitalSelectList[key]) {
            return;
          }
        }
        if (hospitalCount == this.hospitalSelectList.length) {
          this.hospitalOther = this.formData.hospital_other || this.formData.hospital;
          this.formData.hospital = `其他`;
        }
      }, 500);
    },
    async createFormData() {
      /* if(!this.$refs?.provinceComponent || !this.$refs?.hospitalComponent){
        this.createFormData()
        return
      } */
      if (this.isInintData) return;
      this.formData = {
        ...this.formData,
        ...this.userInfo
      };

      // if (this.formData?.hospital && this.formData?.hospital == '其他') {
      //   await this.setHospital()
      // }
      if (this.encryptionArray.length) {
        const deCrypt = new _index.Crypt();
        this.encryptionArray.forEach(im => {
          this.formData[im] = deCrypt.decrypt(this.formData[im]);
        });
      }
      this.formData["hospital"] = null;
      this.formData["hospital_other"] = null;
      this.showSelect = true;
      this.$nextTick(() => {
        // this.$refs.provinceComponent.formatData()
        // this.$refs.hospitalComponent.formatData()

        this.$store.commit("SET_LOADINGPOP", false);
        this.isInintData = true;
      });
    },
    registerCancle() {
      var _this$$route$query, _this$$route;
      if (((_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.handle) == "backtohome") {
        this.$REDIRECT({
          path: "/"
        });
        return;
      }
      const hasToHome = (_this$$route = this.$route) === null || _this$$route === void 0 || (_this$$route = _this$$route.query) === null || _this$$route === void 0 || (_this$$route = _this$$route.redirect) === null || _this$$route === void 0 ? void 0 : _this$$route.indexOf("gotohome=true");
      if (hasToHome != -1) {
        this.$REDIRECT({
          path: "/"
        });
        return;
      }
      this.$GO(-1);
    },
    async getCode() {
      if (this.getSms.timer) {
        this.errorTipWords = `验证码已发送`;
        this.$refs[`customPop-error`].open();
        setTimeout(() => {
          this.$refs[`customPop-error`].close();
        }, 1500);
        return;
      }
      const key = "phone";
      if (this.testAlone(key)) {
        const myCrypt = new _index.Crypt();
        let params = {};
        params[key] = myCrypt.encrypt(this.formData[key]);
        params["crypt_key"] = myCrypt.rsa_key();
        this.getSms.countDown();
        let res = await this.$api.smsSend(params);
        if (res !== null && res !== void 0 && res.errcode) {
          this.errorTipWords = `发送失败，${res.errmsg}`;
          this.$refs[`customPop-error`].open();
          this.getSms.clearStatus();
          return;
        }
        this.errorTipWords = `验证码发送成功`;
        this.$refs[`customPop-error`].open();
        setTimeout(() => {
          this.$refs[`customPop-error`].close();
        }, 1500);
      }
    },
    closeAllRef(refName) {
      const allRef = this.$refs;
      for (const key in allRef) {
        if (key != refName) {
          this.$refs[key].closePop();
        }
      }
    },
    async checkValue(params) {
      this.$set(this.formData, params.field, params.check);
      if (params.field == "province") {
        this.$refs.hospitalComponent.check = null;
        this.$refs.hospitalComponent.inputFilterWords = "";
        this.selecthospitalList = [];
        this.formData.hospital = "";
        this.hospitalTip = "正在获取医院数据...";
        let res = await this.$api.getHospital({
          province: this.formData.province
        });
        this.hospitalTip = "医院";
        this.selecthospitalList = [...res, ...this.hospitalSelectList];
        // this.selecthospitalList = [...this.hospitalSelectList, ...res]

        if (this.selecthospitalList.includes(this.formData['hospital'])) {
          this.$refs.hospitalComponent.inputFilterWords = this.formData['hospital'];
          return;
        }

        // this.$refs.hospitalComponent.resetCheck()
      }
      this.$forceUpdate();
    },
    to(value) {
      console.log(value, '==>value');
    },
    testAlone(key) {
      this.formDataRule[key].forEach((im, ix) => {
        var _this$formData$key;
        if (im.required && (!this.formData[key] || this.formData[key] instanceof String && !((_this$formData$key = this.formData[key]) !== null && _this$formData$key !== void 0 && _this$formData$key.trim()))) {
          this.errorTipWords = im.message;
          this.$refs[`customPop-error`].open();
          throw im.message;
        }
        if (im.verify) {
          im.verifyArr.forEach((km, kx) => {
            if (!km(this.formData[key])) {
              this.errorTipWords = im.verifyArrMsg[kx];
              this.$refs[`customPop-error`].open();
              throw km.message;
            }
          });
        }
      });
      return true;
    },
    testValue() {
      for (let key in this.formDataRule) {
        this.formDataRule[key].forEach((im, ix) => {
          var _this$formData$key2;
          if (im.required && (!this.formData[key] || this.formData[key] instanceof String && !((_this$formData$key2 = this.formData[key]) !== null && _this$formData$key2 !== void 0 && _this$formData$key2.trim()))) {
            this.errorTipWords = im.message;
            this.$refs[`customPop-error`].open();
            throw im.message;
          }
          if (im.verify) {
            im.verifyArr.forEach((km, kx) => {
              if (!km(this.formData[key])) {
                this.errorTipWords = im.verifyArrMsg[kx];
                this.$refs[`customPop-error`].open();
                throw km.message;
              }
            });
          }
        });
      }
      return true;
    },
    encryption() {},
    async registerSubmit() {
      // this.$PUSH({ path: '/information' })

      if (this.testValue()) {
        if (this.formData.hospital == '其他') {
          if (!this.hospitalOther) {
            this.errorTipWords = `请输入医院名称`;
            this.$refs[`customPop-error`].open();
            return;
          }
          if (this.hospitalOther) {
            this.formData.hospital_other = this.hospitalOther;
          }
        }
        let params = JSON.parse(JSON.stringify(this.formData));
        if (this.encryptionArray.length) {
          const myCrypt = new _index.Crypt();
          for (const key in params) {
            if (this.encryptionArray.includes(key)) {
              params[key] = myCrypt.encrypt(params[key]);
            }
          }
          params["crypt_key"] = myCrypt.rsa_key();
        }
        delete params['privacy'];
        // delete params['terms']

        if (this.isSubmiting) return;
        this.isSubmiting = true;
        const {
          query
        } = this.$route;
        const userFrom = localStorage.getItem("userFrom");
        if (userFrom == "SubscribeQRCode" && query.from == 'welcome' && !params.sync_scrm_flag) {
          params["sync_scrm_flag"] = 9;
        }
        let res = await this.$api.updateUserInfo({
          ...params,
          re_perfect_type: 0
        });
        this.isSubmiting = false;
        if (res !== null && res !== void 0 && res.errcode) {
          this.errorTipWords = `提交失败：${res.errmsg}`;
          this.$refs[`customPop-error`].open();
          return;
        }

        /* if (this.$route.redirect) {
          this.$store.dispatch("getUserInfo")
              setTimeout(() => {
            this.$router.redirect({
              url: decodeURIComponent(this.$route.redirect)
            })
          }, 1500)
          return
        } */

        this.$store.dispatch("getUserInfo");

        /* if (query?.poptype == "qrcode" || userFrom == "SubscribeQRCode") {
          this.$refs["customPop-ewm"].open()
          return
        } */

        /* setTimeout(() => {
          this.$refs["customPop-cus"].open()
        }, 2500); */
        this.errorTipWords = `提交成功`;
        this.$refs[`customPop-error`].open();
        setTimeout(() => {
          this.$PUSH({
            path: '/'
          });
        }, 1500);
      }
      // this.$refs.customPop.open()
    },
    customPopCancle() {},
    customPopConfirm() {},
    registerSuccessHandleDelay() {
      this.$refs['customPop-cus'].close();
      this.$REDIRECT({
        path: '/personal'
      });
      // this.$REDIRECT({ path: '/' })
    },
    registerSuccessHandleNow() {
      this.$refs['customPop-cus'].close();
      // this.$REDIRECT({ path: '/userInformationEdit' })
      this.$REDIRECT({
        path: '/information?mode=edit'
      });
    },
    toStaff() {
      this.$PUSH({
        path: "/staffregister"
      });
    }
  }
});