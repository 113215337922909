"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("vant/es/popup/style");
var _popup = _interopRequireDefault(require("vant/es/popup"));
var _header = _interopRequireDefault(require("@/components/sticky/header.vue"));
var _historyList = _interopRequireDefault(require("@/components/course/historyList.vue"));
var _default = exports.default = window.$initPage({
  components: {
    stickyHeader: _header.default,
    vanPopup: _popup.default,
    listItem: _historyList.default
  },
  data() {
    return {
      showPowerBoxTip: false,
      historyList: {},
      dateMap: {
        "today": "今天",
        "yesterday": "昨天",
        "earlier": "更早"
      },
      loadFinal: false
    };
  },
  watch: {},
  filter: {},
  created() {
    //this.initData()
  },
  mounted() {},
  computed: {},
  methods: {
    async initData() {
      //let res = 98
    }
  }
});