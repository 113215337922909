"use strict";

var _interopRequireDefault = require("D:/ApacheServer/web_php/yxxy_html/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commentAreaListItem = _interopRequireDefault(require("./commentAreaListItem.vue"));
var _default = exports.default = {
  components: {
    commentAreaListItem: _commentAreaListItem.default
  },
  props: {
    renderModule: {
      type: String,
      default: ''
    },
    preview: {
      type: Boolean,
      default: false
    },
    /* 点击蒙层是否关闭 */
    mask: {
      type: Boolean,
      default: false
    },
    /* 
          展示形式
            words： 文字提示
            slot  插槽
        */
    showType: {
      type: String,
      default: 'words'
    },
    /* 提示文字 */
    tipWords: {
      type: String,
      default: '请传入提示文字'
    },
    /* 是否为活动图文详情 */
    isRichTextDetail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMoreData: true,
      showPop: false,
      orderby: false,
      mycommentList: [],
      commentList: [],
      commentContent: '',
      current: 1,
      pageSize: 10,
      isLoading: true,
      /* 输入框是否聚焦 */
      commentInputCursor: false,
      isSubmiting: false
    };
  },
  watch: {},
  filter: {},
  created() {
    this.getCommentList();
  },
  mounted() {},
  computed: {},
  methods: {
    updateIm(evt, ix) {
      if (evt == 'add') {
        this.commentList[ix].zan++;
        this.commentList[ix].have_zan = 1;
        return;
      }
      this.commentList[ix].zan--;
      this.commentList[ix].have_zan = 0;
    },
    changeFilter() {
      this.orderby = !this.orderby;
      this.current = 1;
      // this.commentList = [];
      this.getCommentList(true);
    },
    reachBottom() {
      /* 当评论输入框聚焦时，不做动作，不执行触底加载 */
      if (this.commentInputCursor) return;
      this.getCommentList();
    },
    async sendComment() {
      this.$parent.setMonitor('commentSend');
      const openid = localStorage.getItem('openid');
      const wechatInfo = localStorage.getItem('wechatInfo');
      const isRegister = localStorage.getItem('isRegister');
      if (!openid || !wechatInfo || !isRegister) {
        this.$registerView.show(ret => {
          if (ret.next == 'from') {
            this.$store.commit('SET_CALLUSSHOW', false);
          } else {
            this.$store.commit('SET_CALLUSSHOW', false);
            this.$PUSH({
              path: `/register`,
              query: {
                redirect: encodeURIComponent(this.$route.fullPath)
              }
            });
          }
        }, false, true);
        this.$store.commit('SET_CALLUSSHOW', false);
        return;
      }
      if (this.isSubmiting) return;
      if (!this.commentContent) {
        this.$toatsView.show({
          tipWords: '请输入评论内容！'
        });
        return;
      }
      this.isSubmiting = true;
      const {
        contentDetail
      } = this.$parent;
      let params = {
        type: 2024,
        content_id: 0,
        comment: this.commentContent
      };
      try {
        let res = await this.$api.createComment(params);
        setTimeout(() => {
          this.isSubmiting = false;
        }, 1000);
        if (res !== null && res !== void 0 && res.errcode) {
          // alert("数据获取失败" + res.errmsg)
          return;
        }
        this.commentContent = '';
        this.current = 1;
        this.isMoreData = true;
        this.getCommentList(true);
      } catch (error) {}
    },
    async getCommentList(isClear) {
      if (!this.isMoreData || !this.isLoading) return;
      let params = {
        'filter[content_id]': 0,
        'filter[type]': 2024,
        orderby: Number(this.orderby),
        current: this.current,
        pageSize: this.pageSize
      };

      // this.isLoading = true

      try {
        this.isLoading = false;
        let res = await this.$api.getCommentList(params);
        /* 当前数据的长度和返回的总条数一样  不做如何动作 */
        this.$parent.isReachBottom = false;
        if (isClear) {
          this.commentList = [];
        }
        if (this.commentList.length >= res.total) {
          this.isMoreData = false;
          this.isLoading = true;
          return;
        }

        /* 当前数据的长度和返回的总条数不一样 并且条数没满params[pageSize]的数量 */
        if (res.data.length < params['pageSize']) {
          /* 首先把多余的数据去除 */
          this.commentList = this.commentList.slice(0, Number(params['pageSize']) * Number(this.current));
          /* 去除多余数据后，将本次数据和去除后的数据合并 */
          this.commentList = [...this.commentList, ...res.data];
          console.log('this.commentList', this.commentList);
          this.isLoading = true;
          return;
        }
        this.current++;
        this.commentList = [...this.commentList, ...res.data];
        this.isLoading = true;
      } catch (error) {}
    },
    open() {
      this.showPop = true;
    },
    close() {
      this.popFullClose();
    },
    maskHandle() {
      if (this.mask) {
        this.popFullClose();
      }
    },
    popFullClose() {
      this.showPop = false;
      this.$emit('cancle');
    }
  }
};