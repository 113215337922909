import "core-js/modules/es.array.concat.js";
import http from 'utils/http';
export default {
  /* 内容-详情 */getContentDetail: function getContentDetail(params, contentId) {
    return http({
      url: "/api/platform/content/".concat(contentId),
      method: 'get'
    });
  },
  /* 全部内容-列表 */getAllContent: function getAllContent(params, contentId) {
    return http({
      url: "/api/platform/content",
      method: 'get',
      params: params
    });
  },
  /* 全部内容-标签 */getAllContentTag: function getAllContentTag(params, contentId) {
    return http({
      url: "/api/platform/content?get_tag=1",
      // url: `/api/platform/content/tags`,
      method: 'get',
      params: params
    });
  },
  /* 内容-收藏 */contentFaver: function contentFaver(params) {
    return http({
      url: "/api/platform/content/faver",
      method: 'post',
      data: params
    });
  },
  /* 内容-取消收藏 */cancleContentFaver: function cancleContentFaver(params) {
    return http({
      url: "/api/platform/content/faver-delete",
      method: 'post',
      data: params
    });
  },
  /* 内容-点赞 */contentZan: function contentZan(params) {
    return http({
      url: "/api/platform/content-zan-log",
      method: 'post',
      data: params
    });
  },
  /* 内容-取消点赞 */cancleContentZan: function cancleContentZan(params) {
    return http({
      url: "/api/platform/content-zan-log-delete",
      method: 'post',
      data: params
    });
  },
  /* 课程首页-banner */getCourseHomeBanner: function getCourseHomeBanner(params, content_id, type) {
    return http({
      url: "/api/platform/lesson/banner",
      method: 'get',
      params: params
    });
  },
  /* 课程首页-精讲堂 */getCourseHomeLessonList: function getCourseHomeLessonList(params, content_id, type) {
    return http({
      url: "/api/platform/lesson/lesson-list",
      method: 'get',
      params: params
    });
  },
  /* 课程首页-训练营 */getCourseHomeCampainList: function getCourseHomeCampainList(params, content_id, type) {
    return http({
      url: "/api/platform/lesson/campain-list",
      method: 'get',
      params: params
    });
  },
  /* 课程首页-列表（course/class?id=id） */getCourseLessonList: function getCourseLessonList(params, id) {
    return http({
      url: "/api/platform/lesson/lesson",
      method: 'get',
      params: params
    });
  },
  /* 课程二级页-标签集合（course/class?id=id） */getCourseLessonTagList: function getCourseLessonTagList(params, id) {
    return http({
      url: "/api/platform/lesson/lesson?get_tag=1",
      // url: `/api/platform/lesson/lesson/tag-select`,
      method: 'get',
      params: params
    });
  },
  /* 内容-表单-查询（用来回显） */getFormEchoInfo: function getFormEchoInfo(params, content_id, type) {
    return http({
      url: "/api/platform/content/form/".concat(content_id, "/").concat(type),
      method: 'get',
      params: params
    });
  },
  /* 内容-表单-提交 */putFormInfo: function putFormInfo(params) {
    return http({
      url: "/api/platform/content/form",
      method: 'post',
      data: params
    });
  },
  /* 内容-收藏-检查是否收藏 */getFaverCheck: function getFaverCheck(content_id, type) {
    return http({
      url: "/api/platform/content/faver-check/".concat(content_id, "/").concat(type),
      method: 'get'
    });
  },
  /* 内容-点赞-检查是否点赞 */getZanCheck: function getZanCheck(content_id, type) {
    return http({
      url: "/api/platform/content-zan-log-check/".concat(content_id, "/").concat(type),
      method: 'get'
    });
  },
  /* 评论-创建 */createComment: function createComment(params) {
    return http({
      url: "/api/platform/comments",
      method: 'post',
      data: params
    });
  },
  /* 评论-查询列表 */getCommentList: function getCommentList(params) {
    return http({
      url: "/api/platform/comments",
      method: 'get',
      params: params
    });
  },
  /* 评论-点赞 */setCommentZan: function setCommentZan(params) {
    return http({
      url: "/api/platform/comments-zan-log",
      method: 'post',
      data: params
    });
  },
  /* 评论-取消点赞 */delCommentZan: function delCommentZan(params) {
    return http({
      url: "/api/platform/comments-zan-log-delete",
      method: 'post',
      data: params
    });
  },
  /* 病例-列表 */getDisease: function getDisease(params, id) {
    return http({
      url: "/api/platform/disease",
      method: 'get',
      params: params
    });
  },
  /* 病例-标签 */getDiseaseTagList: function getDiseaseTagList(params, id) {
    return http({
      url: "/api/platform/disease?get_tag=1",
      // url: `/api/platform/disease/tags`,
      method: 'get',
      params: params
    });
  }
};