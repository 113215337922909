import confirm from 'components/confirm';
export default {
  components: {
    confirm: confirm
  },
  data: function data() {
    return {
      show: false,
      opacity: 0.7,
      overlay: 0,
      duration: 0,
      callback: function callback() {},
      cancel: function cancel() {},
      confirm: function confirm() {}
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
    close: function close() {
      this.show = false;
    },
    closed: function closed() {
      this.$refs.aiPopup.remove();
      this.$destroy();
    }
  }
};