/* 基础组件 */
import aiGap from 'aui/components/ai-gap';
import aiImg from 'aui/components/ai-img';

/* 高级组件 */
import aiAlert from 'aui/senior/ai-alert';
import aiPopup from 'aui/senior/ai-popup';
import aiConfirm from 'aui/senior/ai-confirm';
import aiLoading from 'aui/senior/ai-loading';
import aiForm from 'aui/components/ai-form';
var install = function install(Vue) {
  Vue.component('ai-gap', aiGap);
  Vue.component('ai-img', aiImg);
  Vue.component('ai-form', aiForm);
  Vue.prototype.$alert = aiAlert.install;
  Vue.prototype.$popup = aiPopup.install;
  Vue.prototype.$confirm = aiConfirm.install;
  Vue.prototype.$loading = aiLoading.install;
};
export default {
  install: install
};